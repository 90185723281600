import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../../img/marca/logo-blanc.png";

//PEU DE PÀGINA

class Peu extends Component {
  render() {
    return (
      <div className="peu">
        <Container>
          <Row>
            <Col md className="columna columnaPeu">
              <p>
                <img
                  src={logo}
                  width="150"
                  className="d-inline-block align-top"
                  alt="Mobilitat Berguedà"
                />
              </p>
            </Col>
            <br />
            <Col md className="columna columnaPeu">
              {/* MENÚ SI CAL */}
              <ul className="linksDestacats">
                <LinkContainer to="/linia/1">
                  <li>Horari bus Berga Barcelona</li>
                </LinkContainer>
                <LinkContainer to="/linia/2">
                  <li>Horari bus Berga Manresa</li>
                </LinkContainer>
                <LinkContainer to="/linia/">
                  <li>Línies bus Berguedà</li>
                </LinkContainer>
                <LinkContainer to="/parada/">
                  <li>Parades bus Berguedà</li>
                </LinkContainer>
                <a
                  href="https://www.bergueda.cat/transportademanda/"
                  target="_blank"
                >
                  <li>Transport demanda Berguedà</li>
                </a>
                <LinkContainer to="/parada/1">
                  <li>Horari bus Berga</li>
                </LinkContainer>
              </ul>
            </Col>
            <br />
            <Col md className="columna columnaPeu columnaPeuDreta">
              <p>
                <a href="https://www.bergueda.cat/" target="_blank">
                  <img
                    className="logoConsell logoPeu"
                    src="https://mobilitat.bergueda.cat/img/logo_consell.png"
                  />
                </a>
              </p>
              <p>
                <a href="http://www.elbergueda.cat/" target="_blank">
                  <img
                    className="logoTerraFuturs logoPeu"
                    src="https://mobilitat.bergueda.cat/img/logo_terrafuturs.png"
                  />
                </a>
              </p>

              {/* <p>Un projecte fet per:</p>
                      <p>Ricard Codina</p>
                      <br/>
                      <p><img className="logoUniversitat" src="https://mobilitat.bergueda.cat/img/logo_UOC.png" /></p> */}
              <p className="numVersio">v.2.7.6</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Peu;
