import React, {Component} from 'react';
import {Card, Row, Col, OverlayTrigger, Tooltip, Button} from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


import API from 'Api';


class ModulLinies extends Component {

//CARREGA LINIES
constructor(){
    super()
    this.obteDades=this.obteDades.bind(this)
  }
  
  
  
  obteDades() {
    this.setState({generaInterficie: false }); //Abans de carregar dades, posem la careta de Carregant.
    const baseURL = `linia/`;
  
    API.get(baseURL)
    .then(res => {
        if (res.data.status==="OK"){
          const linies = res.data.llistat;
          this.setState({linies});
        }        
      const status = res.data.status;
      this.setState({status, generaInterficie: true });  
  
    }) 
  }
  
  
    state = {
        linies:null,
        status:"ERROR",
        generaInterficie: false
      }
  
  
      componentDidMount() {
        this.obteDades();
      }

    render() {

        return (
          <div>
              {this.state.linies &&
                    <Card style={{ width: '100%' }} className="targetaPortada">
                        <Card.Body>
                            <Card.Title>Línies de transport públic</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Horaris, mapa i parades de cada línia del Berguedà</Card.Subtitle>
                                <div className="iconesLiniaPortada">{this.state.linies.map((linia, index)=>
                                    <OverlayTrigger
                                    key={"linia"+index}
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-${"linia"+index}`}>
                                        {linia.nom}
                                        </Tooltip>
                                    }
                                    >
                                    <LinkContainer to={"/linia/"+linia.codi}><div><div className="siglesLinia maBoto" style={{backgroundColor: linia.color}}>{linia.sigles}</div></div></LinkContainer>
                                    </OverlayTrigger>                       
                               
                                )}

                                {/* BUS BERGA */}
                                <OverlayTrigger
                                    key={"busberga"}
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`busberga`}>
                                        Bus urbà de Berga
                                        </Tooltip>
                                    }
                                    >
                                    <a href="http://busberga.ajberga.cat/" target="_blank"><div><div className="siglesLinia maBoto" style={{backgroundColor: '#C4001D'}}><img src="https://mobilitat.bergueda.cat/img/logo-bus-berga-blanc.svg" style={{maxWidth: '50px'}} alt="Logo Bus Berga"/></div></div></a>
                                    </OverlayTrigger>

                                {/* FINAL BUS BERGA */}
                                                
                            </div>
                            <div><LinkContainer to={"/linia"}><Card.Link>Veure totes</Card.Link></LinkContainer> · <a href={process.env.PUBLIC_URL + '/arxius/mapa_transport_public_bergueda_octubre_2021.pdf'} target="_blank"><Card.Link><FontAwesomeIcon icon="file-pdf" color="#336122"/> Plànol de línies</Card.Link></a></div>
                            
                        </Card.Body>
                        </Card>
                }
          </div>
        )
      }
}

export default ModulLinies