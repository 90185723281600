import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import {Container, Col, Row, Button} from 'react-bootstrap';
import AdminParades from './AdminParades';
import AdminLinies from './AdminLinies';
import Login from './Login';
import AdminExpedicions from './AdminExpedicions';
import AdminAvisos from './AdminAvisos';
import AdminPortada from './AdminPortada';
import AdminAvisUsuari from './AdminAvisUsuari';

class Administrador extends Component {

    constructor(){
        super()
        this.assignaToken=this.assignaToken.bind(this)
        this.logOut=this.logOut.bind(this)
      }

    state = {
        admin: false,
        token: null
    }

    //Funció per assignar el token rebut
    assignaToken(token){ 
        this.setState({token, admin:true})
    }

    //Funció per desconnectar-se que elimina el token de la sessió
     logOut(event){ 
        // event.preventDefault();
        sessionStorage.removeItem('token');
        this.setState({token:null, admin:false});
    }

    //Mirem si hi ha emmagatzemat algun token a la memòria de la sessió
    componentDidMount() {
        const token = sessionStorage.getItem('token');
        if (token){
            this.assignaToken(token);
        }
    }

    render() {
        return (
           <div>
               {!this.state.admin &&  
               <Login assignaToken={this.assignaToken} token={this.state.token}/>
               }

                {this.state.admin &&           
                
                //DIRECCIONS DINS DE L'ADMINISTRADOR (només accessibles si hem fet login)
                <div className="modulInferior">
                    <Container>
                        <Row>
                            {/* MENÚ */}
                            <Col md={4} className="menuAdministrador">
                                <div><Link to={"/admin/"}>Inici administrador</Link></div>
                                <div><Link to={"/admin/parada"}>Parades</Link> <Link to={"/admin/parada/afegir"}><Button variant="success" size="sm">+</Button></Link></div>
                                <div><Link to={"/admin/linia"}>Línies</Link> <Link to={"/admin/linia/afegir"}><Button variant="success" size="sm">+</Button></Link></div>
                                <div><Link to={"/admin/expedicio"}>Expedicions</Link> <Link to={"/admin/expedicio/afegir"}><Button variant="success" size="sm">+</Button></Link></div> 
                                <div><Link to={"/admin/avis"}>Avisos</Link> <Link to={"/admin/avis/afegir"}><Button variant="success" size="sm">+</Button></Link></div>
                                <div><Link to={"/admin/avisUsuari"}>Avisos dels usuaris</Link></div>
                                <br/><br/>
                                <div><Button variant="danger" onClick={()=>this.logOut()}>Desconnectar</Button></div>                 
                            </Col>

                            {/* PÀGINES DE L'ADMINISTRADOR */}
                            <Col md={8}>
                                <Route exact path ="/admin/" render={() => (<AdminPortada token={this.state.token}/>)}/>
                                <Route path ={["/admin/parada/:opcions", "/admin/parada"]}  render={(params) => (<AdminParades token={this.state.token} {...params}/>)}/>
                                <Route path ={["/admin/linia/:opcions","/admin/linia"]} render={(params) => (<AdminLinies token={this.state.token} {...params}/>)}/>
                                <Route path ={["/admin/expedicio/:opcions", "/admin/expedicio"]} render={(params) => (<AdminExpedicions token={this.state.token} {...params}/>)}/>
                                <Route path ={["/admin/avis/:opcions", "/admin/avis"]} render={(params) => (<AdminAvisos token={this.state.token} {...params}/>)}/>
                                <Route exact path ="/admin/avisUsuari" render={() => (<AdminAvisUsuari/>)}/>
                            </Col> 
                        </Row>
                    </Container>
                </div>

                }
            
            </div>
        )
    }
}

export default Administrador;