import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
require('moment/locale/ca');

class DadesItinerari extends Component {

    state = {
        data:null
      }

    handleCanviData(data) {
        
        //Preparem el format de la data
        var novaData = new Date(data);
        // var dd = String(novaData.getDate()).padStart(2, '0');
        // var mm = String(novaData.getMonth() + 1).padStart(2, '0'); //January is 0!
        // var yyyy = novaData.getFullYear();

        // var dataResposta = yyyy + '-' + mm + '-' + dd;
        
        this.setState({data:novaData})

      };

      componentDidMount() {
          this.handleCanviData(this.props.dades.data);
      }


    render() {
        

        return (
            <div className="dadesItinerari cap">
                <h2>{this.props.dades.origen.nom} <FontAwesomeIcon icon="arrow-right" /> {this.props.dades.destinacio.nom}</h2>
                <p><Moment locale="ca" format="dd. LL">{this.props.dataRaw}</Moment>. Sortida: {this.props.dades.hora}</p>
                <p className="modificarItinerari"><Link to={"/"}><FontAwesomeIcon icon="undo" /> Modificar itinerari</Link> · <Link to={"#"} onClick={()=>this.props.invertirRecorregut()}><FontAwesomeIcon icon="exchange" /> Invertir recorregut</Link></p>
            </div>
        )
    }



}

{/* <h2>
De {this.state.resposta.dadesItinerari.origen.nom} a {this.state.resposta.dadesItinerari.destinacio.nom}
</h2> */}
export default DadesItinerari