import React, {Component} from 'react';
import {Form, Col} from 'react-bootstrap';
import TaulaHorari from './TaulaHorari';

class HorarisLinia extends Component {
    
    constructor(){
        super()
        this.handleChange=this.handleChange.bind(this)
      }
      
    state = {
        ordre:'DESC',
        diaSetmana:'dilluns'
    }

    handleChange(e) { //Mirem l'opció que ha seleccionat
        let {name, value} = e.target;
        this.setState({
          [name]: value,
        });
    }

    render() {

        const primeraParada = this.props.parades[0].nom;
        const ultimaParada = this.props.parades[this.props.parades.length-1].nom;

        return(
            <div>
                <Form.Row>
                    <Form.Group controlId="formGridOrdre" as={Col}>
                        <Form.Label>Direcció</Form.Label>
                        <Form.Control as="select" name="ordre" onChange={this.handleChange}>
                            <option value="DESC">{primeraParada} > {ultimaParada}</option>
                            <option value="ASC">{ultimaParada} > {primeraParada}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formGridDiaSetmana" as={Col}>
                        <Form.Label>Dia de la setmana</Form.Label>
                        <Form.Control as="select" name="diaSetmana" onChange={this.handleChange}>
                            <option value="dilluns">Dilluns</option>
                            <option value="dimarts">Dimarts</option>
                            <option value="dimecres">Dimecres</option>
                            <option value="dijous">Dijous</option>
                            <option value="divendres">Divendres</option>
                            <option value="dissabte">Dissabte</option>
                            <option value="festiu">Diumenge i festius</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <TaulaHorari codiLinia={this.props.codiLinia} ordre={this.state.ordre} diaSetmana={this.state.diaSetmana}/>
            </div>
        )
    }
}

export default HorarisLinia