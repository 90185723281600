import React, {Component} from 'react';
import {Button, Form, Container, Row, Col, DropdownButton, Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import API from 'Api';
import Carregant from '../../estructura/Carregant';

class FormulariAvisUsuari extends Component {

    constructor(){
        super()
        this.seleccionaIncidencia=this.seleccionaIncidencia.bind(this)
        this.seleccionaParada=this.seleccionaParada.bind(this)
        this.retornaTitolParada=this.retornaTitolParada.bind(this)
        this.handleClick=this.handleClick.bind(this)
        this.afegeixAvis=this.afegeixAvis.bind(this)
        this.assignaRetard=this.assignaRetard.bind(this)
        this.obteIdTipusIncidencia=this.obteIdTipusIncidencia.bind(this)
      }

state = {
    idExpedicio: null,
    pantalla: 1,
    tipusIncidencia: null, //1-retard, 2-anulat, 4-afluencia
    paradaSeleccionada:null,
    minutsRetard:5,
    missatgeAxios: null

}

//Intruccions formulari: http://react.tips/radio-buttons-in-react-16/


seleccionaIncidencia(event) {
    this.setState({
        tipusIncidencia: event.target.value
    });
  };

  seleccionaParada(event) {
    this.setState({
        paradaSeleccionada: event
    });
  };

  assignaRetard(event) {
    this.setState({
        minutsRetard: event
    });
  };

  componentDidMount(){
      this.setState({idExpedicio:this.props.idExpedicio})
  }

  retornaTitolParada() {
      if (!this.state.paradaSeleccionada) {
          return "Escull parada"
      } else {
          for (var i=0; i<this.props.parades.length; i++){ //Recorrem l'array de parades per agafar el nom que correspon a l'ID seleccionat
              if (this.props.parades[i].id===this.state.paradaSeleccionada){
                  return this.props.parades[i].nom;
              }
          }
      }
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({pantalla:2})
    this.afegeixAvis();
  }
  
  afegeixAvis() {

    const dadesAvis = {
        idExpedicio: this.state.idExpedicio,
        idParada:this.state.paradaSeleccionada,
        tipusAvis: this.obteIdTipusIncidencia(this.state.tipusIncidencia), //1-retard, 2-anulat, 4-afluencia
        retard:this.state.minutsRetard
    };

    API.post(`avisUsuari/`, { dadesAvis })
      .then(res => {
        if (res.status===201){
            this.setState({pantalla:3, missatgeAxios:res.data.missatge})
            this.props.actualitzaLlista();
        } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
      }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 404

  }

obteIdTipusIncidencia(nomTipusIncidencia){
    switch (nomTipusIncidencia){
        case 'retard': return 1;
        case 'anulat': return 2;
        case 'afluencia': return 4;
    }
}

render() {

    var titolParadaSeleccionda = this.retornaTitolParada();

    return (
        <div>

        {this.state.pantalla===1 && //PANTALLA 1

        <div><Container>
            <form className="formulariNouAvisUsuari">
                <Row>Quin tipus d'incidència s'ha produit?</Row>           
                <Row>
                    <Col>
                <div className="radio">
            <label className={this.state.tipusIncidencia==="retard" ? "incidenciaSeleccionada seleccionarIncidencia" : "seleccionarIncidencia"}>
                <input type="radio" hidden value="retard" checked={this.state.tipusIncidencia==="retard"} onChange={this.seleccionaIncidencia} /> 
                <div><FontAwesomeIcon icon="hourglass-half" size="4x"/></div>
                <div className="etiquetaIncidencia">Retard</div>
                
            </label>            
            </div></Col>
            <Col>
            <div className="radio">
            <label className={this.state.tipusIncidencia==="anulat" ? "incidenciaSeleccionada seleccionarIncidencia" : "seleccionarIncidencia"}>
                <input type="radio" hidden value="anulat" checked={this.state.tipusIncidencia==="anulat"} onChange={this.seleccionaIncidencia}/>
                <div><FontAwesomeIcon icon="times" size="4x"/></div>
                <div className="etiquetaIncidencia">No ha arribat</div>
            </label>
            </div></Col>
            <Col>
            <div className="radio">
            <label className={this.state.tipusIncidencia==="afluencia" ? "incidenciaSeleccionada seleccionarIncidencia" : "seleccionarIncidencia"}>
                <input type="radio" hidden value="afluencia" checked={this.state.tipusIncidencia==="afluencia"} onChange={this.seleccionaIncidencia}/>
                <div><FontAwesomeIcon icon="users" size="4x"/></div>
                <div className="etiquetaIncidencia">Afluència de passatge</div>
            </label>
            </div></Col>
            </Row>
            {this.state.tipusIncidencia==="retard" &&
                     <div>                         
                     <Row>Quants minuts de retard?</Row>
                     <Row>
                            <DropdownButton
                        variant="outline-secondary"
                        title={this.state.minutsRetard}
                        id="input-group-dropdown-1"
                        onSelect={this.assignaRetard}
                        >
                            <Dropdown.Item eventKey="5">5</Dropdown.Item>
                            <Dropdown.Item eventKey="10">10</Dropdown.Item>
                            <Dropdown.Item eventKey="15">15</Dropdown.Item>
                            <Dropdown.Item eventKey="20">20</Dropdown.Item>
                            <Dropdown.Item eventKey="30">30</Dropdown.Item>
                            <Dropdown.Item eventKey="45">45</Dropdown.Item>
                            <Dropdown.Item eventKey="60">60</Dropdown.Item>
                            <Dropdown.Item eventKey="90">90</Dropdown.Item>

                        </DropdownButton>
                    </Row></div>
                }

                {this.state.tipusIncidencia &&
                     <div>                         
                     <Row>A quina parada et trobes?</Row>
                     <Row>
                            <DropdownButton
                        variant="outline-secondary"
                        title={titolParadaSeleccionda}
                        id="input-group-dropdown-1"
                        onSelect={this.seleccionaParada}
                        >
                            {this.props.parades.map((parada,index) =>
                            <Dropdown.Item eventKey={parada.id} key={"llistaParades-idParada"+parada.id}>{parada.nom}</Dropdown.Item>
                            )}
                        </DropdownButton>
                    </Row></div>
                }

                    {this.state.tipusIncidencia&&this.state.paradaSeleccionada &&   
                         <div><Row><Button variant="secondary" onClick={this.handleClick}>Confirmar</Button></Row></div>   
                        }

            </form> 
            </Container>
        </div>

        }

        {this.state.pantalla===2 && //PANTALLA 2: envia petició
        <div>
            <Carregant/>
        </div>

        }

        {this.state.pantalla===3 && //PANTALLA 3: mostra resultat
        <div>
            La incidència s'ha enregistrat, gràcies per informar a la resta d'usuaris!
        </div>

        }

        {this.state.pantalla===4 && //PANTALLA 4: mostra error
        <div>
            Hi ha hagut un error: {this.state.missatgeAxios}
        </div>

        }

        </div>
    )
}


}

export default FormulariAvisUsuari;