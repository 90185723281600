import React, {Component} from 'react';
import {Button, Form, Alert, Row, Col, DropdownButton, Dropdown, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import API from 'Api';
import Carregant from '../../estructura/Carregant';
import {DateTime} from 'react-datetime-bootstrap';
import Desplegable from '../elements/Desplegable';

class AfegirAvis extends Component {

    constructor(){
        super()
        this.assignaValor=this.assignaValor.bind(this)
        this.handleClick=this.handleClick.bind(this)
        this.assignaData=this.assignaData.bind(this)
        this.assignaIdObjecte=this.assignaIdObjecte.bind(this)
      }

    state = {
        objecte: "Objecte",
        llistaExpedicions:[],
        llistaLinies:[],
        llistaParades:[],
        idObjecte:null,
        llistaTipusAvis:[],
        pantalla: 1,
        missatgeAxios: null,
        liniaSeleccionada:0
    }

    //ASSIGNAR VARIABLES PER ENVIAR
       //ASSIGNAR VARIABLES PER ENVIAR
       assignaValor(e) {
        this.setState({ [e.target.name]: e.target.value });
      };

      //CLIC PER ENVIAR
      handleClick(event) {
        event.preventDefault();
        this.setState({pantalla:2})
        this.afegeix();
      }

      assignaData(data, tipus) {
        
        //Preparem el format de la data
        var novaData = new Date(data);
        var dd = String(novaData.getDate()).padStart(2, '0');
        var mm = String(novaData.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = novaData.getFullYear();

        var dataResposta = dd + '-' + mm + '-' + yyyy;
        
        //Assignem la data a l'estat corresponent
        tipus==="dataInici" && this.setState({dataInici:dataResposta})
        tipus==="dataFinal" && this.setState({dataFinal:dataResposta})

      };

        //ASSIGNA QUIN TIPUS D'OBJECTE ES VOL AFEGIR L'AVIS
        assignaObjecte(event) {    
        this.setState({objecte:event, idObjecte:null});
        };

        //ASSIGNA QUIN TIPUS D'OBJECTE ES VOL AFEGIR L'AVIS
        assignaIdObjecte(event) {    
            this.setState({idObjecte:event});
            };
        

        //ASSIGNAR PARADA A L'ITINERARI
       retornaObjecte() {    
            if (this.state.objecte==="parada") {           
                return "Parada";
            } 
            if (this.state.objecte==="linia") { 
                return "Línia";
            } 
            if (this.state.objecte==="expedicio") return "Expedició";
            return "Escull objecte..."
            };


            //CARREGA PARADES
            carregaParades() {    
                API.get(`parada/?ordre=nom`)
                  .then(res => {
                    if (res.status===200){
                        this.setState({llistaParades:res.data.llistat});
                    } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
                  }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
            
              } 

            //CARREGA LINIES
            carregaLinies() {    
                API.get(`linia/`)
                  .then(res => {
                    if (res.status===200){
                        this.setState({llistaLinies:res.data.llistat});
                    } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
                  }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
            
              }
            
            //CARREGA LLISTA EXPEDICIONS
            carregaExpedicions() {    
                API.get(`expedicio/`)
                  .then(res => {
                    if (res.status===200){
                        this.setState({llistaExpedicions:res.data.llistat});
                    } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
                  }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
            
              }
            
            //CARREGA TIPUS AVIS
            carregaTipusAvis() {    
                API.get(`avis/?tipus`)
                  .then(res => {
                    if (res.status===200){
                        this.setState({llistaTipusAvis:res.data});
                    } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
                  }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
            
              }

      //ORDRE AFEGIR

      afegeix() {

        const dadesAvis = {
            text: this.state.text,
            //codiPostal:this.state.codiPostal,
            titol:this.state.titol,
            tipusObjecte: this.state.objecte,
            idObjecte: this.state.idObjecte,
            dataInici: this.state.dataInici,
            dataFinal: this.state.dataFinal,
            tipusAvis: this.state.tipusAvis
        };
    
        API.post(`avis/`, { dadesAvis, token:this.props.token })
          .then(res => {
            if (res.status===201){
                this.setState({pantalla:3, missatgeAxios:res.data.missatge});
                this.props.refrescaAvisos();
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }

        
      componentDidMount() {
        this.carregaLinies();
        this.carregaExpedicions();
        this.carregaParades();
        this.carregaTipusAvis();
      }

      titolSeleccionat(tipusObjecte) {
        if (tipusObjecte==="parada") {           
            var paradaSeleccionada=this.state.llistaParades.filter((parada)=>parada.id===this.state.idObjecte)
            
            if (paradaSeleccionada.length>0){
                return paradaSeleccionada[0].nom;
            }
            return "Escull parada...";
        } 

        if (tipusObjecte==="linia") {           
            var liniaSeleccionada=this.state.llistaLinies.filter((linia)=>linia.codi===this.state.idObjecte)
            
            if (liniaSeleccionada.length>0){
                return liniaSeleccionada[0].sigles + ": " + liniaSeleccionada[0].nom;
            }
            return "Escull línia...";
        } 

        if (tipusObjecte==="liniaExpedicio") {           
            var liniaSeleccionada=this.state.llistaLinies.filter((linia)=>linia.codi===this.state.liniaSeleccionada)
            
            if (liniaSeleccionada.length>0){
                return liniaSeleccionada[0].sigles + ": " + liniaSeleccionada[0].nom;
            }
            return "Escull línia...";
        } 

        if (tipusObjecte==="expedicio") {           
            var expedicioSeleccionada=this.state.llistaExpedicions.filter((expedicio)=>expedicio.id===this.state.idObjecte)
            
            if (expedicioSeleccionada.length>0){
                return expedicioSeleccionada[0].paradaInici.hora + ": " + expedicioSeleccionada[0].paradaInici.nom+ " > " + expedicioSeleccionada[0].paradaFinal.hora + ": " + expedicioSeleccionada[0].paradaFinal.nom;
            }
            return "Escull expedició...";
        } 

        if (tipusObjecte==="tipusAvis") {           
            var tipusAvisSeleccionat=this.state.llistaTipusAvis.filter((tipusAvis)=>tipusAvis.id===this.state.tipusAvis)
            
            if (tipusAvisSeleccionat.length>0){
                return tipusAvisSeleccionat[0].nom;
            }
            return "Escull tipus d'avís...";
        } 

        return "Falta programar el retorn de la llista";
    }

    render() {

        //FILTRA LLISTA EXPEDICIONS
        var arrayExpedicionsLiniaSeleccionada = this.state.llistaExpedicions.filter((expedicio) => expedicio.linia.codi===this.state.liniaSeleccionada);
        if (this.state.liniaSeleccionada===0) arrayExpedicionsLiniaSeleccionada = this.state.llistaExpedicions;

        return (
            
            <div>
                <Card style={{ width: '100%' }}>
                    <Card.Header>Nou Avís</Card.Header>

                     {this.state.pantalla===1 && //PANTALLA 1: FORMULARI
                        <Card.Body>   
                            <Form.Group controlId="formSeleccioObjecte">                        
                             <DropdownButton
                                variant="outline-secondary"
                                title={this.retornaObjecte()}
                                id="input-group-dropdown-1"
                                onSelect={(event) => this.assignaObjecte(event)}
                                >
                                 
                                        <Dropdown.Item eventKey="parada" key="parada">Parada</Dropdown.Item>
                                        <Dropdown.Item eventKey="linia" key="linia">Línia</Dropdown.Item>
                                        <Dropdown.Item eventKey="expedicio" key="expedicio">Expedició</Dropdown.Item>
                            </DropdownButton>
                            </Form.Group>

                            {this.state.objecte==="parada" &&
                            
                            <Form.Group controlId="formSeleccioObjecte">                        
                                <Desplegable elements={this.state.llistaParades} onSelect={this.assignaIdObjecte} seleccionat={this.titolSeleccionat("parada")}/>
                            </Form.Group>
                            
                            
                            }

                            {this.state.objecte==="linia" &&
                            
                            <Form.Group controlId="formSeleccioObjecte">                        
                                <Desplegable elements={this.state.llistaLinies} onSelect={this.assignaIdObjecte} seleccionat={this.titolSeleccionat("linia")}/>
                            </Form.Group>
                            
                            
                            }

                            {this.state.objecte==="expedicio" &&
                            <div>
                                <Form.Group controlId="formSeleccioObjecte">                        
                                    <Desplegable elements={this.state.llistaLinies} onSelect={(e)=>this.setState({liniaSeleccionada:e, idObjecte:null})} seleccionat={this.titolSeleccionat("liniaExpedicio")}/>
                                </Form.Group>
                                
                                <Form.Group controlId="formSeleccioObjecte">                        
                                    <Desplegable elements={arrayExpedicionsLiniaSeleccionada} onSelect={this.assignaIdObjecte} seleccionat={this.titolSeleccionat("expedicio")}/>
                                </Form.Group>
                            </div>
                            }

                            <Form.Group controlId="formSeleccioObjecte">                        
                                    <Desplegable elements={this.state.llistaTipusAvis} onSelect={(e)=>this.setState({tipusAvis:e})} seleccionat={this.titolSeleccionat("tipusAvis")}/>
                                </Form.Group>

                            <Form.Group controlId="formNomParada">
                                <Form.Control type="text" name="titol" placeholder="Titol de l'avís" onChange={this.assignaValor}/>
                            </Form.Group>
                            {/* <Form.Group controlId="formCodiPostal">
                                <Form.Control type="codiPostal" placeholder="Codi Postal" onChange={this.assignaCodiPostal}/>
                            </Form.Group> */}
                            <Form.Group controlId="formObservacions">    
                                <Form.Control as="textarea" rows="2" name="text" placeholder="Text" onChange={this.assignaValor}/>
                                </Form.Group>

                            <div className="periodeAvis">
                                    <span>Data inici: </span>
                                    <DateTime pickerOptions={{format:"DD-MM-YYYY", locale:"ca"}} placeholder={"Data inici"} onChange={(data)=>this.assignaData(data, "dataInici")}/>
                                    <span>Data final: </span>
                                    <DateTime pickerOptions={{format:"DD-MM-YYYY", locale:"ca"}} placeholder={"Data final"} onChange={(data)=>this.assignaData(data, "dataFinal")}/>
                                </div><br/>
                            
                            {this.state.idObjecte&&this.state.titol &&
                            
                            <Form.Group controlId="formBotoEnviar">  
                                <Button variant="success" onClick={this.handleClick}>Afegir</Button>
                            </Form.Group>   
                            
                            }
                                            
                        
                        
                        </Card.Body>
                    }

                    {this.state.pantalla===2 && //PANTALLA 2: CARREGANT
                        <Card.Body>                            
                            <Carregant/>                        
                        </Card.Body>
                    }   


                    {this.state.pantalla===3 && //PANTALLA 3: mostra resultat
                        <Alert variant="success">
                            Avís afegit correctament!
                        </Alert>

                        }

                    {this.state.pantalla===4 && //PANTALLA 4: mostra error
                        <Alert variant="danger">
                            Hi ha hagut un error: {this.state.missatgeAxios}
                            </Alert>

                        }


                </Card>
            </div>
        )
    }
}

    export default AfegirAvis;