import React, {Component} from 'react';
import {Accordion, Card, Row, Col} from 'react-bootstrap';
import LlistaResultats from './LlistaResultats';
import DetallResultat from './DetallResultat';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Element} from "react-scroll";


class AcordioResultats extends Component {


    render() {

        return (
            <div>
                {/* Comprovem si hem rebut dades sobre l'itinerari fins la parada origen */}
                
                {this.props.itinerariAParadaOrigen &&
                
                    <div className="acordio acordioResultat">
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" className="maBoto">
                            <span className="noSelect"><FontAwesomeIcon icon="walking" /> Recorregut a peu fins a parada {this.props.dades.paradaOrigen.nom} ({this.props.itinerariAParadaOrigen.routes[0].legs[0].distance.text} - {this.props.itinerariAParadaOrigen.routes[0].legs[0].duration.text})</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            <ul>
                                { this.props.itinerariAParadaOrigen.routes[0].legs[0].steps.map((steps, index) => <li key={index}><span dangerouslySetInnerHTML={{__html: steps.html_instructions}}></span> ({steps.distance.text})</li>)}
                                {/* Com que rebem dades HTML, faig servir dangerouslySetInnerHTML per fer el render correcte */}
                            </ul>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    </div>

                }                

                <div className="resultats">
                <p>Escull un resultat per veure'n els detalls.</p>
                {/* <h4>Resultats</h4> */}
                <Row>
                        <Col md><LlistaResultats itineraris={this.props.itineraris} setItinerariVisible={this.props.setItinerariVisible} itinerariVisible={this.props.itinerariVisible} itinerariTriat={this.props.itinerariTriat}/> </Col>
                        <br />  
                        <Col md className="separacioColumna"><Element name="detall_resultat"><DetallResultat  itineraris={this.props.itineraris} dades={this.props.dades} itinerariVisible={this.props.itinerariVisible} itinerariTriat={this.props.itinerariTriat}/></Element></Col>
                </Row>
                 </div>  
                
                {/* Comprovem si hem rebut dades sobre l'itinerari des de la parada final */}
                
                {this.props.itinerariAParadaDestinacio &&

                    <div className="acordio acordioResultat">
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2" className="maBoto">
                            <span className="noSelect"><FontAwesomeIcon icon="walking" /> Recorregut a peu des de parada {this.props.dades.paradaDestinacio.nom} ({this.props.itinerariAParadaDestinacio.routes[0].legs[0].distance.text} - {this.props.itinerariAParadaDestinacio.routes[0].legs[0].duration.text})</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                            <Card.Body>
                            <ul>
                                { this.props.itinerariAParadaDestinacio.routes[0].legs[0].steps.map((steps, index) => <li key={index}><span dangerouslySetInnerHTML={{__html: steps.html_instructions}}></span> ({steps.distance.text})</li>)}
                                {/* Com que rebem dades HTML, faig servir dangerouslySetInnerHTML per fer el render correcte */}
                            </ul>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    </div>
                }
            </div>
        )
    }



}

export default AcordioResultats