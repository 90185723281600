import React, {Component} from 'react';

import { compose, withProps, withStateHandlers } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"

const MapaGoogle = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCYEQJ7nw4waLh4o_IvlQHDCzGVQQvlOOI&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `300px` }} />,
      mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
   
    <GoogleMap
      zoom={10}
      defaultCenter={{lat:Number(props.centre.lat), lng: Number(props.centre.lng)}}
      onClick={(e) => props.getCoordinates(e)}
    >
    {props.latitud &&
                <Marker
                position={{lat: Number(props.latitud), lng: Number(props.longitud)}}
                title="Ubicació escollida" 
                key="ubicacio_escollida" 
                ></Marker>
    }

    </GoogleMap>

   




  )



class MapaSeleccioPunt extends Component {
    constructor(){
        super()
        this.getCoordinates=this.getCoordinates.bind(this)
    }

    state = {
      centre: {lat: 42.098871, lng: 1.860893},
      editar: false
    }
    
    //Funció per capturar les coordenades en clicar
    getCoordinates(event) {
        var lat = event.latLng.lat(), lng = event.latLng.lng()
        this.props.assignaLatitud(lat);
        this.props.assignaLongitud(lng);
    }

    componentDidUpdate() {  
      //Preparem el centre del mapa si hem rebut dades o no
            if (this.props.latitud&&!this.state.editar) {
              var centre = {lat: Number(this.props.latitud), lng: Number(this.props.longitud)};
              this.setState({centre, editar:true});              
            } 
  }

    render() {

     


    return (
        <div><MapaGoogle getCoordinates={this.getCoordinates} latitud={this.props.latitud} longitud={this.props.longitud} centre = {this.state.centre}/></div>

    )
}


}

export default MapaSeleccioPunt