import React, {Component} from 'react';

import API from 'Api';


class LlistaParades extends Component {
    
    state = {
        llistats: []
      }

  componentDidMount() {
    API.get(`parada/`)
      .then(res => {
        const llistats = res.data.llistat;
        this.setState({ llistats });
        
      })
  }

  render() {
    console.log(this.state)
    return (
      <ul>
        { this.state.llistats.map(llistat => <li>{llistat.nom}, {llistat.poblacio}</li>)}
      </ul>
    )
  }
}
export default LlistaParades;