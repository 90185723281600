import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import API from 'Api';
import {Link} from 'react-router-dom';
import AfegirExpedicio from './expedicions/AfegirExpedicio';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EsborrarExpedicio from './expedicions/EsborrarExpedicio';
import EditarExpedicio from './expedicions/EditarExpedicio';
import DesplegableLinies from './elements/DesplegableLinies';
import EditarParadesExpedicio from './expedicions/EditarParadesExpedicio';
import CanviLectius from './expedicions/CanviLectius';

class AdminExpedicions extends Component {
    
    constructor(){
        super()
        this.canviAfegirExpedicio=this.canviAfegirExpedicio.bind(this)
        this.carregaExpedicions=this.carregaExpedicions.bind(this)
        this.obreEsborrarExpedicio=this.obreEsborrarExpedicio.bind(this)
        this.generaDiesCirculacio=this.generaDiesCirculacio.bind(this)
        this.tancaFinestra=this.tancaFinestra.bind(this)
        this.filtraLinia=this.filtraLinia.bind(this)
        this.obreEditarParadesExpedicio=this.obreEditarParadesExpedicio.bind(this)
      }

      carregaLinies() {    
        API.get(`linia/`)
          .then(res => {
            if (res.status===200){
                this.setState({linies:res.data.llistat})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      } 

      carregaExpedicions() {    
        var baseURL=`expedicio/`;
        if (this.props.match.params.opcions&&this.props.match.params.opcions!="afegir") {
            baseURL='expedicio/?codiLinia=' + this.props.match.params.opcions;
        } 
        
        API.get(baseURL)
          .then(res => {
            if (res.status===200){
                this.setState({expedicions:res.data.llistat})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      } 



    state = {
        afegirExpedicio:false,
        linies: [],
        expedicions: [],
        finestraEsborrar: false,
        finestraEditar: false,
        finestraEditarParades:false,
        expedicioEditar: null,
        codiLinia:null
    }
    
    canviAfegirExpedicio() {
        this.state.afegirExpedicio ? this.setState({afegirExpedicio:false}) : this.setState({afegirExpedicio:true});
    }

    obreEsborrarExpedicio(expedicio) {
        this.setState({finestraEsborrar:true, expedicioEditar:expedicio});
     }
 
     obreEditarExpedicio(expedicio) {
         this.setState({finestraEditar:true, expedicioEditar:expedicio});
      }

      obreEditarParadesExpedicio(event) {
        event.preventDefault();
        this.setState({finestraEditar:false, finestraEditarParades:true});
     }
 
 
     tancaFinestra() {
         this.setState({finestraEsborrar:false, finestraEditar:false, finestraEditarParades:false});
         this.carregaExpedicions();
      }

    colorGris(expedicio){ //COLOR PER LES PARADES FORA DE PERIODES DE CIRCULACIO
        if (!expedicio.circula) {
            return "#d0d0d0";
        } 
    }

    generaDiesCirculacio(arrayDies) {
        var resposta = arrayDies.map((dia, index) => dia==="1"&&<span class="diaSetmanaBola">{index}</span>)
        return resposta;

    }


    generaTaula(expedicio, index) {
        return (
            <div className="divTableRow liniaResposta noSelect" key={"linia"+index}> 
                <div className="divTableCell">
                    <div style={{backgroundColor: expedicio.linia.color}} className="siglesLinia">
                        {expedicio.linia.sigles}
                    </div>
                </div>                
                <div className="divTableCell">
                <Link to={"/expedicio/"+expedicio.id} style={{color:this.colorGris(expedicio)}}><strong>{expedicio.paradaInici.hora}</strong>: {expedicio.paradaInici.nom} <FontAwesomeIcon icon="arrow-right" color="#000000"/> <strong>{expedicio.paradaFinal.hora}</strong>: {expedicio.paradaFinal.nom}</Link>
                </div>   
                <div className="divTableCell">                 
                 {this.generaDiesCirculacio(expedicio.diesCirculacioArray)}
                 </div>
                 <div className="divTableCell">
                 <CanviLectius idExpedicio={expedicio.id} lectius={expedicio.periode.nomesLectius} carregaExpedicions={this.carregaExpedicions} token={this.props.token}/>
                 </div>
                 <div className="divTableCell">
                 <FontAwesomeIcon icon="pencil-alt" className="maBoto" color="#336122" onClick={()=>this.obreEditarExpedicio(expedicio)}/>
                 </div>
                 <div className="divTableCell">
                 <FontAwesomeIcon icon="times" className="maBoto" color="#336122" onClick={()=>this.obreEsborrarExpedicio(expedicio)}/>
                 </div>     
        </div>

        )
    }

    trobaLiniaSeleccionada(){
        if (this.state.codiLinia) {
            var nomLiniaSeleccionada = null;
            //Recorrem l'array de linies buscant el nom de l'ID corresponent
            for (var k=0; k<this.state.linies.length&&!nomLiniaSeleccionada; k++) {
                if (this.state.linies[k].codi ===this.state.codiLinia ) {
                    nomLiniaSeleccionada  = <span>{this.state.linies[k].sigles}: {this.state.linies[k].nom}</span>    
                }
            }
            return nomLiniaSeleccionada

        } else return "Escull línia..."

    }

      //ASSIGNAR PARADA A L'ITINERARI
        filtraLinia(event, index) {
            this.setState({codiLinia:event})    
            this.props.history.push('/admin/expedicio/'+event)
          };
    
    componentDidMount() {
        
        if (this.props.match&&this.props.match.params.opcions==="afegir") this.canviAfegirExpedicio(); //Si arribem amb l'opció d'afegir, s'obre el desplegable
        if (this.props.match&&this.props.match.params.opcions!="afegir") this.setState({codiLinia:this.props.match.params.opcions}) //Si arribem amb l'opció d'afegir, s'obre el desplegable
        this.carregaLinies();
        this.carregaExpedicions();
    }

      componentDidUpdate(prevProps) { //Si es canvia la url que rebem a traves de match, es torna a cridar al backend.
        if (this.props.match&&prevProps.match.url !== this.props.match.url) {
            this.carregaExpedicions();
            this.props.match&&this.props.match.params.opcions==="afegir" ? this.setState({afegirExpedicio:true}) : this.setState({afegirExpedicio:false});
        }
      }

    render() {
        var nomLiniaSeleccionada = this.trobaLiniaSeleccionada();

        return (
            
            <div className="modulInferior">
                <h2>Expedicions</h2>
                <Button variant="secondary" onClick={this.canviAfegirExpedicio}>{this.state.afegirExpedicio ? "Tancar" : "Afegir"}</Button>
                <DesplegableLinies elements={this.state.linies} onSelect={this.filtraLinia} index={0} seleccionat={nomLiniaSeleccionada}/>
                {this.state.afegirExpedicio && <AfegirExpedicio token={this.props.token} linies={this.state.linies} refrescaExpedicions={this.carregaExpedicions}/>}
                
                <div className="divTable taulaElements">
                             <div className="divTableBody">
                                {this.state.expedicions.map((expedicio,index)=> this.generaTaula(expedicio, index))}
                            </div>
                        </div>

                {this.state.finestraEsborrar && <EsborrarExpedicio tancar={this.tancaFinestra} expedicio={this.state.expedicioEditar} token={this.props.token}/>}
                {this.state.finestraEditar && <EditarExpedicio tancar={this.tancaFinestra} linies={this.state.linies} expedicio={this.state.expedicioEditar} token={this.props.token} obreEditarParadesExpedicio={this.obreEditarParadesExpedicio}/>}
                {this.state.finestraEditarParades && <EditarParadesExpedicio tancar={this.tancaFinestra} expedicio={this.state.expedicioEditar} token={this.props.token}/>}

            </div>
        )
    }
}

export default AdminExpedicions;