import React, {Component} from 'react';
import {Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


class IconaLectius extends Component {

    //REP PROPS si només circula lectius o no. 0 sempre, 1 només lectius, 2 només NO lectius
    render(){
        return(

            <span>
                {this.props.circulaLectius==="1" &&
                    <OverlayTrigger placement="top"  overlay={<Tooltip> Només circula dies lectius</Tooltip>}>
                        <FontAwesomeIcon icon="graduation-cap" />
                    </OverlayTrigger>  
                }

                {this.props.circulaLectius==="2" &&
                    <OverlayTrigger placement="top"  overlay={<Tooltip> Només circula dies NO lectius</Tooltip>}>
                        <FontAwesomeIcon icon="graduation-cap" color="#e8271a" />
                    </OverlayTrigger>  
                } 
            </span>      
            
        )
    }
}

export default IconaLectius