import React, {Component} from 'react';

import API from 'Api';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


class AdminAvisUsuari extends Component {
    
    constructor(){
        super()
        this.carregaAvisos=this.carregaAvisos.bind(this)
      }

      carregaAvisos() {    
        API.get(`avisUsuari/`)
          .then(res => {
            if (res.status===200){
                this.setState({avisos:res.data.llista_avisos})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      } 

      //CARREGA TIPUS AVIS
      carregaTipusAvis() {    
        API.get(`avis/?tipus`)
            .then(res => {
            if (res.status===200){
                this.setState({llistaTipusAvis:res.data});
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
            }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X

        }

  nomTipusAvis(idTipusAvis) {
    if (this.state.llistaTipusAvis.length>0) {
        var tipusAvis = this.state.llistaTipusAvis.filter((avis)=>avis.id===idTipusAvis);
    return tipusAvis[0].nom
    } return idTipusAvis
}

    state = {
        avisos: [],
        llistaTipusAvis:[]
    }

    tancaFinestra() {
        this.setState({finestraEsborrar:false, finestraEditar:false});
        this.carregaAvisos();
     }

    generaTaula(avis, index) {
        return (
            <div className="divTableRow avisResposta noSelect" key={"avisUsuari"+index}> 
                <div className="divTableCell avisUsuariData">
                {avis.dia}
                </div>
                <div className="divTableCell">
                {avis.hora}
                </div>
                <div className="divTableCell">
                    <div style={{backgroundColor: avis.expedicio.linia.color}} className="siglesLinia">
                        {avis.expedicio.linia.sigles}
                    </div>
                </div>
                <div className="divTableCell">
                <Link to={"/expedicio/"+avis.expedicio.id}><strong>{avis.expedicio.paradaInici.hora}</strong>: {avis.expedicio.paradaInici.nom} <FontAwesomeIcon icon="arrow-right" color="#000000"/> <strong>{avis.expedicio.paradaFinal.hora}</strong>: {avis.expedicio.paradaFinal.nom}</Link>
                 </div>    
                <div className="divTableCell">
                    {this.nomTipusAvis(avis.tipusAvis)}
                     {avis.tipusAvis==="1" && 
                         <span> ({avis.retard} min)</span>
                     }

                 </div>
                 <div className="divTableCell">
                    {avis.parada.nom}
                 </div>
                 <div className="divTableCell">
                 </div>     
        </div>

        )
    }

    componentDidMount() {
        this.carregaAvisos();
        this.carregaTipusAvis();
      }

    
    render() {
        return (
            <div className="modulInferior">
                <h2>Incidències notificades pels usuaris</h2>
                <div className="divTable taulaElements">
                             <div className="divTableBody">
                                {this.state.avisos.map((avis,index)=> this.generaTaula(avis, index))}
                            </div>
                        </div>

            </div>
        )
    }
}

export default AdminAvisUsuari;