import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import API from 'Api';
import AfegirLinia from './linies/AfegirLinia';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EsborrarLinia from './linies/EsborrarLinia';
import EditarLinia from './linies/EditarLinia';

class AdminLinies extends Component {
    
    constructor(){
        super()
        this.canviAfegirLinia=this.canviAfegirLinia.bind(this)
        this.carregaLinies=this.carregaLinies.bind(this)
        this.obreEsborrarLinia=this.obreEsborrarLinia.bind(this)
        this.tancaFinestra=this.tancaFinestra.bind(this)
      }

      carregaLinies() {    
        API.get(`linia/`)
          .then(res => {
            if (res.status===200){
                this.setState({linies:res.data.llistat})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      } 

    state = {
        afegirLinia:false,
        linies: [],
        finestraEsborrar: false,
        finestraEditar: false,
        liniaEditar: null
    }
    
    canviAfegirLinia() {
        this.state.afegirLinia ? this.setState({afegirLinia:false}) : this.setState({afegirLinia:true});
    }

    obreEsborrarLinia(linia) {
       this.setState({finestraEsborrar:true, liniaEditar:linia});
    }

    obreEditarLinia(linia) {
        this.setState({finestraEditar:true, liniaEditar:linia});
     }


    tancaFinestra() {
        this.setState({finestraEsborrar:false, finestraEditar:false});
        this.carregaLinies();
     }

    generaTaula(linia, index) {
        return (
            <div className="divTableRow liniaResposta noSelect" key={"linia"+index}> 
                <div className="divTableCell">
                    <div style={{backgroundColor: linia.color}} className="siglesLinia">
                        {linia.sigles}
                    </div>
                </div>
                <div className="divTableCell">
                <Link to={"/linia/"+linia.codi}>{linia.nom}</Link>
                 </div>
                 <div className="divTableCell">
                 <FontAwesomeIcon icon="pencil-alt" className="maBoto" color="#336122" onClick={()=>this.obreEditarLinia(linia)}/>
                 </div>
                 <div className="divTableCell">
                 <FontAwesomeIcon icon="times" className="maBoto" color="#336122" onClick={()=>this.obreEsborrarLinia(linia)}/>
                 </div>     
        </div>

        )
    }

    componentDidMount() {
        this.carregaLinies();
        if (this.props.match&&this.props.match.params.opcions==="afegir") this.canviAfegirLinia(); //Si arribem amb l'opció d'afegir, s'obre el desplegable
      }

      componentDidUpdate(prevProps) { //Si es canvia la url que rebem a traves de match, es torna a cridar al backend.
        if (this.props.match&&prevProps.match.url !== this.props.match.url) {
            this.props.match&&this.props.match.params.opcions==="afegir" ? this.setState({afegirLinia:true}) : this.setState({afegirLinia:false});
        }
      }
    
    render() {
        return (
            <div className="modulInferior">
                <h2>Línies</h2>
                <Button variant="secondary" onClick={this.canviAfegirLinia}>{this.state.afegirLinia ? "Tancar" : "Afegir"}</Button>
                {this.state.afegirLinia && <AfegirLinia token={this.props.token} refrescaParades={this.carregaLinies}/>}
                <div className="divTable taulaElements">
                             <div className="divTableBody">
                                {this.state.linies.map((linia,index)=> this.generaTaula(linia, index))}
                            </div>
                        </div>
                
                {/* // Si s'activa la finestra d'esborrar */}
                {this.state.finestraEsborrar && <EsborrarLinia tancar={this.tancaFinestra} linia={this.state.liniaEditar} token={this.props.token}/>}
                {this.state.finestraEditar && <EditarLinia tancar={this.tancaFinestra} linia={this.state.liniaEditar} token={this.props.token}/>}
                



            </div>
        )
    }
}

export default AdminLinies;