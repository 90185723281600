import React, {Component} from 'react';
import {Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';



class LlistaResultats extends Component {

    constructor(){
        super()
        this.generaResultat=this.generaResultat.bind(this)
    }

    generaResultat(itinerari, index) {
        var urlConnexio = process.env.PUBLIC_URL + 'img/connexio_resultat.svg';
        
        return (
            <div className="grupFiles">
            <div className={index===this.props.itinerariVisible&&this.props.itinerariTriat ? "divTableRow liniaRespostaItinerari maBoto itinerariSeleccionat" : "divTableRow liniaRespostaItinerari maBoto"} index={index} onClick={() => this.props.setItinerariVisible(index)}>
                <div className="divTableCell siglesLiniaResultat">
                    {itinerari.map((servei)=>
                    <div style={{backgroundColor: servei.linia.color}} className="siglesLinia" aria-label="Línia">
                        {servei.linia.sigles}
                    </div>)}
                </div>
                <div className="divTableCell horaOrigen" aria-label="Hora de sortida">
                {itinerari[0].horesPas.paradaOrigen}
                 </div>
                 <div className="divTableCell imgConnexio"><img src={urlConnexio} alt=">"/></div>    
                <div className="divTableCell horaDestinacio" aria-label="Hora d'arribada">
                {itinerari[itinerari.length-1].horesPas.paradaDestinacio}
                {/* //Agafem l'horari final del recorregut */}
                </div>
                <div className="divTableCell duradaItinerari" aria-label="Durada de l'itinerari">
                <p><FontAwesomeIcon icon="clock" />
                {itinerari[itinerari.length-1].durada_total.h!==0 && <span> {itinerari[itinerari.length-1].durada_total.h}h</span>}
                <span> {itinerari[itinerari.length-1].durada_total.m}m </span></p>
                
                </div>    
                <div className="divTableCell">
                {(itinerari[0].linia.transport_demanda||itinerari[itinerari.length-1].linia.transport_demanda) && <OverlayTrigger placement="top"  overlay={<Tooltip> Transport a la demanda</Tooltip>}>
                    <Badge variant="secondary" aria-label="Aquest itinerari conté un trajecte de transport a la demanda">TD</Badge>
                        </OverlayTrigger>              
                }</div>    
        </div>

        <div className="divTableRow separadorFiles"></div>

        </div>

        )
    }

    render() {    
        
        return (
            <div>
                
                <div className="divTable">
                    
                    <div className="divTableBody">
                    <div className="divTableRow">
                        <div className="divTableCell"></div>
                        <div className="divTableCell horaOrigen"></div>
                        <div className="divTableCell"></div>
                        <div className="divTableCell"></div>
                        <div className="divTableCell"></div>
                    </div>
                    { this.props.itineraris.map((itinerari, index) => this.generaResultat(itinerari, index))} 
                    </div>
                </div>
            </div>
        )
    }

}

export default LlistaResultats;