import IconaLectius from 'Components/linies/IconaLectius';
import React, {Component} from 'react';
import { LinkContainer } from "react-router-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
require('moment/locale/ca');

class DetallsExpedicio extends Component {

generaDiesSetmana() {
    //De més a menys restrictiu
    if (this.props.dades.diesCirculacio.dilluns&&this.props.dades.diesCirculacio.dimarts&&this.props.dades.diesCirculacio.dimecres&&this.props.dades.diesCirculacio.dijous&&this.props.dades.diesCirculacio.divendres&&this.props.dades.diesCirculacio.dissabte&&this.props.dades.diesCirculacio.festiu) {
        return "Circula cada dia, festius inclosos"
    } else if (this.props.dades.diesCirculacio.dilluns&&this.props.dades.diesCirculacio.dimarts&&this.props.dades.diesCirculacio.dimecres&&this.props.dades.diesCirculacio.dijous&&this.props.dades.diesCirculacio.divendres&&this.props.dades.diesCirculacio.dissabte) {
        return "Circula de dilluns a dissabte no festius"
    } else if (this.props.dades.diesCirculacio.dilluns&&this.props.dades.diesCirculacio.dimarts&&this.props.dades.diesCirculacio.dimecres&&this.props.dades.diesCirculacio.dijous&&this.props.dades.diesCirculacio.divendres) {
        return "Circula de dilluns a divendres no festius"
    } else if (this.props.dades.diesCirculacio.dissabte&&this.props.dades.diesCirculacio.festiu) {
        return "Circula dissabte, diumenge i festius"
    } else if (this.props.dades.diesCirculacio.dissabte) {
        return "Circula dissabtes no festius"
    } else if (this.props.dades.diesCirculacio.festiu) {
        return "Circula diumenges i festius"
    } else return "ERROR";


}

formatData(novaData){
    novaData = new Date(novaData);
    var dd = String(novaData.getDate()).padStart(2, '0');
    var mm = String(novaData.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = novaData.getFullYear();
  
    var dataFormat = dd + '-' + mm + '-' + yyyy;
    return dataFormat;
}

render(){
    var dataRebuda
    var dataActual
    
    if (this.props.dades.periode.inici) {
        var dataRebuda = new Date(this.props.dades.periode.inici);
        var dataActual = new Date();
    }

    return (
        <div>
             <LinkContainer to={"/linia/"+this.props.dades.linia.codi}><div className="nomLiniaSuperior maBoto"><div style={{backgroundColor: this.props.dades.linia.color}} className="siglesLinia ">
                        {this.props.dades.linia.sigles}
                    </div><div className="nomLinia">{this.props.dades.linia.nom}</div></div></LinkContainer>
            <h2>Servei des de {this.props.dades.paradaInici.nom} a {this.props.dades.paradaFinal.nom}</h2>
            <p>Sortida a les <b>{this.props.dades.paradaInici.hora}</b>. Arribada a les <b>{this.props.dades.paradaFinal.hora}</b>.</p>
            <p>{this.props.numParades} parades</p>
            <p>{this.generaDiesSetmana()}{(this.props.dades.periode.inici&&(dataRebuda>dataActual)) && <span> a partir del {this.formatData(this.props.dades.periode.inici)} </span>} {this.props.dades.periode.final && <span>fins al {this.formatData(this.props.dades.periode.final)}</span>}.</p>
            
            {/* Dies circulació especial */}
            {this.props.dades.circulaDies &&<p><FontAwesomeIcon icon="info-circle" color="#000000"/> Circula també els dies{this.props.dades.circulaDies.map((data)=><span> - <Moment locale="ca" format="DD/MM/YY">{data}</Moment></span>)}.</p>}
            {this.props.dades.noCirculaDies &&<p><b><FontAwesomeIcon icon="exclamation-triangle" color="#000000"/> No circula els dies{this.props.dades.noCirculaDies.map((data)=><span> - <Moment locale="ca" format="DD/MM/YY">{data}</Moment></span>)}.</b></p>}
            {/* Dies Lectius */}
            {this.props.dades.periode.nomesLectius==="1" && <p><IconaLectius circulaLectius="1"/> Circula només dies lectius.</p>}
            {this.props.dades.periode.nomesLectius==="2" && <p><IconaLectius circulaLectius="2"/> Circula només dies NO lectius.</p>}
        </div>

    )
}
}

export default DetallsExpedicio