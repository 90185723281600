import IconaAgafaViatgers from 'Components/linies/IconaAgafaViatgers';
import IconaDeixaViatgers from 'Components/linies/IconaDeixaViatgers';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import puntParada from '../../img/puntparada.svg'


class RecorregutExpedicio extends Component {

generaResultat(item, color, index, totalElements) {
    if (index===0||index===(totalElements-1)){
        var posicioInicialOFInal=true;
    } else var posicioInicialOFInal=false;

    var paradaActual=false;
    if (this.props.circulaAra&&item.parada.id===this.props.circulaAra.paradesRestants[0].id) {
        paradaActual=true;
    }
    
    
    return (
        <div className="divTableRow liniaResposta noSelect" key={"linia"+index}> 
            <div className="divTableCell">
                {/* Fem servir unes etiquetes especials si és el primer o últim element de la línia */}
                
                {index===0 &&
                    <div className="recorregutParadaColor paradaIniciLinia"
                 style={{backgroundColor: color}}>
                    <img
                        src={puntParada}
                        width="10"
                        height="10"
                        className="puntParada"
                        alt="Parada"
                    />
                </div>
                }

                {index===(totalElements-1) &&
                    <div className="recorregutParadaColor paradaFinalLinia"
                 style={{backgroundColor: color}}>
                    <img
                        src={puntParada}
                        width="10"
                        height="10"
                        className="puntParada"
                        alt="Parada"
                    />
                </div>
                }

                {!posicioInicialOFInal &&
                    <div className="recorregutParadaColor"
                 style={{backgroundColor: color}}>
                    <img
                        src={puntParada}
                        width="10"
                        height="10"
                        className="puntParada"
                        alt="Parada"
                    />
                </div>
                
                }

            {paradaActual&&
                    <div className="recorregutParadaColor"
                 style={{backgroundColor: color}}>
                    <img
                        src="https://mobilitat.bergueda.cat/img/icona_posicio_bus.png"
                        width="30"
                        height="30"
                        className="posicioActual"
                        alt="PosicióActual"
                    />
                </div>
                }

            </div>
            {/* <div className="divTableCell recorregutParadaSeparador"></div> */}
            <div className="divTableCell recorregutParadaNom maBoto" onClick={()=>this.props.obreFinestraParada(item.parada.id)}>
            <b>{item.horaPas}</b> {item.parada.nom} 
                {item.agafaViatgers===1&&item.deixaViatgers===0 && <span> <IconaAgafaViatgers/></span>}
                {item.agafaViatgers===0&&item.deixaViatgers===1 && <span> <IconaDeixaViatgers/></span>}
            </div>    
    </div>

    )
}


render(){
    return (
        <div>
            {/* <div className="divTable">
                <div className="divTableBody"> */}
                    {this.props.horari.map((item,index)=>item.parada.visible && this.generaResultat(item, this.props.color, index, this.props.horari.length))}
                {/* </div>
            </div> */}
        </div>

    )
}
}

export default RecorregutExpedicio  