import React, {Component} from 'react';
import { LinkContainer } from "react-router-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class DetallsLinia extends Component {
render(){
    return (
        <div>
            <LinkContainer to="/linia/" className="maBoto"><p><FontAwesomeIcon icon="arrow-left"  color="#336122" onClick={this.canviEstatFinestra}/> Línies</p></LinkContainer>
             <LinkContainer to={"/linia/"+this.props.dades.codi}><div><div style={{backgroundColor: this.props.dades.color}} className="siglesLinia maBoto">
                        {this.props.dades.sigles}</div>
                    </div></LinkContainer>
            <h2>{this.props.dades.nom}</h2>
        </div>

    )
}
}

export default DetallsLinia