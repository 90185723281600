

import React, {Component} from 'react';
import { compose, withProps, withStateHandlers } from "recompose"
import {Container} from 'react-bootstrap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"
import { Redirect } from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Parada from '../Parada'; 
 

// Centra el mapa a partir dels punts de la línia
 const fitBounds = (map, linia)=> {
  const bounds = new window.google.maps.LatLngBounds();
  linia.map(coords => {
    bounds.extend(coords);
  });
  map.fitBounds(bounds);
};

const MapaGoogle = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCYEQJ7nw4waLh4o_IvlQHDCzGVQQvlOOI&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: Number("42.099492"), lng: Number("1.84396") }}
      ref={(map) => { (map&&props.centrar) && fitBounds(map, props.linia); }}
      onTilesLoaded={() => props.noCentrar()}
    >
        {/* Dibuix de la línia */}
            <Polyline
                      path={props.linia}
                      options={{
                        strokeColor:props.color,
                        strokeOpacity:0.8,
                        strokeWeight:5
                      }}
                      />  

         {/* Dibuix de les parades */}       

       {props.parades.map((parada,index)=>(
      <Marker
         position={{ lat: Number(parada.latitud), lng: Number(parada.longitud)}} 
         title={parada.nom} 
         key={'paradaID'+parada.id} 
         icon={{url:"https://mobilitat.bergueda.cat/img/icona_parada.png",
           anchor: new window.google.maps.Point(10,10),
          scaledSize: new window.google.maps.Size(20,20)}} 
         onClick={() => props.obrirDetallParada(parada.id)}></Marker>))}       

    {props.posicioBus &&
      <Marker
         position={props.posicioBus} 
         title="posicioActual"
         key="posicioActual" 
         icon={{
          url: "https://mobilitat.bergueda.cat/img/icona_posicio_bus.png",
          anchor: new window.google.maps.Point(8,8),
          scaledSize: new window.google.maps.Size(16,16)
        }}
         ></Marker>}       

    </GoogleMap>
  )



class MapaLinia extends Component {


constructor(){
    super()
    this.obrirDetallParada=this.obrirDetallParada.bind(this)
    this.desplegarMapa=this.desplegarMapa.bind(this)
    this.canviEstatFinestra=this.canviEstatFinestra.bind(this)
    this.noCentrar=this.noCentrar.bind(this)
}
state = {
    obrirPaginaParada:false,
    mapaObert:false,
    finestraOberta: false,
    idParada: null,
    centrar:true
    }

    canviEstatFinestra() {
      this.state.finestraOberta ? this.setState({finestraOberta:false}) : this.setState({finestraOberta:true});
      this.setState({centrar:true});
    }


 componentDidMount() {
      window.addEventListener("resize", this.deseplegaMapaEsciptori.bind(this)); //Afegim aquest lisener per capturar els canvis d'estat de la finestra
      this.deseplegaMapaEsciptori();
  }

 noCentrar(){
   this.setState({centrar:false});
 } 
  
  deseplegaMapaEsciptori() {
      if (window.innerHeight >= 10000) { //Si la finestra té una alçada superior a 900px, es mostra el mapa
          this.setState({mapaObert:true});
      } else this.setState({mapaObert:false});
  }

obrirDetallParada(idParada){
    this.setState({finestraOberta:true, idParada});
}

desplegarMapa(){
 this.state.mapaObert ? this.setState({mapaObert:false}) : this.setState({mapaObert:true});
}



render(){
    
const decodePolyline = require('decode-google-map-polyline'); 
const coordsPolyline = decodePolyline(this.props.linia);   


    if (this.state.obrirPaginaParada){
        return <Redirect push to={"/parada/"+this.state.paradaClicada} />;
    }

   var posicioBus = null;
    if (this.props.posicioBus) {
      posicioBus = {lat: Number(this.props.posicioBus.posicioActual.latitud), lng: Number(this.props.posicioBus.posicioActual.longitud)};
    } 


    

    return (
            <div>
              {!this.state.mapaObert &&
                <Container><div className="maBoto" onClick={this.desplegarMapa}><FontAwesomeIcon icon="map-marked-alt" color="#336122"/> Mostra mapa del recorregut</div></Container>
              }


              {this.state.mapaObert &&
              <div>
                <FontAwesomeIcon icon="times-square" size="2x" className="maBoto botoTancarMapa" color="#336122" onClick={this.desplegarMapa}/>
                <MapaGoogle linia={coordsPolyline} color={this.props.color} parades={this.props.parades} obrirDetallParada={this.obrirDetallParada} posicioBus={posicioBus} centrar={this.state.centrar} noCentrar={this.noCentrar}/>
                </div>
              }


              {this.state.finestraOberta && //FINESTRA FLOTANT
                <div>
                    <div className="fonsFinestraFlotant" onClick={this.canviEstatFinestra}></div>
                    <div className="finestraFlotant finestraFlotantAvisosUsuari">
                        <div className="botoTancar"><FontAwesomeIcon icon="times-square" size="2x" className="maBoto" color="#336122" onClick={this.canviEstatFinestra}/></div>
                        <div className="contingutFinestra"><Parada idParada={this.state.idParada}/></div>

                    </div>
                
                </div>
                }
              
              
              
              
              </div>
        )
    }
    }
    
 export default MapaLinia
