import React, {Component} from 'react';
import {Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


class IconaDeixaViatgers extends Component {

    componentDidMount() {
        this.props.modificaEstat && this.props.modificaEstat(); //Si es rep ordre de modificar estat, s'executa.
    }

    render(){
        return(
            <OverlayTrigger placement="top"  overlay={<Tooltip> Només permet baixar en aquesta parada. No recull viatgers</Tooltip>}>
                <FontAwesomeIcon icon="sort-down" />
            </OverlayTrigger>    
            
        )
    }
}

export default IconaDeixaViatgers