import React, {Component} from 'react';
import {Carousel, CardDeck} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import TargetaAvis from './TargetaAvis';

import API from 'Api';

class AvisosGrup extends Component {
    
    constructor(){
        super()
        this.agrupar=this.agrupar.bind(this)
        this.generaItemsCarousel =this.generaItemsCarousel.bind(this)
      }

    state = {
        llistaTipusAvis:[]
    }  

    //CARREGA TIPUS AVIS
carregaTipusAvis() {    
    API.get(`avis/?tipus`)
        .then(res => {
        if (res.status===200){
            this.setState({llistaTipusAvis:res.data});
        } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
        }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X

    }

agrupar(numeroPerGrup,arrayRebuda) { //preparem els grups d'avisos del Carrussel
    var arrayRetorn=[];
    var arrayGrup=[];
    var ultim=false;

    for (var i=0; i<arrayRebuda.length; i++) {
            ultim=false;
            var render=<TargetaAvis dades={arrayRebuda[i]} llistaTipusAvis={this.state.llistaTipusAvis}/> //Prepara ja el component que es retornarà
            arrayGrup.push(render)    
            
            if (arrayGrup.length===numeroPerGrup) {
                arrayRetorn.push(arrayGrup)
                var arrayGrup=[];
                ultim=true;
            }
      }  
      !ultim && arrayRetorn.push(arrayGrup); //Si no és l'últim element pensem a fer push a la taula de retorn
      
      return arrayRetorn
}




generaItemsCarousel(arrayGrup) {

    return (
        <Carousel.Item>
                <CardDeck md>
                {arrayGrup.map((item)=>item)}                
                </CardDeck>
        </Carousel.Item>
    )
}

componentDidMount() {
    this.carregaTipusAvis();
}

 render() {
    if (this.props.dades){
        var avisosAgrupats = this.agrupar(3, this.props.dades);
        console.log(avisosAgrupats)
    }
    

    return(
        <div>
            <h3>Avisos</h3>
            {this.props.dades && 
            <div>
                <Carousel controls="false" indicators="false">

                {avisosAgrupats.map((grupAvisos)=>this.generaItemsCarousel(grupAvisos))}

                </Carousel>
                
            </div>}
            

        {!this.props.dades &&
            <p>En aquests moments no hi ha avisos vigents.</p>
        }

        </div>
    )
}

}

export default AvisosGrup