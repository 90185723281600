import React, {Component} from 'react';
import {Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


class IconaAgafaViatgers extends Component {

    componentDidMount() {
        this.props.modificaEstat && this.props.modificaEstat(); //Si es rep ordre de modificar estat, s'executa.
    }

    render(){
        return(
            <OverlayTrigger placement="top"  overlay={<Tooltip> Només agafa viatgers en aquesta parada. No admet baixar-hi</Tooltip>}>
                <FontAwesomeIcon icon="sort-up" />
            </OverlayTrigger>    

        )
    }
}

export default IconaAgafaViatgers