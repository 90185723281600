import React, {Component} from 'react';

import API from 'Api';
import {Card, Container, Row, Col} from 'react-bootstrap';
import Carregant from '../estructura/Carregant';
import {Link} from 'react-router-dom';

class LlistaParades extends Component {
    constructor(){
      super()
      this.carregaParades=this.carregaParades.bind(this)
      this.ordenaParades=this.ordenaParades.bind(this)
      this.canviEstatFinestra=this.canviEstatFinestra.bind(this)
    }
  
    state = {
      pantalla: 1,
      parades: [],
      missatgeAxios: null
    }
  
    canviEstatFinestra() {
      this.state.finestraOberta ? this.setState({finestraOberta:false}) : this.setState({finestraOberta:true});
  }
    
    //ORDRE CARREGA PARADES
    carregaParades() {    
        API.get(`parada/?ordre=nom`)
          .then(res => {
            if (res.status===200){
                this.setState({parades:res.data.llistat})
                this.ordenaParades();
                this.setState({pantalla:2})

            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }  
    ordenaParades() {
        var paradesBergueda = [];
        var paradesAltres = [];

        for (var i=0; i<this.state.parades.length; i++){ //Classifiquem les parades rebudes

            if (this.state.parades[i].esBergueda) {
                paradesBergueda.push(this.state.parades[i])
            } else paradesAltres.push(this.state.parades[i])

        }
        this.setState({parades: {paradesBergueda,paradesAltres}});  //assignem el nou estat amb les parades
    }

    componentDidMount() {
      this.carregaParades();
    }
  
    render() {
        return (
            <div>
                {this.state.pantalla===1 && //PANTALLA 1: CARREGANT
                    <Card.Body>                            
                        <Carregant/>                        
                    </Card.Body>
                } 
        
                {this.state.pantalla===2 && //PANTALLA 2: VISUALITZACIO DADES
                    <Container>
                        <div className="modulInferior">
                        <Card style={{ width: '100%' }}>
                            <Card.Body>
                                <h3>Llista de parades</h3>
                                <Row>
                                    <Col md>
                                        <h4>Berguedà</h4>
                                        {this.state.parades.paradesBergueda.map((parada)=> <div className="itemParada"><Link to={"/parada/" + parada.id}>{parada.nom}</Link></div>)}
                                    </Col>
                                    <Col md>
                                        <h4>Altres</h4>
                                        {this.state.parades.paradesAltres.map((parada)=> <div className="itemParada"><Link to={"/parada/" + parada.id}>{parada.nom}</Link></div>)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                       </div>     
                    </Container>
                }  
            </div>
        )
        

        
      }
  }
  export default LlistaParades;