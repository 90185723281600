import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import API from "Api";
import FotoGran from "./portada/FotoGran";
import ModulAvisos from "./portada/ModulAvisos";
import ModulLinies from "./portada/ModulLinies";
import ModulParades from "./portada/ModulParades";
import Cercador from "./Cercador";
import AvisosGrup from "./avisos/AvisosGrup";
import Carregant from "./estructura/Carregant";
import ModulIncidencies from "./portada/ModulIncidencies";
import ModulLleure from "./portada/ModulLleure";

class Portada extends Component {
  //CARREGA AVISOS VIGENTS
  constructor() {
    super();
    this.obteDades = this.obteDades.bind(this);
  }

  obteDades() {
    this.setState({ generaInterficie: false }); //Abans de carregar dades, posem la careta de Carregant.
    const baseURL = `avis/llistaAvisos.php?objecte=senseExpedicions`;

    API.get(baseURL).then((res) => {
      if (res.data.status === "OK") {
        const avisos = res.data.avisos;
        this.setState({ avisos });
      }

      const status = res.data.status;
      this.setState({ status, generaInterficie: true });
    });
  }

  state = {
    avisos: null,
    status: "ERROR",
    generaInterficie: false,
  };

  componentDidMount() {
    this.obteDades();
    document.title =
      "Mobilitat Berguedà - Horaris Bus Berga Barcelona - Berga Manresa - Berga Vic - Berga Ripoll";
  }

  render() {
    return (
      <div>
        {!this.state.generaInterficie && ( //Si no ha carregat les dades
          <div>
            <Carregant />
          </div>
        )}
        {this.state.generaInterficie && ( //Genera la interficie quan ha rebut les dades
          <div>
            <FotoGran
              canviOrigen={this.props.canviOrigen}
              canviDestinacio={this.props.canviDestinacio}
              canviHora={this.props.canviHora}
              canviData={this.props.canviData}
              itinerari={this.props.itinerari}
              assignarItinerari={this.props.assignarItinerari}
            />
            <br />
            <Container>
              <Row>
                <Col md>
                  <ModulLinies />
                </Col>
                <br />
                <Col md>
                  <ModulParades />
                </Col>
              </Row>
              <Row>
                {/* <Col md><ModulLleure/></Col>
                  <br />   */}
                <Col md>
                  <ModulIncidencies />
                </Col>
              </Row>
              <br />
              {/* <AvisosGrup dades={this.state.avisos}/>      */}
            </Container>

            <br />
          </div>
        )}
      </div>
    );
  }
}

export default Portada;
