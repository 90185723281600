import React, {Component} from 'react';
import {Button, Form, Alert, Row, Col, DropdownButton, Dropdown, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import API from 'Api';
import { SliderPicker } from 'react-color';
import Carregant from '../../estructura/Carregant';
import MapaSeleccioPunt from '../../mapes/MapaSeleccioPunt';
import DesplegableLinies from '../elements/DesplegableLinies';
import SeleccioHoraPas from '../elements/SeleccioHoraPas';
import {DateTime} from 'react-datetime-bootstrap';

class AfegirExpedicio extends Component {

    constructor(){
        super()

        this.trobaLiniaSeleccionada=this.trobaLiniaSeleccionada.bind(this)
        this.assignaLinia=this.assignaLinia.bind(this)
        this.generaParades=this.generaParades.bind(this)
        this.assignaValor=this.assignaValor.bind(this)
        this.handleClick=this.handleClick.bind(this)
        this.girarOrdre=this.girarOrdre.bind(this)
        this.assignaHoraPas=this.assignaHoraPas.bind(this)
        this.assignaData=this.assignaData.bind(this)
        this.assignaValorCheck=this.assignaValorCheck.bind(this)
        this.esborraHoraPas=this.esborraHoraPas.bind(this)
      }

    state = {
        codiLinia: null,
        dilluns: true,
        dimarts: true,
        dimecres: true,
        dijous: true,
        divendres: true,
        dissabte: true,
        festiu: true,
        paradaInicial:null,
        paradaFinal:null,
        dataInici:null,
        dataFinal:null,
        invers: false,

        horari:[],
        pantalla: 1,
        missatgeAxios: null
    }

    //ASSIGNAR VARIABLES PER ENVIAR
      assignaValor(e) {
        this.setState({ [e.target.name]: e.target.value });
      };

      assignaValorCheck(e) {
        this.setState({ [e.target.name]: e.target.checked });
      };

      assignaData(data, tipus) {
        
        //Preparem el format de la data
        var novaData = new Date(data);
        var dd = String(novaData.getDate()).padStart(2, '0');
        var mm = String(novaData.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = novaData.getFullYear();

        var dataResposta = dd + '-' + mm + '-' + yyyy;
        
        //Assignem la data a l'estat corresponent
        tipus==="dataInici" && this.setState({dataInici:dataResposta})
        tipus==="dataFinal" && this.setState({dataFinal:dataResposta})

      };

      assignaColor(color) {
            this.setState({ color: color.hex });
      }


      //CLIC PER ENVIAR
      handleClick(event) {
        event.preventDefault();
        this.setState({pantalla:2})
        this.afegeix();
      }

        //CLIC PER AFEGIR UNA NOVA PARADA
        afegirParada(event) {

            var nouNumParada=this.state.numParades+1
            this.setState({numParades:nouNumParada})
        }

        //CLIC PER AFEGIR UNA NOVA PARADA
        treureParada(event) {
            event.preventDefault();
            var nouNumParada=this.state.numParades-1
            this.setState({numParades:nouNumParada})
        }

        //ASSIGNAR PARADA A L'ITINERARI
        assignaLinia(event) {    
            this.setState({codiLinia:event, horari:[]});
          };

       //ORDRE CARREGA PARADES
       carregaParades() {    
        API.get(`parada/?ordre=nom`)
          .then(res => {
            if (res.status===200){
                this.setState({parades:res.data.llistat})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }

      girarOrdre(event) {
        event.preventDefault();
        this.state.invers ? this.setState({invers:false}) : this.setState({invers:true});
        this.setState({horari:[]})
    }

      //ORDRE AFEGIR
      afegeix() {

        //Preparem les dades per enviar
        const dades = {
            codiLinia: this.state.codiLinia,
            dilluns:  this.state.dilluns,
            dimarts:  this.state.dimarts,
            dimecres:  this.state.dimecres,
            dijous:  this.state.dijous,
            divendres:  this.state.divendres,
            dissabte:  this.state.dissabte,
            festiu:  this.state.festiu,
            dataInici: this.state.dataInici,
            dataFinal: this.state.dataFinal,
            horari: this.state.horari,
        };
    
        API.post(`expedicio/`, { dades, token:this.props.token })
          .then(res => {
            if (res.status===201){
                this.setState({pantalla:3, missatgeAxios:res.data.missatge})
                this.props.refrescaExpedicions();
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }

    trobaLiniaSeleccionada(){
        if (this.state.codiLinia) {
            var nomLiniaSeleccionada = null;
            //Recorrem l'array de linies buscant el nom de l'ID corresponent
            for (var k=0; k<this.props.linies.length&&!nomLiniaSeleccionada; k++) {
                if (this.props.linies[k].codi ===this.state.codiLinia ) {
                    nomLiniaSeleccionada  = <span>{this.props.linies[k].sigles}: {this.props.linies[k].nom}</span>    
                }
            }
            return nomLiniaSeleccionada

        } else return "Escull línia..."

    }

    assignaHoraPas(value, idParada) {
        //Preparem l'hora rebuda en el format correcte per enviar
        var novaHora = new Date(value);
        var hh = String(novaHora.getHours()).padStart(2, '0');
        var mm = String(novaHora.getMinutes()).padStart(2, '0');
        var hora = hh + ':' + mm; //Valor de l'hora per registrar
        
        //Busquem a l'horari si existeix entrada de la parada

        var horari = this.state.horari
        var existeix = false

        //Recorrem l'array buscant si el valor ja existeix
        for (var k=0; k<horari.length&&!existeix; k++) {
            if (horari[k].idParada === idParada ) {
                console.log(k);
                existeix=true;
                horari[k].horaPas=hora; //Assignem la nova hora

            } 
        }

        //Si no existeix o és el primer valor l'introduim.
        if (horari.length === 0 || !existeix) {
            console.log("Primer o no existeix");
            horari.push({idParada, horaPas:hora}) //Introduim el primer valor a l'array
        }

        this.setState({horari}); // Tornem a assignar l'array de nou a l'estat 
    }

    esborraHoraPas(idParada) {
        //Busquem a l'horari si existeix entrada de la parada
        var horari = this.state.horari
        var existeix = false
        console.log("esborra Hora Pas")
        //Recorrem l'array buscant si el valor ja existeix
        for (var k=0; k<horari.length&&!existeix; k++) {
            if (horari[k].idParada === idParada ) {
                horari.splice(k, 1);
                existeix=true;

            } 
        }
        this.setState({horari}); // Tornem a assignar l'array de nou a l'estat 
    }

    
    generaParades(){
         //Primer busquem l'index de l'array que conté la línia seleccionada
         var indexLiniaSeleccionada = -1;
            //Recorrem l'array de linies buscant el nom de l'ID corresponent
            for (var k=0; k<this.props.linies.length&&indexLiniaSeleccionada<0; k++) {
                if (this.props.linies[k].codi ===this.state.codiLinia ) {
                    indexLiniaSeleccionada = k 
                }
            }
        if (indexLiniaSeleccionada>=0) { //si tenim index, coninuem
                 //Generem el loop per la llista de parades de la línia per ordre
                    var llistaParades = [];
                    var idParada = 0; 
                    var nomParada= null;           

                    for (var i=0; i<this.props.linies[indexLiniaSeleccionada].parades.length; i++) { //Recorrem l'array de parades de la línia per generar els camps de formulari de les paradaes
                        
                        idParada=this.props.linies[indexLiniaSeleccionada].parades[i].id;
                        nomParada=this.props.linies[indexLiniaSeleccionada].parades[i].nom;
                        llistaParades.push(<div className="horaPasParada">
                            
                            <div>{nomParada}</div>
                            <SeleccioHoraPas onSelect={this.assignaHoraPas} idParada={idParada} esborraHoraPas={this.esborraHoraPas}/>
                            
                            </div>                           
                            
                            )
                    }   

                    if (this.state.invers) { //Invertim la llista de parades si volem l'ordre invers
                        llistaParades.reverse();
                    }
                return llistaParades;
        } 
        
        return "No hi ha línia seleccionada";
        
       
    }  

    componentDidMount() {
        this.carregaParades();
    }

    render() {

        var nomLiniaSeleccionada = this.trobaLiniaSeleccionada();

        return (
            
            <div>
                <Card style={{ width: '100%' }}>
                    <Card.Header>Nova expedició</Card.Header>

                     {this.state.pantalla===1 && //PANTALLA 1: FORMULARI
                        <Card.Body>
                            <Row>
                                <Col md>
                            <Form.Group controlId="formNomLinia">
                                <DesplegableLinies elements={this.props.linies} onSelect={this.assignaLinia} index={0} seleccionat={nomLiniaSeleccionada}/>
                            </Form.Group>
                            {this.state.codiLinia &&
                                <div>

                                <Form.Group controlId="BotoInvertir">  
                                <Button variant="secondary" onClick={this.girarOrdre}>Invertir</Button>
                                </Form.Group>

                                {this.generaParades()}

                                
                                

                                <Form.Group controlId="formBotoEnviar">  
                                <Button variant="success" onClick={this.handleClick}>Afegir</Button>
                                </Form.Group>
                                
                                </div>
                                }

                            </Col>
                            <Col md>
                                    <div>
                                        <div>Dies de la setmana que opera</div>
                                        <Form.Check type="checkbox" label={"Dilluns"} name={"dilluns"} onClick={this.assignaValorCheck} checked={this.state.dilluns}/>                                 
                                        <Form.Check type="checkbox" label={"Dimarts"} name={"dimarts"} onClick={this.assignaValorCheck} checked={this.state.dimarts}/> 
                                        <Form.Check type="checkbox" label={"Dimecres"} name={"dimecres"} onClick={this.assignaValorCheck} checked={this.state.dimecres}/> 
                                        <Form.Check type="checkbox" label={"Dijous"} name={"dijous"} onClick={this.assignaValorCheck} checked={this.state.dijous}/> 
                                        <Form.Check type="checkbox" label={"Divendres"} name={"divendres"} onClick={this.assignaValorCheck} checked={this.state.divendres}/> 
                                        <Form.Check type="checkbox" label={"Dissabte"} name={"dissabte"} onClick={this.assignaValorCheck} checked={this.state.dissabte}/>           
                                        <Form.Check type="checkbox" label={"Diumenge i festius"} name={"festiu"} onClick={this.assignaValorCheck} checked={this.state.festiu}/> 
                                    </div>

                                    <div className="periodeExpedicio">
                                        <span>Data inici: </span>
                                        <DateTime pickerOptions={{format:"DD-MM-YYYY", locale:"ca"}} placeholder={"Data inici"} onChange={(data)=>this.assignaData(data, "dataInici")}/>
                                        <span>Data final: </span>
                                        <DateTime pickerOptions={{format:"DD-MM-YYYY", locale:"ca"}} placeholder={"Data final"} onChange={(data)=>this.assignaData(data, "dataFinal")}/>
                                    </div>
                                
                                
                                </Col>
                            
                            </Row>
                        
                        </Card.Body>
                    }

                    {this.state.pantalla===2 && //PANTALLA 2: CARREGANT
                        <Card.Body>                            
                            <Carregant/>                        
                        </Card.Body>
                    }   


                    {this.state.pantalla===3 && //PANTALLA 3: mostra resultat
                        <Alert variant="success">
                            Expedició afegida correctament!
                        </Alert>

                        }

                    {this.state.pantalla===4 && //PANTALLA 4: mostra error
                        <Alert variant="danger">
                            Hi ha hagut un error: {this.state.missatgeAxios}
                            </Alert>

                        }


                </Card>
            </div>
        )
    }
}

    export default AfegirExpedicio;