import React, {Component} from 'react';
import {Button, Alert} from 'react-bootstrap';

import API from 'Api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Carregant from '../../estructura/Carregant';

class EsborrarExpedicio extends Component {
    
    constructor(){
        super()
        this.handleClick=this.handleClick.bind(this);
      }

    //CLIC PER ENVIAR
    handleClick(event) {
        event.preventDefault();
        this.setState({pantalla:2})
        this.esborra();
    }  

    //ORDRE ESBORRAR
    esborra() {
        const idExpedicio= this.props.expedicio.id;

        API.delete(`expedicio/`, { data: {idExpedicio, token:this.props.token }}) //Les ordres delete cal especificar data:
        .then(res => {
            if (res.status===200){
                this.setState({pantalla:3, missatgeAxios:res.data.missatge})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
        }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X

    }

    state = {
        pantalla: 1
    }

    
    render() {
        return ( //FINESTRA FLOTANT
            <div> 
            <div className="fonsFinestraFlotant" onClick={this.props.tancar}></div>
            <div className="finestraFlotant finestraFlotantAvisosUsuari">
                <div className="botoTancar"><FontAwesomeIcon icon="times-square" size="2x" className="maBoto" color="#336122" onClick={this.props.tancar}/></div>
                <div className="contingutFinestra">
                    
                {this.state.pantalla===1 && //PANTALLA 1: Missatge de confirmació
                <div>
                    <p>Estàs segur que vols eliminar l'expedició seleccionada?</p>
                    <div className="dadesElement"><span style={{backgroundColor: this.props.expedicio.linia.color}} className="siglesLinia maBoto">
                        {this.props.expedicio.linia.sigles}</span> <strong>{this.props.expedicio.paradaInici.hora}</strong>: {this.props.expedicio.paradaInici.nom} <FontAwesomeIcon icon="arrow-right" color="#000000"/> <strong>{this.props.expedicio.paradaFinal.hora}</strong>: {this.props.expedicio.paradaFinal.nom}</div>
                    <Button variant="danger" onClick={this.handleClick}>Sí, elimina</Button> <Button variant="secondary" onClick={this.props.tancar}>No, cancel·la</Button>
                </div>               
                
                }

                {this.state.pantalla===2 && //PANTALLA 2: CARREGANT                           
                            <Carregant/>                        
                }

                {this.state.pantalla===3 && //PANTALLA 3: mostra resultat
                        <Alert variant="success">
                            Expedició esborrada correctament!
                        </Alert>
                }

                {this.state.pantalla===4 && //PANTALLA 4: mostra error
                    <Alert variant="danger">
                        Hi ha hagut un error: {this.state.missatgeAxios}
                        </Alert>
                    }
                
                
                </div>

            </div>
        
        </div>
        )
    }
}

export default EsborrarExpedicio;