import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class AvisPeu extends Component {

render() {

    return (
      <div className="avisPeu">
          <Container>
          <p>Si detectes algun error en el funcionament de la pàgina, o ens vols fer arribar dubtes o suggeriments, fes-ho al twitter <a href="https://www.twitter.com/MobiBergueda">@MobiBergueda</a>.</p>
          </Container>
      </div>
    )
  }

}

export default AvisPeu