import React, {Component} from 'react';

import API from 'Api';
import Carregant from '../estructura/Carregant';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Container, Card} from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";


class LlistaExpedicions extends Component {
    state = {
        expedicions: [],
        generaInterficie: false
      }
    
      obteDades() {     
        const baseURL = `expedicio/?circulaAra`;
        API.get(baseURL)
        .then(res => {
                const expedicions = res.data.llistat;
                this.setState({expedicions});
          this.setState({generaInterficie: true });
        })
    }
    
    componentDidMount() {
        this.obteDades();
        this.interval = setInterval(function() {
            this.obteDades();
            }.bind(this), 30000)
        }
      
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        document.title = "Servei actual - Mobilitat Berguedà";
        return (
            <div>
                {!this.state.generaInterficie && //Si no ha carregat les dades
                    <div>
                       <Carregant/>
                    </div>
                }
                {this.state.generaInterficie && //Genera la interficie quan ha rebut les dades
                  <div>
                    <Container>
                    <Card style={{ width: '100%' }}>
                        <Card.Body>
                    <h3>Circulacions actuals</h3>
                    {this.state.expedicions.length>0 && this.state.expedicions.map ((expedicio, index) => 
                                        <LinkContainer to={"/expedicio/"+expedicio.id} key={"idExpedicio_"+expedicio.id}><div className="maBoto paradaLlista">
                                            {/* <div className="botoVisible"  onClick={()=>this.props.amagarLinia(index)}>{linia.visible ? <FontAwesomeIcon icon="check-circle" /> : <FontAwesomeIcon icon="circle" />}</div> */}
                                            {' '}<div className="iconaLinia" ><span className="siglesLinia" style={{backgroundColor: expedicio.linia.color}}>{expedicio.linia.sigles}</span></div>
                                            {' '}<div className="nomLinia">{expedicio.paradaInici.nom + " (" + expedicio.paradaInici.hora+ ")"} <FontAwesomeIcon icon="arrow-right"  color="#336122"/> {expedicio.paradaFinal.nom + " (" + expedicio.paradaFinal.hora + ")"}</div>
                                            {' '}<div className="properaParada" style={{display:"block", fontSize:"13px"}}>Propera parada: {expedicio.circulaAra.paradesRestants[1].nom} ({expedicio.circulaAra.paradesRestants[1].horaPas})  {expedicio.numAvisosUsuari>0 && <span><FontAwesomeIcon icon="exclamation-triangle" color="#B364A9"/> {expedicio.numAvisosUsuari} {expedicio.numAvisosUsuari===1 ? "avís notificat" : "avisos notificats"}</span>}     </div>
                                        </div></LinkContainer>
                                    )}
                    

                    {this.state.expedicions.length===0 && 
                        <p>No hi ha serveis en circulació en aquests moments.</p>
                    }
                    <div style={{display:"block", fontSize:"13px", fontStyle:"italic", marginTop:"10px"}}>(Situació aproximada segons l'horari teòric)</div>        
                    </Card.Body></Card></Container>
                </div>
                }
            </div>
        )
    }
}

export default LlistaExpedicions