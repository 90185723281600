import React, {Component} from 'react';
import {Dropdown, DropdownButton} from 'react-bootstrap';

class Desplegable extends Component {
    
    
    
    render() {
        return (
            <DropdownButton
                variant="outline-secondary"
                title={this.props.seleccionat}
                id="input-group-dropdown-1"
                onSelect={(event) => this.props.onSelect(event, this.props.index)}
                >
                    {this.props.elements.map((element,index) =>
                         <span>
                         {element.id&&element.nom && 
                            <Dropdown.Item eventKey={element.id} key={element.id}>{element.nom}</Dropdown.Item>
                         }
                         {element.codi&&element.nom && 
                            <Dropdown.Item eventKey={element.codi} key={element.codi}>{element.sigles}: {element.nom}</Dropdown.Item>
                        }
                        {element.paradaInici&&element.paradaFinal && 
                            <Dropdown.Item eventKey={element.id} key={element.id}>{element.paradaInici.hora}: {element.paradaInici.nom} > {element.paradaFinal.hora}: {element.paradaFinal.nom}</Dropdown.Item>
                        }
                        </span>
                    )}
            </DropdownButton>
        )
    }
}

export default Desplegable;


    