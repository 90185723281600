import React, {Component} from 'react';

import API from 'Api';
import MapContainerCoo from './MapContainerCoo'
import {Link} from 'react-router-dom';
import Carregant from './estructura/Carregant';
import {Container, Row, Col} from 'react-bootstrap';
import DetallsParada from './parades/DetallsParada';
import MapaParada from './parades/MapaParada';
import LiniesParada from './parades/LiniesParada'
import ProximesSortides from './parades/ProximesSortides';
import AvisosGrup from './avisos/AvisosGrup';
import LlistaParades from './parades/LlistaParades';
import TaulaHorarisParada from './parades/TaulaHorarisParada';
import ObresRonda from './estructura/ObresRonda';


class Parada extends Component {
  constructor(){
    super()
    this.obteDades=this.obteDades.bind(this)
    this.canviEstatFinestra=this.canviEstatFinestra.bind(this)
  }

  state = {
    dades: null,
    linies: null,
    properesSortides: null,
    avisos:null,
    destinacions:null,
    pantalla: 1,

    finestraOberta: false,
    idParada: null
  }

  canviEstatFinestra() {
    this.state.finestraOberta ? this.setState({finestraOberta:false}) : this.setState({finestraOberta:true});
}
  
  obteDades() {
    var id=0;

    //Depen de quin props rebem, carreguem des d'una variable o altra.
    if (this.props.match&&this.props.match.params.id) {
      id = Number(this.props.match.params.id);
    } else if (this.props.idParada) {
      id = Number(this.props.idParada);
    } else this.setState({ pantalla: 2 });


    if (id) { //Si s'ha obtingut id, es busquen dades
      const baseURL = `parada/?id=` + id;

      API.get(baseURL)
      .then(res => {
        const dades = res.data.info_parada;
        const linies = res.data.linies;
        const avisos = res.data.avisos;
        const properesSortides = res.data.properesSortides;
        const destinacions = res.data.destinacions;
        this.setState({ dades, linies, properesSortides, avisos, destinacions, pantalla: 3 });
        document.title = dades.nom + " - Horaris bus a " + dades.nom + " - Mobilitat Berguedà";
      })
      
    }

  }



    componentDidUpdate(prevProps) { //Si es canvia la url que rebem a traves de match, es torna a cridar al backend.
        if (this.props.match&&prevProps.match.url !== this.props.match.url) {
          this.setState({ pantalla: 1 });
           this.obteDades();
        }
      }


  componentDidMount() {
    this.obteDades();
  }

  render() {
         return (
          <div>
          
              {this.state.pantalla===1 && //PANTALLA 1: Si s'han rebut dades, es carreguen
                    <div>
                       <Carregant/>
                    </div>
                } 
                {this.state.pantalla===2 && //PANTALLA 2: Si NO s'han rebut dades, es carrega component amb la llista sencera
                    <div>
                       <LlistaParades/>
                    </div>
                }
              {this.state.pantalla===3 && //PANTALLA 3: Un cop generades les dades, es mostren en pantalla
                  
                  <div className="modulInferior">
                    <Container>
                    <Row>
                      <Col md><DetallsParada key={"dadesParadaID"+this.state.dades.id} dades={this.state.dades}/></Col>
                      <br />  
                      <Col md><MapaParada key={"mapaParadaID"+this.state.dades.id} dades={this.state.dades}/></Col>
                    </Row>

                      {/* Especial Obres Ronda Universitat    */}
                      {this.state.dades.id==="13" &&  <div><br></br><ObresRonda/></div>}

                    <div><TaulaHorarisParada destinacions={this.state.destinacions} dades={this.state.dades}/></div>
                    <div className="duesColumnes"><Row>
                      <Col md><LiniesParada linies={this.state.linies}/></Col>
                      <br />  
                      <Col md><ProximesSortides proximesSortides={this.state.properesSortides} refrescaDades={this.obteDades}/></Col>
                    </Row>
                    </div>
                    <div><AvisosGrup dades={this.state.avisos}/></div>
                    </Container>


                  </div>
                }
              </div>
        )
    }
}
export default Parada;