import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import API from 'Api';
import {Link} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AfegirAvis from './avisos/AfegirAvis';
import EsborrarAvis from './avisos/EsborrarAvis';
import EditarAvis from './avisos/EditarAvis';

class AdminAvisos extends Component {
    
    constructor(){
        super()
        this.canviAfegirAvis=this.canviAfegirAvis.bind(this)
        this.carregaAvisos=this.carregaAvisos.bind(this)
        this.obreEsborrarAvis=this.obreEsborrarAvis.bind(this)
        this.tancaFinestra=this.tancaFinestra.bind(this)
      }

      carregaAvisos() {    
        API.get(`avis/`)
          .then(res => {
            if (res.status===200){
                this.setState({avisos:res.data.avisos})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      } 


    state = {
        afegirAvis:false,
        avisos: [],
        finestraEsborrar: false,
        finestraEditar: false,
        avisEditar: null
    }
    
    canviAfegirAvis() {
        this.state.afegirAvis ? this.setState({afegirAvis:false}) : this.setState({afegirAvis:true});
    }

    obreEsborrarAvis(avis) {
        this.setState({finestraEsborrar:true, avisEditar:avis});
     }
 
     obreEditarAvis(avis) {
         this.setState({finestraEditar:true, avisEditar:avis});
      }
 
 
     tancaFinestra() {
         this.setState({finestraEsborrar:false, finestraEditar:false});
         this.carregaAvisos();
      }

    colorGris(avis){ //COLOR PER LES PARADES FORA DE PERIODES DE CIRCULACIO
        if (!avis.circula) {
            return "#d0d0d0";
        } 
    }

    generaTaula(avis, index) {
        return (
            <div className="divTableRow liniaResposta noSelect" key={"avis"+index}>         
                <div className="divTableCell">
                {avis.titol}
                 </div>
                 <div className="divTableCell">
                {avis.objecte.nom}
                
                {!avis.objecte.nom && <span><strong>{avis.objecte.paradaInici.hora}</strong>: {avis.objecte.paradaInici.nom} <FontAwesomeIcon icon="arrow-right" color="#000000"/> <strong>{avis.objecte.paradaFinal.hora}</strong>: {avis.objecte.paradaFinal.nom}
                </span>}
                 </div>
                 <div className="divTableCell">
                {avis.dataInici}
                 </div>
                 <div className="divTableCell">
                {avis.dataFinal}
                 </div>
                 <div className="divTableCell">
                    <FontAwesomeIcon icon="pencil-alt" className="maBoto" color="#336122" onClick={()=>this.obreEditarAvis(avis)}/>
                 </div>
                 <div className="divTableCell">
                    <FontAwesomeIcon icon="times" className="maBoto" color="#336122" onClick={()=>this.obreEsborrarAvis(avis)}/>
                 </div>     
        </div>

        )
    }
    
    componentDidMount() {
        this.carregaAvisos();
        if (this.props.match&&this.props.match.params.opcions==="afegir") this.canviAfegirAvis(); //Si arribem amb l'opció d'afegir, s'obre el desplegable
      }

    componentDidUpdate(prevProps) { //Si es canvia la url que rebem a traves de match, es torna a cridar al backend.
    if (this.props.match&&prevProps.match.url !== this.props.match.url) {
        this.props.match&&this.props.match.params.opcions==="afegir" ? this.setState({afegirAvis:true}) : this.setState({afegirAvis:false});
    }
    }

    render() {
        return (
            <div className="modulInferior">
                <h2>Avisos</h2>
                <Button variant="secondary" onClick={this.canviAfegirAvis}>{this.state.afegirAvis ? "Tancar" : "Afegir"}</Button>
                {this.state.afegirAvis && <AfegirAvis token={this.props.token} refrescaAvisos={this.carregaAvisos}/>}
                
                <div className="divTable taulaElements">
                             <div className="divTableBody">
                                {this.state.avisos.map((avis,index)=> this.generaTaula(avis, index))}
                            </div>
                        </div>

                {this.state.finestraEsborrar && <EsborrarAvis tancar={this.tancaFinestra} avis={this.state.avisEditar} token={this.props.token}/>}
                {this.state.finestraEditar && <EditarAvis tancar={this.tancaFinestra} avis={this.state.avisEditar} token={this.props.token}/>}

            </div>
        )
    }
}

export default AdminAvisos;