import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormulariAvisUsuari from './FormulariAvisUsuari';

import API from 'Api';

class AvisosUsuari extends Component {

    constructor(){
        super()
        this.canviEstatFinestra=this.canviEstatFinestra.bind(this)
        this.escriuMissatgeTipusAvis=this.escriuMissatgeTipusAvis.bind(this)
        this.obteDades=this.obteDades.bind(this)
      }

    state = {
        finestraOberta: false,
        avisos:null
    }

    canviEstatFinestra() {
        this.state.finestraOberta ? this.setState({finestraOberta:false}) : this.setState({finestraOberta:true});
    }

obteDades() {
    this.setState({generaInterficie: false }); //Abans de carregar dades, posem la careta de Carregant.

    const baseURL = `avisUsuari/?idExpedicio=` + this.props.idExpedicio;

    API.get(baseURL)
    .then(res => {
        if (res.data.status==="OK"){
            const avisos = res.data.llista_avisos
            this.setState({avisos});
        }        
    })
    }    



escriuMissatgeTipusAvis(idTipusAvis, retard, parada) {
    switch (idTipusAvis){
        case "1": return (
            <span><b>Retard de {retard} minuts</b> al pas per {parada.nom}</span>
        );
        case "2": return (
            <span><b>Bus absent</b> a {parada.nom}</span>
        );
        case "4": return (
            <span><b>Afluència de viatgers</b> a {parada.nom}</span>
        );
    }
}

componentDidMount() {
    this.obteDades();
  }


render() {

    return (
        <div>
        <div className="modulAvisosUsuari">
            <div className="llistaAvisosUsuari">
                {this.state.avisos &&
                <div>
                    <h5><FontAwesomeIcon icon="exclamation-triangle" color="#B364A9"/> Incidències assenyalades avui</h5>
                    {this.state.avisos.map((avis,index)=>
                    
                    <div>{avis.hora}: {this.escriuMissatgeTipusAvis(avis.tipusAvis, avis.retard, avis.parada)}</div>
                    
                    )}
                </div>}

            </div>
            <Button variant="secondary" onClick={this.canviEstatFinestra}>Informa d'una incidència</Button>
        </div>

        {this.state.finestraOberta &&
        <div>
            <div className="fonsFinestraFlotant" onClick={this.canviEstatFinestra}></div>
            <div className="finestraFlotant finestraFlotantAvisosUsuari">
                <div className="botoTancar"><FontAwesomeIcon icon="times-square" size="2x" className="maBoto" color="#336122" onClick={this.canviEstatFinestra}/></div>
                <div className="contingutFinestra"><FormulariAvisUsuari parades={this.props.parades} idExpedicio={this.props.idExpedicio} actualitzaLlista={this.obteDades}/></div>

            </div>
        
        </div>
        }
        </div>
    )
}



}

export default AvisosUsuari;