import React, {Component} from 'react';
import {Button, Form, Alert, Row, Col, DropdownButton, Dropdown, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import API from 'Api';
import Carregant from '../../estructura/Carregant';
import MapaSeleccioPunt from '../../mapes/MapaSeleccioPunt';

class AfegirParada extends Component {

    constructor(){
        super()
        this.assignaLatitud=this.assignaLatitud.bind(this)
        this.assignaLongitud=this. assignaLongitud.bind(this)
        this.assignaNom=this.assignaNom.bind(this)
        this.assignaCodiPostal=this. assignaCodiPostal.bind(this)
        this.assignaObservacions=this.assignaObservacions.bind(this)
        this.handleClick=this.handleClick.bind(this)
      }

    state = {
        nom: null,
        codiPostal: null,
        latitud: null,
        longitud: null,
        observacions: null,
        pantalla: 1,
        missatgeAxios: null
    }

    //ASSIGNAR VARIABLES PER ENVIAR
    assignaLatitud(latitud) {
        this.setState({ latitud });
      };

    assignaLongitud(longitud) {
        this.setState({ longitud });
      };

      assignaNom(e) {
        this.setState({ nom: e.target.value });
      };

      assignaCodiPostal(e) {
        this.setState({ codiPostal: e.target.value });
      };

      assignaObservacions(e) {
        this.setState({ observacions: e.target.value });
      };


      //CLIC PER ENVIAR
      handleClick(event) {
        event.preventDefault();
        this.setState({pantalla:2})
        this.afegeix();
      }

      //ORDRE AFEGIR

      afegeix() {

        const dadesParada = {
            nom: this.state.nom,
            //codiPostal:this.state.codiPostal,
            latitud:this.state.latitud,
            longitud: this.state.longitud,
            observacions: this.state.observacions
        };
    
        API.post(`parada/`, { dadesParada, token:this.props.token })
          .then(res => {
            if (res.status===201){
                this.setState({pantalla:3, missatgeAxios:res.data.missatge});
                this.props.refrescaParades();
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }

    render() {

        return (
            
            <div>
                <Card style={{ width: '100%' }}>
                    <Card.Header>Nova parada</Card.Header>

                     {this.state.pantalla===1 && //PANTALLA 1: FORMULARI
                        <Card.Body>
                            <Row>
                                <Col md>
                            <Form.Group controlId="formNomParada">
                                <Form.Control type="text" placeholder="Nom Parada" onChange={this.assignaNom}/>
                            </Form.Group>
                            {/* <Form.Group controlId="formCodiPostal">
                                <Form.Control type="codiPostal" placeholder="Codi Postal" onChange={this.assignaCodiPostal}/>
                            </Form.Group> */}
                            <Form.Group controlId="formObservacions">    
                                <Form.Control as="textarea" rows="2" placeholder="Observacions" onChange={this.assignaObservacions}/>
                                </Form.Group>
                            <Form.Group controlId="formBotoEnviar">  
                                <Button variant="success" onClick={this.handleClick}>Afegir</Button>
                                </Form.Group>
                            </Col>
                            <Col md>
                            <MapaSeleccioPunt assignaLatitud={this.assignaLatitud} assignaLongitud={this.assignaLongitud} latitud={this.state.latitud} longitud={this.state.longitud}/>
                                {this.state.latitud &&
                                    <div>El punt triat és {this.state.latitud}, {this.state.longitud}</div>
                                }                            
                                </Col>
                            
                            </Row>
                        
                        </Card.Body>
                    }

                    {this.state.pantalla===2 && //PANTALLA 2: CARREGANT
                        <Card.Body>                            
                            <Carregant/>                        
                        </Card.Body>
                    }   


                    {this.state.pantalla===3 && //PANTALLA 3: mostra resultat
                        <Alert variant="success">
                            Parada afegida correctament!
                        </Alert>

                        }

                    {this.state.pantalla===4 && //PANTALLA 4: mostra error
                        <Alert variant="danger">
                            Hi ha hagut un error: {this.state.missatgeAxios}
                            </Alert>

                        }


                </Card>
            </div>
        )
    }
}

    export default AfegirParada;