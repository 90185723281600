import React, {Component} from 'react';

import { compose, withProps, withStateHandlers, withHandlers } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"

const CoordsPolyLineEntreParades = [
    { lat: 42.098871, lng: 1.860893 },
    { lat: 42.097871, lng: 1.861893 }
  ];

  const decodePolyline = require('decode-google-map-polyline');


const MapaGoogle = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCYEQJ7nw4waLh4o_IvlQHDCzGVQQvlOOI&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `600px` }} />,
      mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
    withHandlers((props) => {
      const refs = {
        map: undefined,
      }
  
      return {
        onMapMounted: () => ref => {
          refs.map = ref
        },
        onZoomChanged: () => () => {
          props.canviaIconesZoom(refs.map.getZoom())
        }
      }
    }),
  )((props) =>
    <GoogleMap
      zoom={props.opcionsMapa.zoom}
      options= {{gestureHandling: 'greedy'}}
      center={props.opcionsMapa.centre}
      ref={props.onMapMounted}
      onClick={(e) => props.getCoordinates(e)}
      onDrag={props.onZoomChanged}
      onZoomChanged={props.onZoomChanged}
    >

      {props.parades.map((parada,index)=>(
      parada.esBergueda &&(<Marker
         visible={parada.visible}
         position={{ lat: Number(parada.latitud), lng: Number(parada.longitud)}} 
         title={parada.nom} 
         key={'paradaID'+parada.id} 
         icon={{url:"https://mobilitat.bergueda.cat/img/icona_parada.png",
         anchor: new window.google.maps.Point(props.iconesZoom,props.iconesZoom),
          scaledSize: new window.google.maps.Size((props.iconesZoom*2),(props.iconesZoom*2))}} 
         onClick={() => props.obrirDetallParada(parada.id)}></Marker>)))}

      {props.parades.map((parada,index)=>(
      !parada.esBergueda && (<Marker
         visible={parada.visible} 
         position={{ lat: Number(parada.latitud), lng: Number(parada.longitud)}} 
         title={parada.nom} 
         key={'paradaID'+parada.id} 
         icon={{url:"https://mobilitat.bergueda.cat/img/icona_parada_gris.png",
         anchor: new window.google.maps.Point(props.iconesZoom,props.iconesZoom),
          scaledSize: new window.google.maps.Size((props.iconesZoom*2),(props.iconesZoom*2))}} 
         onClick={() => props.obrirDetallParada(parada.id)}></Marker>)))}      
    

             {/* Dibuix de la línia */}
        {props.dibuixLinies.map((linia,index)=>
          <Polyline
            path={linia}
            visible={props.linies[index].visible} 
            onClick={() => props.obrirDetallLinia(props.linies[index].codi)}
            options={{
              strokeColor:props.linies[index].color,
              strokeOpacity:0.8,
              strokeWeight:(props.iconesZoom/2)
            }}
          />) } 

        {props.geolocalitzacio.visible && 
          <Marker
         position={props.geolocalitzacio.ubicacio} 
         title="Ubicació actual" 
         key="ubicacio_actual" 
         icon={{url:"https://mobilitat.bergueda.cat/img/ubicacio_actual.png",
         anchor: new window.google.maps.Point(15,15),
        scaledSize: new window.google.maps.Size(30,30)}} 
         ></Marker>
         }

      {props.expedicionsActuals.map((expedicio,index)=>(
      <div>
        
        {expedicio.numAvisosUsuari===0 && //Si no hi ha avisos d'usuari
           <Marker
          position={{lat: Number(expedicio.circulaAra.posicioActual.latitud), lng: Number(expedicio.circulaAra.posicioActual.longitud)}}
          title={expedicio.linia.sigles + ": " + expedicio.paradaInici.nom + " (" + expedicio.paradaInici.hora+ ") > " + expedicio.paradaFinal.nom + " (" + expedicio.paradaFinal.hora + ")\nPropera parada: " + expedicio.circulaAra.paradesRestants[1].nom + " (" + expedicio.circulaAra.paradesRestants[1].horaPas + ")"} 
          key={'expedicio'+expedicio.id} 
          icon={{url:"https://mobilitat.bergueda.cat/img/icona_posicio_bus.png",
            anchor: new window.google.maps.Point(props.iconesZoom,props.iconesZoom),
            scaledSize: new window.google.maps.Size((props.iconesZoom*3),(props.iconesZoom*3))}} 
            onClick={() => props.obrirDetallExpedicio(expedicio.id)}
            onMouseOver={() => props.setExpedicioVisible(index)}
            onMouseOut={() => props.setExpedicioVisible(null)}
            ></Marker>}

        {expedicio.numAvisosUsuari!=0 && //Si hi ha avisos d'usuari
           <Marker
          position={{lat: Number(expedicio.circulaAra.posicioActual.latitud), lng: Number(expedicio.circulaAra.posicioActual.longitud)}}
          title={expedicio.linia.sigles + ": " + expedicio.paradaInici.nom + " (" + expedicio.paradaInici.hora+ ") > " + expedicio.paradaFinal.nom + " (" + expedicio.paradaFinal.hora + ")\nPropera parada: " + expedicio.circulaAra.paradesRestants[1].nom + " (" + expedicio.circulaAra.paradesRestants[1].horaPas + ")"} 
          key={'expedicio'+expedicio.id} 
          icon={{url:"https://mobilitat.bergueda.cat/img/icona_posicio_bus_avis.png",
            anchor: new window.google.maps.Point(props.iconesZoom,props.iconesZoom),
            scaledSize: new window.google.maps.Size((props.iconesZoom*3),(props.iconesZoom*3))}} 
            onClick={() => props.obrirDetallExpedicio(expedicio.id)}
            onMouseOver={() => props.setExpedicioVisible(index)}
            onMouseOut={() => props.setExpedicioVisible(null)}
            ></Marker>}
         
         
         </div>
         ))}

        {props.expedicioVisible && 
        <Polyline
            path={decodePolyline(props.expedicionsActuals[props.expedicioVisible].polilinia_codificada)}
            visible={true}
            // onClick={() => props.obrirDetallLinia(props.linies[index].codi)}
            options={{
              strokeColor:props.expedicionsActuals[props.expedicioVisible].linia.color,
              strokeOpacity:0.8,
              strokeWeight:2
            }}
          />
          }

    </GoogleMap>
  )



class MapaGran extends Component {
    constructor(){
        super()
        this.getCoordinates=this.getCoordinates.bind(this)
        this.setExpedicioVisible=this.setExpedicioVisible.bind(this)
        this.canviaIconesZoom=this.canviaIconesZoom.bind(this)
    }

    state = {
      expedicioVisible: null,
      iconesZoom: 5
    }
    
    //Funció per capturar les coordenades en clicar
    getCoordinates(event) {
        var lat = event.latLng.lat(), lng = event.latLng.lng()
        console.log({lat, lng});
    }

    canviaIconesZoom(nivellZoom) {
      console.log(nivellZoom)
      if (nivellZoom>=12) {
        this.setState({iconesZoom:10})
      } else this.setState({iconesZoom:5})
    }

    setExpedicioVisible(value) {
      this.setState({expedicioVisible:value});
    }
    

    render() {

    const decodePolyline = require('decode-google-map-polyline');
    var arrayLiniaLinies = [];
    this.props.linies.map((linia)=> arrayLiniaLinies.push(decodePolyline(linia.polilinia_codificada)))

  

    return (
        <div><MapaGoogle
                  geolocalitzacio={this.props.geolocalitzacio}
                  parades={this.props.parades}
                  opcionsMapa={this.props.opcionsMapa}
                  linies={this.props.linies}
                   dibuixLinies={arrayLiniaLinies}
                   obrirDetallParada={this.props.obrirDetallParada}
                   obrirDetallLinia={this.props.obrirDetallLinia}
                   obrirDetallExpedicio={this.props.obrirDetallExpedicio}
                   paradesVisibles={this.props.paradesVisibles}
                   getCoordinates={this.getCoordinates}
                   expedicionsActuals={this.props.expedicionsActuals}
                   setExpedicioVisible={this.setExpedicioVisible}
                   expedicioVisible={this.state.expedicioVisible}
                   iconesZoom={this.state.iconesZoom}
                   canviaIconesZoom={this.canviaIconesZoom}
                   
                   /></div>

    )
}


}

export default MapaGran