import React, {Component} from 'react';
import {Button, Form, Alert, Row, Col, DropdownButton, Dropdown, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import API from 'Api';
import { SliderPicker } from 'react-color';
import Carregant from '../../estructura/Carregant';
import MapaSeleccioPunt from '../../mapes/MapaSeleccioPunt';
import Desplegable from '../elements/Desplegable';
import SeleccioHoraPas from '../elements/SeleccioHoraPas';
import {DateTime} from 'react-datetime-bootstrap';
import CanviCondicioParada from './CanviCondicioParada';

class EditarParadesExpedicio extends Component {

    constructor(){
        super()

        this.assignaLinia=this.assignaLinia.bind(this)
        this.generaParades=this.generaParades.bind(this)
        this.assignaValor=this.assignaValor.bind(this)
        this.handleClick=this.handleClick.bind(this)
        this.girarOrdre=this.girarOrdre.bind(this)
        this.assignaHoraPas=this.assignaHoraPas.bind(this)
        this.assignaData=this.assignaData.bind(this)
        this.assignaDadesAState=this.assignaDadesAState.bind(this)
        this.assignaValorCheck=this.assignaValorCheck.bind(this)
        this.esborraParada=this.esborraParada.bind(this)
        this.dataNull=this.dataNull.bind(this)
        this.carregaRecorregut=this.carregaRecorregut.bind(this)
        this.generaDesplegables=this.generaDesplegables.bind(this)
        this.assignaIdParada=this.assignaIdParada.bind(this)
        this.titolSeleccionat=this.titolSeleccionat.bind(this)
      }

    state = {
        parades:[],
        novaHoraPas:null,

        horari:[],
        pantalla: 1,
        missatgeAxios: null,
        idParadaAfegir:null
    }

    //ASSIGNAR VARIABLES PER ENVIAR
      assignaValor(e) {
        this.setState({ [e.target.name]: e.target.value });
      };

      assignaValorCheck(e) {
        this.setState({ [e.target.name]: e.target.checked });
      };

      assignaData(data, tipus) {
        
        //Preparem el format de la data
        var novaData = new Date(data);
        var dd = String(novaData.getDate()).padStart(2, '0');
        var mm = String(novaData.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = novaData.getFullYear();

        var dataResposta = dd + '-' + mm + '-' + yyyy;
        
        //Assignem la data a l'estat corresponent
        tipus==="dataInici" && this.setState({dataInici:dataResposta})
        tipus==="dataFinal" && this.setState({dataFinal:dataResposta})

      };

      dataNull(event, tipus) {
        event.preventDefault();
        //Assignem null a la data a l'estat corresponent
        tipus==="dataInici" && this.setState({dataInici:null})
        tipus==="dataFinal" && this.setState({dataFinal:null})

      };

      assignaColor(color) {
            this.setState({ color: color.hex });
      }


      //CLIC PER ENVIAR
      handleClick(event) {
        event.preventDefault();
        this.setState({pantalla:2})
        this.editar();
      }

        //CLIC PER AFEGIR UNA NOVA PARADA
        afegirParada(event) {

            var nouNumParada=this.state.numParades+1
            this.setState({numParades:nouNumParada})
        }

        //CLIC PER AFEGIR UNA NOVA PARADA
        treureParada(event) {
            event.preventDefault();
            var nouNumParada=this.state.numParades-1
            this.setState({numParades:nouNumParada})
        }

        //ASSIGNAR PARADA A L'ITINERARI
        assignaLinia(event) {    
            this.setState({codiLinia:event, horari:[]});
          };

       //ORDRE RECORREGUT
      carregaRecorregut() { 
          
        const baseURL = `expedicio/?id=` + this.props.expedicio.id;

        API.get(baseURL)
          .then(res => {
            if (res.status===200){
                //Assignem codi de la linia
                this.setState({codiLinia:this.props.expedicio.linia.codi})
                console.log(res.data.horari);
                //Preparem l'array amb els itineraris rebuts
                var arrayHorari=[];
                res.data.horari.map((puntParada, index) => arrayHorari[index] = {idParada:puntParada.parada.id, nom:puntParada.parada.nom, horaPas:puntParada.horaPas, agafaViatgers:puntParada.agafaViatgers, deixaViatgers:puntParada.deixaViatgers});
                
                this.setState({horari:arrayHorari})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }

        //ORDRE CARREGA PARADES
        carregaParades() {    
        API.get(`parada/?ordre=nom`)
            .then(res => {
            if (res.status===200){
                this.setState({parades:res.data.llistat})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
            }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
        }

      girarOrdre(event) {
        event.preventDefault();
        this.state.invers ? this.setState({invers:false}) : this.setState({invers:true});
        this.setState({horari:[]})
    }

      //ORDRE AFEGIR
      editar() {

        //Preparem les dades per enviar
        const dadesExpedicio = {
            id: this.state.idExpedicio,
            codiLinia: this.state.codiLinia,
            dilluns:  this.state.dilluns,
            dimarts:  this.state.dimarts,
            dimecres:  this.state.dimecres,
            dijous:  this.state.dijous,
            divendres:  this.state.divendres,
            dissabte:  this.state.dissabte,
            festiu:  this.state.festiu,
            dataInici: this.state.dataInici,
            dataFinal: this.state.dataFinal
        };
    
        API.put(`expedicio/`, { dadesExpedicio, token:this.props.token })
          .then(res => {
            if (res.status===201){
                this.setState({pantalla:3, missatgeAxios:res.data.missatge})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }


    esborraParada(event,idParada) {
        //Prevenim el botó
        event.preventDefault();
        console.log(idParada);
    
        //Acció d'esborrar al Backend
        const idExpedicio = this.props.expedicio.id;

        API.delete(`expedicioparada/`, { data: {idExpedicio, idParada, token:this.props.token }}) //Les ordres delete cal especificar data:
        .then(res => {
            if (res.status===200){
                //Tornem a recuperar el recorregut des de la BD.
                this.carregaRecorregut();
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
        }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X      
       
    }

    //ORDRE AFEGIR
    afegirParada(event) {
        event.preventDefault();
        //Preparem les dades per enviar
        const dades = {
            idParada: this.state.idParadaAfegir,
            idExpedicio: this.props.expedicio.id,
            horaPas: this.state.novaHoraPas
        };
    
        API.post(`expedicioparada/`, { 
            idParada: this.state.idParadaAfegir,
            idExpedicio: this.props.expedicio.id,
            horaPas: this.state.novaHoraPas,
            token:this.props.token })
          .then(res => {
            if (res.status===201){
                //Tornem a recuperar el recorregut des de la BD.
                this.carregaRecorregut();
                this.setState({novaHoraPas:null, idParadaAfegir:null})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }

    generaParades(horari, index) {
        return (
            <div className="horaPasParada">
               
               <div><strong>{horari.horaPas}</strong>: {horari.nom} <CanviCondicioParada horari={horari} idExpedicio={this.props.expedicio.id} token={this.props.token} carregaExpedicions={this.carregaRecorregut}/> <FontAwesomeIcon icon="times" className="maBoto" color="#336122" onClick={(e)=>this.esborraParada(e, horari.idParada)}/></div>
               
               </div>           

        )
    }    

    trobaHoraPasRebuda(idParada){
        for (var i=0; i<this.state.horari.length; i++) {
            if (this.state.horari[i].idParada===idParada){
                var data= new Date();
                var horari = this.state.horari[i].horaPas;
                var hora = horari.split(":")[0];
                var minuts = horari.split(":")[1];

                data.setMinutes(minuts);
                data.setHours(hora);

                console.log(data);
                return data;
            } 
        }
        return null;

    }

    assignaIdParada(event) {    
        this.setState({idParadaAfegir:event});
        };

    assignaDadesAState() {

        //Assigna dates periode
        this.props.expedicio.periode.inici && this.assignaData(this.props.expedicio.periode.inici, "dataInici");
        this.props.expedicio.periode.final && this.assignaData(this.props.expedicio.periode.final, "dataFinal");

        
        //Assigna dies setmana i idExpedicio

        this.setState({
            idExpedicio: this.props.expedicio.id,
        })

    }

       //RENDERITZA ELS DESPLEGABLES DE LA PARADA
       generaDesplegables() {
                //Generem el loop per la llista de parades de la línia per ordre
            var llistaDesplegables =  <Desplegable elements={this.state.parades} 
                onSelect={this.assignaIdParada}
                // seleccionat={"Escull parada..."}
                seleccionat={()=>this.titolSeleccionat()}
                />;
            return llistaDesplegables;
    } 


    titolSeleccionat(tipusObjecte) {
        if (tipusObjecte==="parada") {           
            var paradaSeleccionada=this.state.parades.filter((parada)=>parada.id===this.state.idParadaAfegir)
            
            if (paradaSeleccionada.length>0){
                return paradaSeleccionada[0].nom;
            }
            return "Escull parada...";
        } 


        return "Falta programar el retorn de la llista";
    }

    assignaHoraPas(value) {
        //Preparem l'hora rebuda en el format correcte per enviar
        var novaHora = new Date(value);
        var hh = String(novaHora.getHours()).padStart(2, '0');
        var mm = String(novaHora.getMinutes()).padStart(2, '0');
        var hora = hh + ':' + mm; //Valor de l'hora per registrar

        this.setState({novaHoraPas:hora}); // Tornem a assignar l'array de nou a l'estat 
    }


    componentDidMount() {
        
        this.carregaRecorregut();
        this.carregaParades();
        // this.assignaDadesAState();
    }

    render() {

        return (
            
            <div>
                <div className="fonsFinestraFlotant" onClick={this.props.tancar}></div>
                     <div className="finestraFlotant finestraFlotantAvisosUsuari">
                        <div className="botoTancar"><FontAwesomeIcon icon="times-square" size="2x" className="maBoto" color="#336122" onClick={this.props.tancar}/></div>
                        <div className="contingutFinestra">
                <Card style={{ width: '100%' }}>
                    <Card.Header>Edita les parades de l'expedició</Card.Header>

                     {this.state.pantalla===1 && //PANTALLA 1: FORMULARI
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div className="dadesElement"><span style={{backgroundColor: this.props.expedicio.linia.color}} className="siglesLinia maBoto">
                                    {this.props.expedicio.linia.sigles}</span> <strong>{this.props.expedicio.paradaInici.hora}</strong>: {this.props.expedicio.paradaInici.nom} <FontAwesomeIcon icon="arrow-right" color="#000000"/> <strong>{this.props.expedicio.paradaFinal.hora}</strong>: {this.props.expedicio.paradaFinal.nom}</div>
                                    {this.state.horari.map((horari,index)=> this.generaParades(horari, index))}
                                    <br/>
                                    <Form.Group controlId="formBotoEnviar">
                                        <h5>Afegir parada a l'expedició</h5> 
                                        <div className="llistatParades">
                                        <Desplegable elements={this.state.parades} 
                                                    onSelect={this.assignaIdParada}
                                                    // seleccionat={"Escull parada..."}
                                                    seleccionat={this.titolSeleccionat("parada")}
                                        />
                                         <DateTime
                                            pickerOptions={{format:"HH:mm"}}
                                            placeholder="Hora de pas"
                                            // value={this.state.novaHoraPas}
                                            onChange={(e)=>this.assignaHoraPas(e)}/>
                                        </div>
                                        <br/>
                                        <Button variant="success" onClick={(e)=>this.afegirParada(e)}>Afegir parada</Button>
                                    </Form.Group>
                            </Col>
                            
                            </Row>
                        
                        </Card.Body>
                    }

                    {this.state.pantalla===2 && //PANTALLA 2: CARREGANT
                        <Card.Body>                            
                            <Carregant/>                        
                        </Card.Body>
                    }   


                    {this.state.pantalla===3 && //PANTALLA 3: mostra resultat
                        <Alert variant="success">
                            Expedició modificada correctament!
                        </Alert>

                        }

                    {this.state.pantalla===4 && //PANTALLA 4: mostra error
                        <Alert variant="danger">
                            Hi ha hagut un error: {this.state.missatgeAxios}
                            </Alert>

                        }


                </Card>
                </div>
            </div>
            </div>
        )
    }
}

    export default EditarParadesExpedicio;