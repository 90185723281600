import axios from 'axios';
var baseURL = {baseURL: process.env.PUBLIC_URL + '/api/'};
// var baseURL = {baseURL: 'https://mobilitat.bergueda.cat/api'};

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // baseURL = {baseURL: 'http://localhost/taller_itv/public/api/'};
    baseURL = {baseURL: 'https://mobilitat.bergueda.cat/api/'};
}

if (window.location.hostname==="localhost") {
    // baseURL = {baseURL: 'http://localhost/taller_itv/public/api/'};
    baseURL = {baseURL: 'https://mobilitat.bergueda.cat/api/'};
}

export default axios.create(baseURL);