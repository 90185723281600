import React, {Component} from 'react';
import {Card, OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';
import {Link} from 'react-router-dom';

class LiniesParada extends Component {
    generaResultat(linia, index) {
        return (
            <div className="divTableRow liniaResposta noSelect" key={"linia"+index}> 
                <div className="divTableCell">
                    <div style={{backgroundColor: linia.color}} className="siglesLinia">
                        {linia.sigles}
                    </div>
                </div>
                <div className="divTableCell">
                <Link to={"/linia/"+linia.codi}>{linia.nom} <span>
                {linia.transport_demanda && <OverlayTrigger placement="top"  overlay={<Tooltip> Transport a la demanda</Tooltip>}>
                    <Badge variant="secondary">TD</Badge>
                        </OverlayTrigger>              
                }</span>                
                </Link>
                 </div>    
        </div>

        )
    }
    
    
    
    render(){
        return(
            <div className="modulPagina">
                <Card style={{ width: '100%' }}>
                    <Card.Body>
                        <h3>Línies</h3>
                        <div className="divTable">
                             <div className="divTableBody">
                                {this.props.linies.map((linia,index)=> this.generaResultat(linia, index))}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default LiniesParada