import React, {Component} from 'react';
import {Badge, Dropdown, DropdownButton, Alert, Table, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { LinkContainer } from "react-router-bootstrap";
import Carregant from '../estructura/Carregant';


import API from 'Api';
import Expedicio from '../Expedicio';
import IconaLectius from 'Components/linies/IconaLectius';

class TaulaHorarisParada extends Component {
    constructor(){
        super()
        this.seleccionaParada=this.seleccionaParada.bind(this)
        this.retornaTitolParada=this.retornaTitolParada.bind(this)
        this.obteDades=this.obteDades.bind(this)
        this.canviEstatFinestra=this.canviEstatFinestra.bind(this)
        this.obreFinestra=this.obreFinestra.bind(this)
      }

state = {
    pantalla: 0,
    paradaSeleccionada:null,
    dades: null,
    expedicions: null,
    missatgeAxios: null,
    finestraOberta: false,
    idExpedicio: null
  }

canviEstatFinestra() {
    this.state.finestraOberta ? this.setState({finestraOberta:false}) : this.setState({finestraOberta:true});
}

obreFinestra(idExpedicio) {
 this.setState({finestraOberta:true, idExpedicio});
}

seleccionaParada(event) {
    this.setState({
        paradaSeleccionada: event,
        pantalla: 1
    },this.obteDades);;
  };

retornaTitolParada() {
    if (!this.state.paradaSeleccionada) {
        return "Escull destinació..."
    } else {
        for (var i=0; i<this.props.destinacions.length; i++){ //Recorrem l'array de parades per agafar el nom que correspon a l'ID seleccionat
            if (this.props.destinacions[i].id===this.state.paradaSeleccionada){
                return this.props.destinacions[i].nom;
            }
        }
    }
}

//ORDRE CARREGA PARADES
obteDades() {    
    API.get(`parada/horarisParada.php?paradaOrigen=` + this.props.dades.id + `&paradaDestinacio=` + this.state.paradaSeleccionada)
      .then(res => {
        if (res.status===200){
            this.setState({pantalla:2, dades:res.data.dades, expedicions:res.data.expedicions})
        } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
      }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X

  }

generaHoraPas(expedicio, direccio) {
    return (
        <OverlayTrigger
            key={"expedicioId"+expedicio.id}
            placement="top"
            overlay={
                <Tooltip id={`tooltip-${"expedicioId"+expedicio.id}`} className="tooltipHoraPas">
                <p><span style={{backgroundColor:expedicio.linia.color, padding:"0 5px", borderRadius:"10%"}}>{expedicio.linia.sigles}</span> {expedicio.linia.nom}</p>
                <p>Direcció: {expedicio.destinacioExpedicio}</p>
                <p><br/></p>
                {direccio==="anada" && <p style={{fontWeight:"700"}}>Arribada a {this.state.dades.estacioDestinacio.nom}: {expedicio.horaArribada}</p>}
                {direccio==="tornada" && <p style={{fontWeight:"700"}}>Arribada a {this.state.dades.estacioOrigen.nom}: {expedicio.horaArribada}</p>}
                </Tooltip>
            }
            >
            <div className="horaPas horaPasTaulaParada maBoto" onClick={() => this.obreFinestra(expedicio.id)}>{expedicio.horaSortida} <IconaLectius circulaLectius={expedicio.circulaLectius}/> <span>
                {expedicio.linia.transportDemanda && <OverlayTrigger placement="top"  overlay={<Tooltip> Transport a la demanda</Tooltip>}>
                    <Badge variant="secondary">TD</Badge>
                        </OverlayTrigger>              
                }</span></div>
            </OverlayTrigger>
    )
}


render(){
    var titolParadaSeleccionda = this.retornaTitolParada();


    return(
        <div className="modulPagina">
            <h3>Horaris en aquesta parada</h3>
            <div className="llistaDestinacio"><div style={{marginRight:'5px'}}>Destinació:</div>
                <DropdownButton
                    variant="outline-secondary"
                    title={titolParadaSeleccionda}
                    id="input-group-dropdown-1"
                    onSelect={this.seleccionaParada}
                    >
                        {this.props.destinacions.map((parada,index) =>
                        <Dropdown.Item eventKey={parada.id} key={"llistaDestinacions-idParada"+parada.id}>{parada.nom}</Dropdown.Item>
                        )}
                </DropdownButton>
            </div>


            {this.state.pantalla===1 && //PANTALLA 1: CARREGANT                       
                        <Carregant/>                        
            }  

            {this.state.pantalla===2 && //PANTALLA 2: GENERA DADES                    
                <div>
                    <div className="embolcallTaulaHorariParada TaulaHorariAnada">
                        <p className="titol"><span className="origen">{this.state.dades.estacioOrigen.nom}</span> <FontAwesomeIcon icon="arrow-right"  color="#336122"/> <LinkContainer to={"/parada/"+this.state.dades.estacioDestinacio.id} className="maBoto"><span className="destinacio">{this.state.dades.estacioDestinacio.nom}</span></LinkContainer></p>
                        
                        <Table striped bordered hover className="taulaHorariParada">
                            <tbody>
                            {this.state.expedicions.anada.setmana && <tr>
                                <td className="taulaTitolDia">De dilluns a divendres (no festius)</td>
                                <td>
                                    {this.state.expedicions.anada.setmana.map((expedicio, index) => (
                                        this.generaHoraPas(expedicio, "anada")
                                    ))}
                                </td>
                            </tr>}
                            {this.state.expedicions.anada.dissabte &&<tr>
                            <td className="taulaTitolDia">Dissabte</td>
                                <td>
                                    {this.state.expedicions.anada.dissabte.map((expedicio, index) => (
                                        this.generaHoraPas(expedicio, "anada")
                                    ))}
                                </td>
                            </tr>}
                            {this.state.expedicions.anada.festius &&<tr>
                            <td className="taulaTitolDia">Diumenge i festius</td>
                                <td>
                                    {this.state.expedicions.anada.festius.map((expedicio, index) => (
                                        this.generaHoraPas(expedicio, "anada")
                                    ))}
                                </td>
                            </tr>}
                            </tbody>
                        </Table> 
                        </div>
                         <div className="embolcallTaulaHorariParada TaulaHorariTornada">
                         <p className="titol"><LinkContainer to={"/parada/"+this.state.dades.estacioDestinacio.id} className="maBoto"><span className="destinacio">{this.state.dades.estacioDestinacio.nom}</span></LinkContainer> <FontAwesomeIcon icon="arrow-right"  color="#336122"/> <span className="origen">{this.state.dades.estacioOrigen.nom}</span></p>
                         
                         <Table striped bordered hover className="taulaHorariParada">
                             <tbody>
                             {this.state.expedicions.tornada.setmana && <tr>
                                 <td className="taulaTitolDia">De dilluns a divendres (no festius)</td>
                                 <td>
                                     {this.state.expedicions.tornada.setmana.map((expedicio, index) => (
                                         this.generaHoraPas(expedicio, "tornada")
                                     ))}
                                 </td>
                             </tr>}
                             {this.state.expedicions.tornada.dissabte &&<tr>
                             <td className="taulaTitolDia">Dissabte</td>
                                 <td>
                                     {this.state.expedicions.tornada.dissabte.map((expedicio, index) => (
                                         this.generaHoraPas(expedicio, "tornada")
                                     ))}
                                 </td>
                             </tr>}
                             {this.state.expedicions.tornada.festius &&<tr>
                             <td className="taulaTitolDia">Diumenge i festius</td>
                                 <td>
                                     {this.state.expedicions.tornada.festius.map((expedicio, index) => (
                                         this.generaHoraPas(expedicio, "tornada")
                                     ))}
                                 </td>
                             </tr>}
                             </tbody>
                         </Table> 
                         </div> 
                     </div>                     
            }                 
               

            {this.state.pantalla===4 && //PANTALLA 4: mostra error
                        <Alert variant="danger">
                            Hi ha hagut un error: {this.state.missatgeAxios}
                            </Alert>

                        }


            {this.state.finestraOberta && //FINESTRA FLOTANT
                            <div>
                                <div className="fonsFinestraFlotant" onClick={this.canviEstatFinestra}></div>
                                <div className="finestraFlotant finestraFlotantAvisosUsuari">
                                    <div className="botoTancar"><FontAwesomeIcon icon="times-square" size="2x" className="maBoto" color="#336122" onClick={this.canviEstatFinestra}/></div>
                                    <div className="contingutFinestra"><Expedicio idExpedicio={this.state.idExpedicio}/></div>

                                </div>
                            
                            </div>
                            }            


        </div>
    )
}
}

export default TaulaHorarisParada