import React, {Component} from 'react';
import {Card, Row, Col} from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";

import API from 'Api';

class ModulParades extends Component {
    //CARREGA PARADES
    constructor(){
        super()
        this.obteDades=this.obteDades.bind(this)
    }
    
    
    
    obteDades() {
        const baseURL = `parada/?limit=8`;    
        API.get(baseURL)
        .then(res => {
            const parades = res.data.llistat;
            this.setState({parades});     
        }) 
    }
  
  
    state = {
        parades:null
      }
  
  
      componentDidMount() {
        this.obteDades();
      }
    render() {

        return (
          <div>
            {this.state.parades && <div>
            <Card style={{ width: '100%' }} className="targetaPortada">
                <Card.Body>
                    <Card.Title>Parades</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Horaris i destinacions des de cada parada</Card.Subtitle>
                        <Row>
                            <Col md>
                            {this.state.parades.map((parada, index) => ( //Només agafem les 4 primeres parades
                                index<4 && <LinkContainer to={"/parada/"+parada.id}><Card.Text className="maBoto nomParadaPortada">{parada.nom}</Card.Text></LinkContainer>
                            ))}

                            </Col>
                            <Col md>
                            {this.state.parades.map((parada, index) => ( //Només agafem les 4 últimes parades
                                index>=4 && <LinkContainer to={"/parada/"+parada.id}><Card.Text className="maBoto nomParadaPortada">{parada.nom}</Card.Text></LinkContainer>
                            ))}
                            </Col>
                        </Row>
                        <LinkContainer to={"/parada"}><Card.Link>Veure totes</Card.Link></LinkContainer>
                </Card.Body>
                </Card>
            </div>}
          </div>
        )
      }
}

export default ModulParades