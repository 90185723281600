import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TransportDemanda extends Component {
  render() {
    return (
      <div className="modulTransportDemanda">
        <Alert variant="secondary">
          <Alert.Heading>
            Aquesta línia és de <strong>transport a la demanda</strong>
          </Alert.Heading>
          <img
            src="https://mobilitat.bergueda.cat/img/logo_tad.png"
            className="logoTAD"
          />
          <p>
            Per utilitzar aquest servei cal abans sol·licitar-lo, com a màxim,
            fins a les 20h del dia anterior laborable a la data del viatge. La
            petició a través de l'aplicació per a dispositius mòbils{" "}
            <strong>Flexitransport</strong>, disponible per a{" "}
            <a href="https://sites.google.com/amtu.cat/flexitransport-app-usuari">
              Android
            </a>{" "}
            i{" "}
            <a href="https://sites.google.com/amtu.cat/flexitransport-app-usuari">
              iOS
            </a>
            .
          </p>
          <hr />
          <p className="mb-0">
            També es pot fer a través del número de telèfon per sol·licitar el
            Servei de Transport a la Demanda, el <strong>900 69 65 66</strong>.
            Operatiu de dilluns a divendres de 8h a 16h per a sol·licitar
            reserves (fins les 19h per anul·lacions/incidències) i dissabtes de
            9h a 14h.
          </p>
          <p className="mb-0">
            Més informació sobre el servei de transport a la demanda al web del{" "}
            <a href="https://www.bergueda.cat/transportademanda/">
              Consell Comarcal del Berguedà
            </a>
            .
          </p>
        </Alert>
      </div>
    );
  }
}

export default TransportDemanda;
