import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 
const mapStyles = {
  width: '100%',
  height: '50%',
};

export class MapContainerCoo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        lat: 42.098871,
        lng: 1.860893,
        time: Date.now()
    }
}

  
  componentWillReceiveProps(nextProps) {
    this.setState({
      lat: nextProps.lat,
      lng: nextProps.lng,
    });
  }
  
  
  componentDidMount() {
    setTimeout(() => this.setState({ time: Date.now() }), 1000);
  }


  render() {
    console.log(this.state.lat);
    console.log(this.state.lng);
    const coordenades = ({
      lat: this.state.lat,
      lng: this.state.lng
    })

    return (
        <div className="mapa">
        <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 42.098871,
        lng: 1.860893
        }}
         center={coordenades}
      >
          <Marker
    title={'The marker`s title will appear as a tooltip.'}
    name={'SOMA'}
    position={coordenades} />
    </Map></div>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: (`AIzaSyCYEQJ7nw4waLh4o_IvlQHDCzGVQQvlOOI`)
})(MapContainerCoo)