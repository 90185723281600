import React, {Component} from 'react';

import API from 'Api';
import MapContainerCoo from './MapContainerCoo'
import {Link} from 'react-router-dom';
import Carregant from './estructura/Carregant';
import {Container, Row, Col} from 'react-bootstrap';
import DetallsLinia from './linies/DetallsLinia';
import MapaLinia from './linies/MapaLinia';
import RecorregutExpedicio from './expedicions/RecorregutExpedicio';
import HorarisLinia from './linies/HorarisLinia';
import LlistaTotesLinies from './linies/LlistaTotesLinies';
import DetallsExpedicio from './expedicions/DetallsExpedicio';
import AvisosGrup from './avisos/AvisosGrup';
import AvisosUsuari from './avisos/usuari/AvisosUsuari';
import Parada from './Parada';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LlistaExpedicions from './expedicions/LlistaExpedicions';
import TransportDemanda from './linies/TransportDemanda';


class Expedicio extends Component {
  constructor(){
    super()
    this.obteDades=this.obteDades.bind(this)
    this.obteDadesPosicio=this.obteDadesPosicio.bind(this)
    this.canviEstatFinestra=this.canviEstatFinestra.bind(this)
    this.obreFinestraParada=this.obreFinestraParada.bind(this)
    this.interval=null;
  }
  
  

  obteDades() {
    this.noID=false;
    var id=null;
    if (this.props.match&&this.props.match.params.id) {
      id = Number(this.props.match.params.id);
    } else if (this.props.idExpedicio) {
      id = Number(this.props.idExpedicio);
    } else {
      this.setState({ pantalla: 2 });
    } 

    console.log(id);

    if (id) {
      const baseURL = `expedicio/?id=` + id;

      API.get(baseURL)
      .then(res => {
          if (res.data.status==="OK"){
              const dades = res.data.info_expedicio;
              const horari = res.data.horari;
              const avisos = res.data.avisos;
              const polilinia_codificada = res.data.polilinia_codificada;
              const circulaAra= res.data.info_expedicio.circulaAra;
              document.title =dades.linia.sigles + ": Servei des de " + dades.paradaInici.nom + " a " + dades.paradaFinal.nom + " de les "+ dades.paradaInici.hora + " - Mobilitat Berguedà";
              this.setState({ dades, horari, avisos, polilinia_codificada, circulaAra});
              
              //Assigna en un array les parades rebudes
              var arrayParades =[];
              horari.map((item)=>arrayParades.push(item.parada));
              this.setState({arrayParades});


              //comprova que la data no és anterior al dia d'avui
              if (dades.periode.final) {
                  const dataRebuda = new Date(dades.periode.final);
                  const dataActual = new Date();

                  console.log(dataRebuda);
                  if (dataRebuda<dataActual) {
                      this.setState({circula:false});
                  }
          }

          }
        const status = res.data.status;
        this.setState({status, pantalla:3 });
      })
    }

  }

  obteDadesPosicio() {
    var id=null;
    if (this.props.match&&this.props.match.params.id) {
      id = Number(this.props.match.params.id);
    } else if (this.props.idExpedicio) {
      id = Number(this.props.idExpedicio);
    } 


    if (id) {
      const baseURL = `expedicio/posicioBus.php?id=` + id;

      API.get(baseURL)
      .then(res => {
          if (res.data.status==="OK"){
              const circulaAra = res.data.circulaAra;
              this.setState({  circulaAra});
          }
      })
    } 
  }


    state = {
        dades: null,
        horari: null,
        avisos: null,
        arrayParades: null,
        polilinia_codificada: null,
        status:"ERROR",
        pantalla: 1,
        circula: true,
        finestraOberta: false,
        idParada: null
      }
    
      canviEstatFinestra() {
        this.state.finestraOberta ? this.setState({finestraOberta:false}) : this.setState({finestraOberta:true});
    }

    obreFinestraParada(idParada) {
     this.setState({finestraOberta:true, idParada});
  }


      
      
  componentDidUpdate(prevProps) { //Si es canvia la url que rebem a traves de match, es torna a cridar al backend.
        if (this.props.match&&prevProps.match.url !== this.props.match.url) {
            this.obteDades();
            this.setState({finestraOberta:false})
        }
      }

  componentDidMount() {
    this.obteDades();
    this.obteDadesPosicio();
    this.interval = setInterval(function() {
      this.obteDadesPosicio();
    }.bind(this), 5000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>
        {this.state.pantalla===1 && //PANTALLA 1: Si s'han rebut dades, es carreguen
          <div>
            <Carregant/>
          </div>
        } 
        
        {this.state.pantalla===2 && //PANTALLA 2: Si NO s'han rebut dades, es mostra un missatge d'error
          <div className="modulInferior">
              <LlistaExpedicions/>
          </div>
        }

        {this.state.pantalla===3 && //Genera la interficie quan ha rebut les dades
          <div>
          { this.state.status==="OK"&&this.state.circula &&
          <div className="modulInferior">
            <Container>
                <DetallsExpedicio key={"dadesCodiID"+this.state.dades.id} dades={this.state.dades} numParades={this.state.horari.filter((horari)=>horari.parada.visible).length}/>
            </Container>
            <MapaLinia linia={this.state.polilinia_codificada} color={this.state.dades.linia.color} parades={this.state.arrayParades} posicioBus={this.state.circulaAra}/>
                <div className="modulInferior">
                    <Container>
                        {this.state.dades.linia.transport_demanda && <TransportDemanda/>}
                        <Row>
                            <Col md><RecorregutExpedicio color={this.state.dades.linia.color} horari={this.state.horari} circulaAra={this.state.circulaAra} obreFinestraParada={this.obreFinestraParada}/></Col>
                            <Col md  className="separacioColumna">
                              <div className="quadre avisosExpedicio"><AvisosUsuari parades={this.state.arrayParades} idExpedicio={this.state.dades.id}/></div>
                              <div className="quadre avisosExpedicio"><AvisosGrup dades={this.state.avisos}/></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            }

            { (this.state.status==="ERROR"||!this.state.circula) &&
          <div className="modulInferior">
            <Container>
                No s'han trobat expedicions amb aquest codi.
            </Container>
            </div>
            } 
            </div>
        }             
          
      {this.state.finestraOberta && //FINESTRA FLOTANT
          <div>
              <div className="fonsFinestraFlotant" onClick={this.canviEstatFinestra}></div>
              <div className="finestraFlotant finestraFlotantAvisosUsuari">
                  <div className="botoTancar"><FontAwesomeIcon icon="times-square" size="2x" className="maBoto" color="#336122" onClick={this.canviEstatFinestra}/></div>
                  <div className="contingutFinestra"><Parada idParada={this.state.idParada}/></div>          
          </div></div>
          }
        </div>
   )
  }    
}


export default Expedicio;