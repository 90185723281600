import React, {Component} from 'react';
import { LinkContainer } from "react-router-bootstrap";
import {Navbar, Nav, NavItem} from 'react-bootstrap'
import logo from '../../img/marca/logo.svg'
import CercadorWidget from './CercadorWidget';

class Top extends Component {

render() {

    return (
      <div>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" sticky="top">
          <LinkContainer to="/" onClick={()=>this.props.mostraMenu(true)}><Navbar.Brand>
                <img
                    src={logo}
                    width="250"
                    height="100"
                    className="d-inline-block align-top"
                    alt="Mobilitat Berguedà"
                />
            </Navbar.Brand></LinkContainer>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav></Nav>

                {this.props.mostraMenu &&                
                <Nav className="menuSuperior">
                  <LinkContainer to="/mapa"><NavItem>Mapa</NavItem></LinkContainer>
                  <NavItem><CercadorWidget canviOrigen={this.props.canviOrigen} canviDestinacio={this.props.canviDestinacio} canviHora={this.props.canviHora} canviData={this.props.canviData} itinerari={this.props.itinerari} assignarItinerari={this.props.assignarItinerari}/></NavItem>    
                  <LinkContainer to="/parada/"><NavItem>Parades</NavItem></LinkContainer>     
                  <LinkContainer to="/linia"><NavItem>Línies</NavItem></LinkContainer>                     
                  <LinkContainer to="/expedicio/"><NavItem>Servei actual</NavItem></LinkContainer>
                  <a href="https://www.bergueda.cat/transportademanda/" class="linkmenu" target="_blank"><NavItem>Transport a la demanda</NavItem></a>
                </Nav> }

            </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }

}

export default Top