import Main from './Components/Main'
import {withRouter} from 'react-router-dom';
import React, { Component } from 'react';
import CacheBuster from './CacheBuster';

//FONT AWESOME
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faCoffee } from '@fortawesome/pro-solid-svg-icons/faCoffee';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faWalking } from '@fortawesome/pro-solid-svg-icons/faWalking';
import { faBus } from '@fortawesome/pro-solid-svg-icons/faBus';
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faTimesSquare } from '@fortawesome/pro-solid-svg-icons/faTimesSquare';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faMapMarkedAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkedAlt';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt';
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons/faHourglassHalf';
import { faChevronCircleRight } from '@fortawesome/pro-solid-svg-icons/faChevronCircleRight';
import { faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faLayerGroup} from '@fortawesome/pro-solid-svg-icons/faLayerGroup';
import { faCrosshairs} from '@fortawesome/pro-solid-svg-icons/faCrosshairs';
import { faClock} from '@fortawesome/pro-solid-svg-icons/faClock';
import { faUndo} from '@fortawesome/pro-solid-svg-icons/faUndo';
import { faArrowCircleDown} from '@fortawesome/pro-solid-svg-icons/faArrowCircleDown';
import { faFilePdf} from '@fortawesome/pro-solid-svg-icons/faFilePdf';
import { faFile} from '@fortawesome/pro-solid-svg-icons/faFile';
import { faLink} from '@fortawesome/pro-solid-svg-icons/faLink';
import { faExchange} from '@fortawesome/pro-solid-svg-icons/faExchange';
import { faCalendarCheck } from '@fortawesome/pro-solid-svg-icons/faCalendarCheck';
import { faSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown';
import { faSort } from '@fortawesome/pro-solid-svg-icons/faSort';
import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons/faGraduationCap';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faSkiing } from '@fortawesome/pro-solid-svg-icons/faSkiing';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faMoneyBillWave } from '@fortawesome/pro-solid-svg-icons/faMoneyBillWave';

library.add(faMoneyBillWave, faExternalLink, faSkiing, faInfoCircle, faGraduationCap, faSort, faSortUp, faSortDown, faCalendarCheck,faExchange, faFile, faLink, faFilePdf,faCrosshairs, faLayerGroup, faExclamationTriangle, faCheckSquare, faCoffee, faSearch, faChevronCircleRight, faWalking, faSync, faBus, faCheckCircle, faCircle, faTimesSquare, faHourglassHalf, faTimes, faUsers, faMapMarkedAlt, faArrowLeft, faArrowRight, faPencilAlt, faClock, faArrowCircleDown, faUndo)

class App extends Component {
    render() {
      return (
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
              // setTimeout(()=>window.location.reload(true), 2000);
              // setTimeout(()=console.log("prova"), 3000);
            }
  
            return (
              <Main/>
            );
          }}
        </CacheBuster>
      );
    }

    // render() {
    //   return (
    //           <Main/>
    //   );
    // }
  }

export default withRouter(App)
