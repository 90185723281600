import React, {Component} from 'react';
import {DateTime} from 'react-datetime-bootstrap';

class SeleccioHoraPas extends Component {

    state = {
        mostra: true
    }

    amagaParada() {
        this.state.mostra ? this.setState({mostra:false}) : this.setState({mostra:true})
    }

    // componentDidMount(){
    //     !this.props.value && this.setState({mostra:false});
    // }

    render() {
        return (
            <div>
            {this.state.mostra &&
                <div>
                    <DateTime
                            pickerOptions={{format:"HH:mm"}}
                            placeholder="Hora de pas"
                            value={this.props.value}
                            onChange={(e)=>this.props.onSelect(e, this.props.idParada)}/>
                            <span className="maBoto" onClick={() => {
                                this.props.esborraHoraPas(this.props.idParada)  
                                this.amagaParada()
                                }}>X</span>
                </div>
            }

            {!this.state.mostra &&
                <span className="maBoto" onClick={() => {this.amagaParada()}}>+</span>
            }
            </div>

        )
    }
}

export default SeleccioHoraPas;


    