import React, {Component} from 'react';
import MapaGran from './mapes/MapaGran';

import API from 'Api';
import Carregant from './estructura/Carregant';
import DetallParada from './mapa_pagina/DetallParada';
import {Container, Row, Col, Button} from 'react-bootstrap';
import CapesMapa from './mapa_pagina/CapesMapa';
import Parada from './Parada';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Linia from './Linia';
import Expedicio from './Expedicio';

class Mapa extends Component {

    state = {
        parades: null,
        totesParades:null,
        linies: null,
        opcionsMapa: { 
          centre: {lat: 42.098871, lng: 1.860893},
          zoom:10 
        },
        geolocalitzacio: {
          visible: false,
          ubicacio: null
        },
        generaInterficie: false,
        detallParada:null,
        detallLinia:null,
        detallExpedicio:null,
        finestraOberta: false,
        paradesVisibles: true,
        menuCapesVisible: true,
        expedicionsActuals: []
      }

      
    constructor(){
        super()
        this.obrirDetallParada=this.obrirDetallParada.bind(this)
        this.amagartotesParades=this.amagartotesParades.bind(this)
        this.amagarMenuCapes=this.amagarMenuCapes.bind(this)
        this.amagarState=this.amagarState.bind(this)
        this.amagarLinia=this.amagarLinia.bind(this)
        this.amagarParada=this.amagarParada.bind(this)
        this.canviaCentre=this.canviaCentre.bind(this)
        this.canviaCentreExpedicio=this.canviaCentreExpedicio.bind(this)
        this.mostraLiniaSola=this.mostraLiniaSola.bind(this)
        this.assignaGeolocalitzacio=this.assignaGeolocalitzacio.bind(this)
        this.obteGeolocalitzacio=this.obteGeolocalitzacio.bind(this)
        this.canviEstatFinestra=this.canviEstatFinestra.bind(this)
        this.obrirDetallLinia=this.obrirDetallLinia.bind(this)
        this.obrirDetallExpedicio=this.obrirDetallExpedicio.bind(this)
        this.filtraParades=this.filtraParades.bind(this)
    }

    canviEstatFinestra() {
      this.state.finestraOberta ? this.setState({finestraOberta:false, detallParada:null, detallLinia:null, detallExpedicio:null}) : this.setState({finestraOberta:true});
  }

    
    obrirDetallParada(idParada){
        this.setState({ finestraOberta:true, detallParada:idParada});
    }

    obrirDetallLinia(codiLinia){
      this.setState({ finestraOberta:true, detallLinia:codiLinia});
  }

  obrirDetallExpedicio(idExpedicio){
    this.setState({ finestraOberta:true, detallExpedicio:idExpedicio});
}
    amagarState(state){
      state ? this.setState({ state:false }) : this.setState({ state:true });
  }

    amagartotesParades(){
      let arrayParades=this.state.parades; //Agafem l'state actual i el posem en una variable
      
      if (this.state.paradesVisibles) {
          for (var i=0;i<arrayParades.length;i++) {
            arrayParades[i].visible=false;
          }
      } else {
        for (var i=0;i<arrayParades.length;i++) {
          arrayParades[i].visible=true;
        }
      }
      
      this.setState({ parades: arrayParades }) //Retornem i assignem l'state de nou
      this.state.paradesVisibles ? this.setState({ paradesVisibles :false }) : this.setState({ paradesVisibles :true });
      
    }

    amagarLinia(index){
      
      let arrayLinies=this.state.linies; //Agafem l'state actual i el posem en una variable

      if (arrayLinies[index].visible) { //Modifiquem el valor necessari
        arrayLinies[index].visible=false;
      } else arrayLinies[index].visible=true;

      this.setState({ linies: arrayLinies }) //Retornem i assignem l'state de nou
    }

    amagarParada(index){
      
      let arrayParades=this.state.parades; //Agafem l'state actual i el posem en una variable

      if (arrayParades[index].visible) { //Modifiquem el valor necessari
        arrayParades[index].visible=false;
      } else arrayParades[index].visible=true;

      this.setState({ parades: arrayParades, paradesVisibles: false }) //Retornem i assignem l'state de nou
    }

    amagarMenuCapes(){
      this.state.menuCapesVisible ? this.setState({ menuCapesVisible:false }) : this.setState({ menuCapesVisible:true });
  }

  canviaCentre(index){
    var opcionsACanviar=this.state.opcionsMapa; //Carregem l'objecte actual de l'estat
    var nouCentre = {lat: Number(this.state.parades[index].latitud), lng: Number(this.state.parades[index].longitud)}; //Preparem el nou centre
    opcionsACanviar.centre=nouCentre //Assignem el nou centre al valor
    opcionsACanviar.zoom=10 //PROVISIONAL: per solucionar problema zoom
    this.setState({ opcionsMapa: opcionsACanviar});
    opcionsACanviar.zoom=15 //Ampliem el mapa a la parada seleccionada.
    this.setState({ opcionsMapa: opcionsACanviar}); //El tornem a passar a l'state
  }

  canviaCentreExpedicio(index){
    var opcionsACanviar=this.state.opcionsMapa; //Carregem l'objecte actual de l'estat
    var nouCentre = {lat: Number(this.state.expedicionsActuals[index].circulaAra.posicioActual.latitud), lng: Number(this.state.expedicionsActuals[index].circulaAra.posicioActual.longitud)}; //Preparem el nou centre
    opcionsACanviar.centre=nouCentre //Assignem el nou centre al valor
    opcionsACanviar.zoom=10 //PROVISIONAL: per solucionar problema zoom
    this.setState({ opcionsMapa: opcionsACanviar});
    opcionsACanviar.zoom=15 //Ampliem el mapa a la parada seleccionada.
    this.setState({ opcionsMapa: opcionsACanviar}); //El tornem a passar a l'state
  }

//Funciío per mostrar les parades d'una línia sola
  mostraLiniaSola(index){
    //CARREGUEM ELS STATES INICIALS
    let arrayLinies=this.state.linies;
    let arrayParades=this.state.parades;

    //Assignem visible només la línia escollida
    for (var i=0;i<arrayLinies.length;i++) {
      i===index ? arrayLinies[i].visible=true : arrayLinies[i].visible=false
    }


    //Assignem visible a les parades de la línia

    for (var i=0;i<arrayParades.length;i++) {
      var trobat=false;
      var k=0;

      while(!trobat&&k<arrayLinies[index].parades.length) {
        if (arrayLinies[index].parades[k].id===arrayParades[i].id) { //Si les id d'una parada i de la llista de parades de la linia coincideixen és que forma part de la linia
          arrayParades[i].visible=true;
          trobat=true;
        } else arrayParades[i].visible=false;
        k++;
      }
    }

    //assignem els nous STATES

    this.setState({ parades: arrayParades, linies: arrayLinies, paradesVisibles: false })

  }

  assignaGeolocalitzacio(position){
    var pos = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }
    
    //Actualitzem la marca de la geolocalització
    var geolocalitzacio = {
      visible: true,
      ubicacio: pos
    }
    //Assignem un nou centre
    var opcionsMapa=this.state.opcionsMapa;
    opcionsMapa.centre=pos;

    this.setState({geolocalitzacio, opcionsMapa});
  }


  obteGeolocalitzacio(){ //Extret de https://developers.google.com/maps/documentation/javascript/geolocation#try-it-yourself i https://developer.mozilla.org/es/docs/Web/API/Geolocation/getCurrentPosition
    if (navigator.geolocation) {
      
      navigator.geolocation.getCurrentPosition((position)=>this.assignaGeolocalitzacio(position));

    } else {
      // Browser doesn't support Geolocation
      
    }   

  }

  //FILTRATGE PARADES
    filtraParades(e) {
    var parades = this.state.totesParades;
    var valor = e.target.value.toLowerCase();
      var paradesFiltrades = parades.filter((parada) => parada.nom.toLowerCase().includes(valor)); //Comparem sempre en mínuscules
      this.setState({parades:paradesFiltrades})
  }


  //FUNCIONS CARREGAR ELEMENTS STATE

  carregaParades(){
    API.get(`parada/?ordre=nom`)
      .then(res => {
        const parades = res.data.llistat;
        this.setState({ parades, totesParades:parades});
    })
  }

  carregaLinies(){
    API.get(`linia/`)
    .then(res => {
      const linies = res.data.llistat;
      
      this.interval = setInterval(function() {
        this.setState({ linies, generaInterficie: true });
        }.bind(this), 2000) 
    })
  }

  carregaExpedicionsActuals(){
    API.get(`expedicio/?circulaAra`)
      .then(res => {
        const expedicionsActuals = res.data.llistat;
        this.setState({ expedicionsActuals }); 
      })
  }

      componentDidMount() {
      
        this.carregaParades();
        this.carregaLinies();
        this.carregaExpedicionsActuals();

        this.interval = setInterval(function() {
          this.carregaExpedicionsActuals();
        }.bind(this), 5000)
            
        }

        componentWillUnmount() {
          clearInterval(this.interval);
        }

    render() {
        document.title = "Mapa de la mobilitat al Berguedà - Mobilitat Berguedà";
        return (
            <div>
                
                {!this.state.generaInterficie && //Si no ha carregat les dades
                   <Carregant/>
                }
              {this.state.generaInterficie && //Genera la interficie quan ha rebut les dades
                   <div>
                     
                      
                      <div>
                        <Container fluid>
                          <Row>
                            <Col lg><MapaGran
                                       geolocalitzacio={this.state.geolocalitzacio}
                                       opcionsMapa={this.state.opcionsMapa}
                                       parades={this.state.parades}
                                       linies={this.state.linies}
                                       obrirDetallParada={this.obrirDetallParada}
                                       obrirDetallLinia={this.obrirDetallLinia}
                                       obrirDetallExpedicio={this.obrirDetallExpedicio}
                                       expedicionsActuals={this.state.expedicionsActuals}
                                       paradesVisibles={this.state.paradesVisibles}/>
                                       
                                        {!this.state.menuCapesVisible && //Mostra si el menú no està visible
                                            <Button variant="success" onClick={this.amagarMenuCapes} className="botoMenuCapes"><FontAwesomeIcon icon="layer-group" color="#FFFFFF"/></Button>
                                        }

                                        <Button variant="primary" onClick={this.obteGeolocalitzacio} className="botoMenuCapes" style={{left:"15px"}}><FontAwesomeIcon icon="crosshairs" color="#FFFFFF"/></Button>
                                       
                                       </Col>

                            
                            
                            {/* //Mostra si el menú està visible */}
                            {this.state.menuCapesVisible && <Col lg="3">
                              <CapesMapa
                                linies={this.state.linies}
                                canviaCentre={this.canviaCentre}
                                canviaCentreExpedicio={this.canviaCentreExpedicio}
                                parades={this.state.parades}
                                amagarParades={this.amagartotesParades}
                                amagarParada={this.amagarParada}
                                amagarLinia={this.amagarLinia}
                                amagarMenuCapes={this.amagarMenuCapes}
                                amagarState={this.amagarState}
                                mostraLiniaSola={this.mostraLiniaSola}
                                paradesVisibles={this.state.paradesVisibles}
                                obteGeolocalitzacio={this.obteGeolocalitzacio}
                                filtraParades={this.filtraParades}
                                expedicionsActuals={this.state.expedicionsActuals}

                                />
                            </Col>}
                          </Row>
                        </Container>
                      </div>
                    

                   

                    {this.state.finestraOberta && //FINESTRA FLOTANT
                            <div>
                                <div className="fonsFinestraFlotant" onClick={this.canviEstatFinestra}></div>
                                <div className="finestraFlotant finestraFlotantAvisosUsuari">
                                    <div className="botoTancar"><FontAwesomeIcon icon="times-square" size="2x" className="maBoto" color="#336122" onClick={this.canviEstatFinestra}/></div>
                                    <div className="contingutFinestra">
                                      {this.state.detallParada && <Parada idParada={this.state.detallParada}/>}
                                      {this.state.detallLinia && <Linia codiLinia={this.state.detallLinia}/>}
                                      {this.state.detallExpedicio && <Expedicio idExpedicio={this.state.detallExpedicio}/>}
                                      </div>

                                </div>
                            
                            </div>
                            }

                   {/* <DetallParada idParada={this.state.detallParada} parades={this.state.parades} amagarParades={this.amagartotesParades} obrirDetallParada={this.obrirDetallParada}/> */}
                   </div>
                }
            </div>

            
        )
    }

}

export default Mapa