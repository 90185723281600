import React, {Component} from 'react';
import AfegirParada from './parades/AfegirParada';
import {Button} from 'react-bootstrap';

import API from 'Api';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EsborrarParada from './parades/EsborrarParada';
import EditarParada from './parades/EditarParada';

class AdminParades extends Component {
    
    constructor(){
        super()
        this.canviAfegirParada=this.canviAfegirParada.bind(this)
        this.carregaParades=this.carregaParades.bind(this)
        this.obreEsborrarParada=this.obreEsborrarParada.bind(this)
        this.tancaFinestra=this.tancaFinestra.bind(this)
      }

      carregaParades() {    
        API.get(`parada/?ordre=nom`)
          .then(res => {
            if (res.status===200){
                this.setState({parades:res.data.llistat})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      } 

      state = {
        afegirParada:false,
        parades: [],
        finestraEsborrar: false,
        finestraEditar: false,
        paradaEditar: null
    }
    
    canviAfegirParada() {
        this.state.afegirParada ? this.setState({afegirParada:false}) : this.setState({afegirParada:true});
    }

    obreEsborrarParada(parada) {
        this.setState({finestraEsborrar:true, paradaEditar:parada});
     }
 
     obreEditarParada(parada) {
         this.setState({finestraEditar:true, paradaEditar:parada});
      }
 
 
     tancaFinestra() {
         this.setState({finestraEsborrar:false, finestraEditar:false});
         this.carregaParades();
      }

      componentDidMount() {
        this.carregaParades();

        if (this.props.match&&this.props.match.params.opcions==="afegir") this.canviAfegirParada(); //Si arribem amb l'opció d'afegir, s'obre el desplegable
      }

      componentDidUpdate(prevProps) { //Si es canvia la url que rebem a traves de match, es torna a cridar al backend.
        if (this.props.match&&prevProps.match.url !== this.props.match.url) {
            this.props.match&&this.props.match.params.opcions==="afegir" ? this.setState({afegirParada:true}) : this.setState({afegirParada:false});
        }
      }

      generaTaula(parada, index) {
        return (
            <div className="divTableRow liniaResposta noSelect" key={"linia"+index}> 
                <div className="divTableCell">
                <Link to={"/parada/"+parada.id}>{parada.nom}</Link>
                 </div>
                 <div className="divTableCell">
                 <FontAwesomeIcon icon="pencil-alt" className="maBoto" color="#336122" onClick={()=>this.obreEditarParada(parada)}/>
                 </div>
                 <div className="divTableCell">
                 <FontAwesomeIcon icon="times" className="maBoto" color="#336122" onClick={()=>this.obreEsborrarParada(parada)}/>
                 </div>     
        </div>

        )
    }
    
    render() {
        return (
            <div className="modulInferior">
                <h2>Parades</h2>
                <Button variant="secondary" onClick={this.canviAfegirParada}>{this.state.afegirParada ? "Tancar" : "Afegir"}</Button>
                {this.state.afegirParada && <AfegirParada token={this.props.token} refrescaParades={this.carregaParades}/>}
                
                <div className="divTable taulaElements">
                    <h3>Berguedà</h3>
                    <div className="divTableBody">                       
                        {this.state.parades.map((parada,index)=> parada.esBergueda && this.generaTaula(parada, index))}
                    </div>
                    <h3>Altres</h3>
                    <div className="divTableBody">                       
                        {this.state.parades.map((parada,index)=> !parada.esBergueda && this.generaTaula(parada, index))}
                    </div>
                </div>

                {this.state.finestraEsborrar && <EsborrarParada tancar={this.tancaFinestra} parada={this.state.paradaEditar} token={this.props.token}/>}
                {this.state.finestraEditar && <EditarParada tancar={this.tancaFinestra} parada={this.state.paradaEditar} token={this.props.token}/>}

            </div>
        )
    }
}

export default AdminParades;