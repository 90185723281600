import React, {Component} from 'react';

import API from 'Api';
import Carregant from './estructura/Carregant';
import {Container, Row, Col} from 'react-bootstrap';
import DetallsLinia from './linies/DetallsLinia';
import MapaLinia from './linies/MapaLinia';
import RecorregutLinia from './linies/RecorregutLinia';
import HorarisLinia from './linies/HorarisLinia';
import LlistaTotesLinies from './linies/LlistaTotesLinies';
import AvisosGrup from './avisos/AvisosGrup';
import Parada from './Parada';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TransportDemanda from './linies/TransportDemanda';
import Arxius from './linies/Arxius';



class Linia extends Component {
  constructor(){
    super()
    this.obteDades=this.obteDades.bind(this)
    this.canviEstatFinestra=this.canviEstatFinestra.bind(this)
    this.obreFinestraParada=this.obreFinestraParada.bind(this)
  }
  
  obteDades() {
    this.setState({generaInterficie: false }); //Abans de carregar dades, posem la careta de Carregant.
    this.noID=false;
    var id = 0;
    if (this.props.match) {
      id = Number(this.props.match.params.id);
    }

    if (this.props.codiLinia) {
      id = Number(this.props.codiLinia);
    }

    const baseURL = `linia/?codi=` + id;

    API.get(baseURL)
    .then(res => {
        if (res.data.status==="OK"){
            const dades = res.data.info_linia;
            const parades = res.data.parades;
            const avisos = res.data.avisos;
            const arxius = res.data.arxius;
            const polilinia_codificada = res.data.polilinia_codificada;
            document.title = dades.sigles + ": " + dades.nom + " Horaris Bus Linia " + dades.nom + " - Mobilitat Berguedà";
            this.setState({ dades, parades, avisos, polilinia_codificada, arxius});
        }
      const status = res.data.status;
      this.setState({status, generaInterficie: true });
      
    })
  }


    state = {
        dades: null,
        parades: null,
        avisos: null,
        polilinia_codificada: {},
        status:"ERROR",
        generaInterficie: false,
        finestraOberta: false,
        idParada: null
      }
    
      canviEstatFinestra() {
        this.state.finestraOberta ? this.setState({finestraOberta:false}) : this.setState({finestraOberta:true});
    }

    obreFinestraParada(idParada) {
     this.setState({finestraOberta:true, idParada});
  }
      
      
  componentDidUpdate(prevProps) { //Si es canvia la url que rebem a traves de match, es torna a cridar al backend.
        if (this.props.match&&prevProps.match.url !== this.props.match.url) {
          this.setState({finestraOberta:false})
          this.obteDades();
        }
      }

  componentDidMount() {
    this.obteDades();
  }

  render() {
    console.log(this.state)
    if (!this.props.match&&!this.props.codiLinia) {
        return (<div><Container><LlistaTotesLinies/></Container></div>)
      } else {

        return (
          <div>
        {!this.state.generaInterficie && //Si no ha carregat les dades
                    <div>
                       <Carregant/>
                    </div>
                }
              {this.state.generaInterficie && //Genera la interficie quan ha rebut les dades
                  <div>
                  { this.state.status==="OK" &&
                  <div className="modulInferior">
                    <Container>
                        <DetallsLinia key={"dadesCodiID"+this.state.dades.codi} dades={this.state.dades}/>
                    </Container>
                    <MapaLinia linia={this.state.polilinia_codificada} color={this.state.dades.color} parades={this.state.parades}/>
                        <div className="modulInferior">
                            <Container>
                              {this.state.dades.transport_demanda && <TransportDemanda/> }
                                <Row>
                                    <Col md><RecorregutLinia color={this.state.dades.color} parades={this.state.parades} obreFinestraParada={this.obreFinestraParada}/></Col>
                                    <Col md  className="separacioColumna">
                                      {this.state.arxius && <Arxius arxius={this.state.arxius}/> }
                                     <AvisosGrup dades={this.state.avisos}/>
                                    </Col>
                                </Row>
                         
                                <div className="modulInferior">
                                  <HorarisLinia codiLinia={this.state.dades.codi} parades={this.state.parades}/>
                                </div>
                            </Container>
                        </div>
                    </div>
                    }

                    { this.state.status==="ERROR" &&

                    <div><Container><LlistaTotesLinies/></Container></div>

                    }

                    
                    {this.state.finestraOberta && //FINESTRA FLOTANT
                            <div>
                                <div className="fonsFinestraFlotant" onClick={this.canviEstatFinestra}></div>
                                <div className="finestraFlotant finestraFlotantAvisosUsuari">
                                    <div className="botoTancar"><FontAwesomeIcon icon="times-square" size="2x" className="maBoto" color="#336122" onClick={this.canviEstatFinestra}/></div>
                                    <div className="contingutFinestra"><Parada idParada={this.state.idParada}/></div>

                                </div>
                            
                            </div>
                            }
                  </div>

                }
        </div>
        )
      }
    }
}
export default Linia;