import React, {Component} from 'react';
import {Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

//Mòdul TargetaAvís
//Props que rep:
// -tipusVora: indica quin estil de vora necessita
//      [primary, secondary, success, danger, warning, info, dark, light]
// -tipus: Indica tipus de l'avis
// -titol: Títol de l'avis
// -descripcio: text amb la descripció de l'avis

class TargetaAvis extends Component {
    
    constructor(){
        super()
        this.canviFormatData=this.canviFormatData.bind(this)
        this.generaDadesObjecte=this.generaDadesObjecte.bind(this)
        this.dibuixaPastillaLinia=this.dibuixaPastillaLinia.bind(this)
      }

    canviFormatData(novaData){ //Format de data anglès a català
        novaData = new Date(novaData);
        var dd = String(novaData.getDate()).padStart(2, '0');
        var mm = String(novaData.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = novaData.getFullYear();
      
        var dataFormat = dd + '-' + mm + '-' + yyyy;
    
        return dataFormat;
    }

    generaDadesObjecte(tipusObjecte, objecte){ //Genera la resposta si rep objecte
        if (tipusObjecte==="expedicio"){
            return <span></span>
        } else return <span></span>
    }

    dibuixaPastillaLinia() {
        return <div style={{backgroundColor: this.props.dades.objecte.color}} className="siglesLinia">{this.props.dades.objecte.sigles}</div>;
    }

    nomTipusAvis(idTipusAvis) {
       
        //De moment, retorna una icona genèrica
        return  <FontAwesomeIcon icon="exclamation-triangle" color="#000000"/>
        
        // if (this.props.llistaTipusAvis.length>0) {
        //     var tipusAvis = this.props.llistaTipusAvis.filter((avis)=>avis.id===idTipusAvis);
        // return tipusAvis[0].nom
        // } return idTipusAvis
        


    }
    
    render() {
        let tipusVora = "light"; //Si no s'ha definit tipus de vora, agafa color per defecte

        if (this.props.tipusVora) {
            tipusVora = this.props.tipusVora
        } 

        return (
              <Card style={{ width: '100%' }}>
                <Card.Header className="avisHeader">{this.nomTipusAvis(this.props.dades.tipusAvis)}
                  {this.props.dades.tipusObjecte==='linia'&&this.props.dades.objecte ? this.dibuixaPastillaLinia() : <span> <FontAwesomeIcon icon="bus" /> </span>}
                 {this.props.dades.objecte && this.props.dades.objecte.nom}</Card.Header>
                <Card.Body>
                <Card.Title>{this.props.dades.titol}</Card.Title>
                {this.props.dades.text && <Card.Text>{this.props.dades.text}</Card.Text>}
                </Card.Body>
                <Card.Footer>{this.canviFormatData(this.props.dades.dataInici)} {this.props.dades.dataFinal&&this.props.dades.dataFinal!=this.props.dades.dataInici && <span> - {this.canviFormatData(this.props.dades.dataFinal)}</span>}</Card.Footer>
            </Card>
        )
      }
}

export default TargetaAvis