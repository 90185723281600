import React, {Component} from 'react';
import { LinkContainer } from "react-router-bootstrap";
import TransportDemanda from '../linies/TransportDemanda';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class DetallResultat extends Component {

render() {
    const itinerariVisible=this.props.itinerariVisible;

    return (
    this.props.itinerariTriat &&
        <div className="detallResultat">
            <div className="detallItinerari">
             {this.props.itineraris[itinerariVisible].map((itinerari, index)=>

            <div className="detallExpedicio">
                {itinerari.durada_transbord && <div className="missatgeTransbord"><FontAwesomeIcon icon="clock" /> Espera a <b>{itinerari.parades.origen}</b> durant {itinerari.durada_transbord.h!==0 && <span> 
                    
                {itinerari.durada_transbord.h!==1 ? <span>{itinerari.durada_transbord.h} hores i</span> : <span>{itinerari.durada_transbord.h} hora i</span> }
                    
                     </span>}
                     
                {itinerari.durada_transbord.m!==1 ? <span> {itinerari.durada_transbord.m} minuts.</span> : <span> {itinerari.durada_transbord.m} minut.</span> }

                </div>}
                <div className="colorliniaExpedicio" style={{backgroundColor: itinerari.linia.color}}></div>
                <div className="dadesExpedicio">

                    <div className="dadesOrigenExpedicio childInlineBlock">
                        <div className="horaOrigenExpedicio">{itinerari.horesPas.paradaOrigen}</div>
                        <LinkContainer to={"/parada/"+itinerari.parades.origenID}><div className="paradaOrigenExpedicio maBoto">{itinerari.parades.origen}</div></LinkContainer>
                        {itinerari.parades.origenID==="13"&&<div className="notaParadaDiferent">Parada: Pg. de Gràcia / Diputació (davant pg. de Gràcia, 36). <a href="https://goo.gl/maps/C4YSsATMh7zZf2fz7" target="_blank">Mapa <FontAwesomeIcon icon="external-link"/></a></div>}
                    </div>

                    <div className="dadesRecorregutExpedicio">
                            <div className="childInlineBlock">
                            <LinkContainer to={"/linia/"+itinerari.linia.codi}><div><div style={{backgroundColor: itinerari.linia.color}} className="siglesLinia maBoto">
                                {itinerari.linia.sigles}
                                </div></div></LinkContainer>
                            <LinkContainer to={"/expedicio/"+itinerari.expedicio.id}><div className="dadesServeiExpedicio maBoto">Servei de {itinerari.expedicio.paradaInici} a {itinerari.expedicio.paradaFinal}</div>
                        </LinkContainer></div>
                        <LinkContainer to={"/expedicio/"+itinerari.expedicio.id}><div className="dadesDireccioExpedicio maBoto">Direcció {itinerari.expedicio.paradaFinal}</div></LinkContainer>
                        <div className="duradaItinerari">
                        <p><FontAwesomeIcon icon="clock" />
                        {itinerari.durada.h!==0 && <span> {itinerari.durada.h}h</span>}
                        <span> {itinerari.durada.m}m </span></p>
                        
                        </div>    
                        {itinerari.linia.transport_demanda && <TransportDemanda/>
                        }
                        <div className="paradesRecorregut">
                            <div className="linkParades">
                            {itinerari.num_parades_recorregut!==0 &&
                                    <p> 
                                        <span><FontAwesomeIcon icon="arrow-circle-down" /> </span>
                                        {itinerari.num_parades_recorregut!==1 ? <span>{itinerari.num_parades_recorregut} parades</span> : <span>{itinerari.num_parades_recorregut} parada</span>
                                        }
                                        
                                    </p>
                            }

                            </div>

                            <div className="llistaParadesRecorregut">

                                {itinerari.parades_recorregut.map((parada) =>
                                    <div className="paradaRecorregut">
                                        <p><span className="horaPas">{parada.horaPas}</span> {parada.nom}</p>
                                    </div> 
                                
                                )}    

                            </div>
                        </div>
                    
                    </div>

                    <div className="dadesDestinacioExpedicio childInlineBlock">
                        <div className="horaDestinacioExpedicio">{itinerari.horesPas.paradaDestinacio}</div>
                        <LinkContainer to={"/parada/"+itinerari.parades.finalID}><div className="paradaDestinacioExpedicio maBoto">{itinerari.parades.final}</div></LinkContainer>
                        {itinerari.parades.finalID==="13"&&<div className="notaParadaDiferent">Parada: Gran Via / Balmes. <a href="https://goo.gl/maps/8jJqyHWwn6f2nKXX6" target="_blank">Mapa <FontAwesomeIcon icon="external-link"/></a></div>}
                    </div>

                </div>
            </div>    
            )}

        </div>
            
            {/* <div className="quadre">
                <h4>Avisos</h4>
                <p>No hi ha avisos destacats</p>
            </div> */}
        </div>
    
    )
}



}

export default DetallResultat