import React, {Component} from 'react';
import {Card, OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { LinkContainer } from "react-router-bootstrap";

class ProximesSortides extends Component {
    
generaResultat(expedicio, index) {
        return (
            <LinkContainer to={"/expedicio/"+expedicio.id}>
            <div className="divTableRow liniaResposta maBoto" key={"expedicio"+index}> 
                <div className="divTableCell">
                    <div style={{backgroundColor: expedicio.linia.color}} className="siglesLinia">
                        {expedicio.linia.sigles}
                    </div>
                </div>
                <div className="divTableCell horaPas horaPasExpedicio">
                {expedicio.horaPas}
                 </div>
                 <div className="divTableCell direccio direccioExpedicio">
                    {expedicio.direccio} <span>
                {expedicio.linia.transport_demanda && <OverlayTrigger placement="top"  overlay={<Tooltip> Transport a la demanda</Tooltip>}>
                    <Badge variant="secondary">TD</Badge>
                        </OverlayTrigger>              
                }</span>                
                </div>
                {expedicio.numAvisosUsuari>0 &&
                
                <div className="divTableCell direccio direccioExpedicio">
                    <span className="notificacioAvisos"><FontAwesomeIcon icon="exclamation-triangle" color="#B364A9"/> {expedicio.numAvisosUsuari}</span>
                </div>  }   
        </div>
        
        </LinkContainer>

        )
    }



render(){
    return(
        <div className="modulPagina">
            <Card style={{ width: '100%' }}>
                <Card.Body>
                    <h3>Properes Sortides</h3>
                    
                    <p>Actualitzat a les {this.props.proximesSortides.hora} <span className="botoRefrescar boto maBoto" onClick={()=>this.props.refrescaDades()}><FontAwesomeIcon icon="sync" /></span></p>
                    {this.props.proximesSortides.status==="OK" && //Si rebem resultats correctes
                        
                        this.props.proximesSortides.expedicions.map((expedicio,index)=>this.generaResultat(expedicio, index))
                        
                    }

                    {this.props.proximesSortides.status==="ERROR" && //Si no queden expedicions
                        <p>No queden sortides pel dia d'avui</p>
                    }   
                </Card.Body>
            </Card>
        </div>
    )
}
}

export default ProximesSortides