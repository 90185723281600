import React, {Component} from 'react';

import API from 'Api';
import DadesItinerari from './resultats/DadesItinerari';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import AcordioResultats from './resultats/AcordioResultats';
import MapaSuperior from './resultats/MapaSuperior';
import Carregant from './estructura/Carregant';
import Cercador from './Cercador';
import CercadorWidget from './estructura/CercadorWidget';
import { scroller } from "react-scroll";
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ObresRonda from './estructura/ObresRonda';
import DetallPreu from './resultats/DetallPreu';

class Resultats extends Component {

    state = {
        resposta: {
            dadesItinerari: {
                origen: {
                    nom:"A"
                },
                destinacio: {
                    nom:"B"
                }
            }
        },
        itinerariAParadaOrigen:null,
        itinerariDesParadaDestinacio:null,
        itinerariVisible:0,
        itinerariTriat:false,
        generaInterficie: false,
        error: false,
        missatgeAxios: null
      }

      constructor(){
        super()
        this.handleSetItinerariVisible=this.handleSetItinerariVisible.bind(this)
        this.obteDades=this.obteDades.bind(this)
        this.setTitol=this.setTitol.bind(this)
    }

    handleSetItinerariVisible(index){
        //event.preventDefault();
        // const idItinerari = event.target.getAttribute("index");
        const idItinerari = index;
        this.scrollToResult();
        this.setState({ itinerariVisible: idItinerari, itinerariTriat:true});
    }

    setTitol() {
        document.title = "Itinerari entre " + this.state.resposta.dadesItinerari.origen.nom +" i " + this.state.resposta.dadesItinerari.destinacio.nom + " - Mobilitat Berguedà" ;
    }     

    obteDades() {
      this.setState({generaInterficie:false, error:false, itinerariVisible:0, itinerariTriat:false})
      const origen=this.props.itinerari.origen;
      const destinacio=this.props.itinerari.destinacio;
      const data=this.props.itinerari.data;
      const hora=this.props.itinerari.hora;
      const baseURL = `itinerari/generaItinerariv5.php?origen=` + origen + `&destinacio=` + destinacio + `&data=` + data + `&hora=` + hora;
  
      //origen=Biblioteca de Gironella&destinacio=Dickens, Berga&data=09-11-2019&hora=10:25
      API.get(baseURL)
        .then(res => {
          const resposta = res.data;
          this.setState({ resposta, generaInterficie: true });
          resposta.itinerariAParadaOrigen && this.setState ({itinerariAParadaOrigen: resposta.itinerariAParadaOrigen});
          resposta.itinerariDesParadaDestinacio && this.setState ({itinerariDesParadaDestinacio: resposta.itinerariDesParadaDestinacio});
          this.setTitol();
        }).catch (error => this.setState({error:true, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    }

    mostraDetallItinerari() {
      if (window.innerWidth >= 768) { //Si la finestra té una alçada superior a 768px, es mostra el mapa
          this.setState({itinerariTriat:true});
      } else this.setState({itinerariTriat:false});
  }
  
    componentDidMount() {
      this.obteDades();  
      this.mostraDetallItinerari();      
    }

    componentDidUpdate(prevProps) { //Si es modifica el recorregut
      if (prevProps.itinerari !== this.props.itinerari) {
        this.obteDades();
      }
    }

    scrollToResult = () => {
      scroller.scrollTo("detall_resultat", {
        duration: 1000,
        delay: 100,
        smooth: true,
        offset: -50});
  };

    render() {



  
        //this.setItinerariVisible(2);
        return (
          <div>
              
              {!this.state.generaInterficie&&!this.state.error && //Si no ha carregat les dades
                    <Carregant/>
                }
              {this.state.generaInterficie&&(this.state.resposta.itineraris.status==="OK"||this.state.resposta.itineraris.status==="TRANSBORD") && //Genera la interficie quan ha rebut les dades
                   <div>
                      <Container className="containerDadesItinerari"><DadesItinerari dades={this.state.resposta.dadesItinerari} dataRaw={this.props.itinerari.dataRaw} invertirRecorregut={this.props.invertirRecorregut}/></Container>
                      <MapaSuperior
                       dades={this.state.resposta.dadesItinerari}
                      itinerari={this.state.resposta.itineraris.opcions[this.state.itinerariVisible]}
                      itinerariAParadaOrigen={this.state.itinerariAParadaOrigen}
                      itinerariAParadaDestinacio={this.state.itinerariDesParadaDestinacio}/>
                    {/* <MapaSuperior
                       dades={this.state.resposta.dadesItinerari}
                      itinerari={this.state.resposta.itineraris.opcions[this.state.itinerariVisible]}
                      itinerariAParadaOrigen={() => this.stateitinerariAParadaOrigen && this.state.itinerariAParadaOrigen.routes[0]}
                      itinerariAParadaDestinacio={() => this.state.itinerariDesParadaDestinacio && this.state.itinerariDesParadaDestinacio.routes[0]}/> */}
                    <Container>


                    {/* Especial Obres Ronda Universitat    */}
                     {/* {(this.state.resposta.dadesItinerari.paradaOrigen.id==="13"||this.state.resposta.dadesItinerari.paradaOrigen.id==="14") &&  <ObresRonda estat="origen"/>}
                     {(this.state.resposta.dadesItinerari.paradaDestinacio.id==="13"||this.state.resposta.dadesItinerari.paradaDestinacio.id==="14") &&  <ObresRonda estat="destinacio"/>} */}

                     
                    {/* <span className="maBoto modificaItinerariLink"><CercadorWidget canviOrigen={this.props.canviOrigen} canviDestinacio={this.props.canviDestinacio} canviHora={this.props.canviHora} canviData={this.props.canviData} itinerari={this.props.itinerari} assignarItinerari={this.props.assignarItinerari} titol="Modifica itinerari"/></span> */}
                    <AcordioResultats
                    name="detall1" 
                        itinerariAParadaOrigen={this.state.itinerariAParadaOrigen}
                        itinerariAParadaDestinacio={this.state.itinerariDesParadaDestinacio}
                        dades={this.state.resposta.dadesItinerari}
                        itineraris={this.state.resposta.itineraris.opcions}
                        itinerariVisible={this.state.itinerariVisible}
                        setItinerariVisible={this.handleSetItinerariVisible}
                        itinerariTriat = {this.state.itinerariTriat}
                         />
                    {this.state.resposta.dadesItinerari.preu && <DetallPreu preu={this.state.resposta.dadesItinerari.preu}/>}

                    {this.state.resposta.dadesItinerari.hora!=="05:00" && <Link to={"#"} onClick={()=>this.props.canviHora("2020-09-27T03:00:42.188Z")}><FontAwesomeIcon icon="calendar-check" /> Veure tots els horaris del dia</Link>}  
                    {/* La data anterior és ficticia perquè l'ordre de canviar l'hora només afecta a l'hora  */}
                    </Container>
                       
                   </div>
                }

                  {this.state.generaInterficie&&this.state.resposta.itineraris.status==="ERROR1" && //Genera la interficie quan ha rebut les dades
                   <div>
                     <Container className="containerDadesItinerari">
                       <DadesItinerari dades={this.state.resposta.dadesItinerari} dataRaw={this.props.itinerari.dataRaw} invertirRecorregut={this.props.invertirRecorregut}/></Container>

                     <Container>
                
                    <p>No s'han trobat itineraris per la ruta i dies seleccionats.</p>
                    <Link to={"#"} onClick={()=>this.props.canviHora("2020-09-27T03:00:42.188Z")}><FontAwesomeIcon icon="calendar-check" /> Veure tots els horaris del dia</Link>  
                    {/* La data anterior és ficticia perquè l'ordre de canviar l'hora només afecta a l'hora  */}
                    </Container>
                       
                   </div>
                }

                   {this.state.generaInterficie&&(!this.state.resposta.dadesItinerari.paradaOrigen.id||!this.state.resposta.dadesItinerari.paradaDestinacio.id) && //Genera la interficie quan ha rebut les dades
                   <div>
                    <Container className="containerDadesItinerari"><DadesItinerari dades={this.state.resposta.dadesItinerari} dataRaw={this.props.itinerari.dataRaw} invertirRecorregut={this.props.invertirRecorregut}/></Container>
                
                    <Container>
                    <p>No es pot generar una ruta entre els dos punts escollits</p>
                    
                    </Container>
                       
                   </div>
                }

                  {!this.state.generaInterficie&&this.state.error && //Genera la interficie quan ha rebut les dades
                   <div className="modulInferior">
                    <Container >               
                            <Alert variant="danger">{this.state.missatgeAxios}</Alert>
                            
                            <h3>Redefineix la teva cerca</h3>
                            <Cercador canviOrigen={this.props.canviOrigen} canviDestinacio={this.props.canviDestinacio} canviHora={this.props.canviHora} canviData={this.props.canviData} itinerari={this.props.itinerariBuscar} assignarItinerari={this.props.assignarItinerari}/>
                    </Container>
                       
                   </div>
                }
            <br />              
          </div>
        )
      }

}

export default Resultats;