import React, {Component} from 'react';


import { compose, withProps, withStateHandlers } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"


const MapaGoogle = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCYEQJ7nw4waLh4o_IvlQHDCzGVQQvlOOI&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `250px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: Number(props.parada.latitud), lng: Number(props.parada.longitud) }}

    >
            <Marker
              position={{ lat: Number(props.parada.latitud), lng: Number(props.parada.longitud)}}
              title={props.parada.nom}
              key={'paradaID'+props.parada.id}
              icon={{url:"https://mobilitat.bergueda.cat/img/icona_parada20.png"}}
              />    
    </GoogleMap>
  )



class MapaParada extends Component {
render(){
    return (
            <div><MapaGoogle parada={this.props.dades}/></div>
        )
    }
    }
    
 export default MapaParada

