import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {Card, OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';
import Moment from 'react-moment';
require('moment/locale/ca');

class DetallPreu extends Component {

//Hem de rebre per props.preu
//preu i abonament

render() {     

        return (

            <div className="detallPreu ">
                <Card style={{ width: '100%' }}>
                    <Card.Body>
                        <Card.Title> <FontAwesomeIcon icon="money-bill-wave" /> <b>Cost del trajecte</b></Card.Title>
                        <Card.Text>
                            <p>Bitllet senzill: <b>{this.props.preu.preu.toFixed(2).toString().replace(/\./g, ',')} €</b></p>
                            {this.props.preu.abonament && 
                                <p>Abonament 10 viatges: <b>{this.props.preu.abonament.toFixed(2).toString().replace(/\./g, ',')} €</b> ({(this.props.preu.abonament/10).toFixed(2).toString().replace(/\./g, ',')} € per viatge)</p>
                            }
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-muted text-petit">
                        Última actualització: <Moment locale="ca" format="DD/MM/YYYY">{this.props.preu.actualitzacio}</Moment>
                    </Card.Footer>
                </Card>
            </div>
        )
    }

}
export default DetallPreu