import React, {Component} from 'react';
import { withRouter } from 'react-router-dom'
import {Button, Form, Row, Col} from 'react-bootstrap';
import {DateTime} from 'react-datetime-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Typeahead} from 'react-bootstrap-typeahead';

import API from 'Api';
require('moment/locale/ca');

//CERCADOR

const Buscar = withRouter(({ history }) => (
    <Button variant="success" size="lg" block onClick={() => {
      history.push('/resultats')
       
       
       }}>
            <FontAwesomeIcon icon="search" />
        </Button>
  ))

class Cercador extends Component {
    state = {
        llistats: []
      }


constructor(){
        super()
        this.handleInputChangeOrigen=this.handleInputChangeOrigen.bind(this)
        this.handleInputChangeDestinacio=this.handleInputChangeDestinacio.bind(this)
        this.handleChangeOrigen=this.handleChangeOrigen.bind(this)
        this.handleChangeDestinacio=this.handleChangeDestinacio.bind(this)
        this.handleChangeData=this.handleChangeData.bind(this)
        this.handleChangeHora=this.handleChangeHora.bind(this)
    }

    handleInputChangeOrigen(input, e) {
        var lloc = input;
      //console.log(lloc);
      this.props.canviOrigen(lloc);
      }

      handleInputChangeDestinacio(input, e) {
        var lloc = input;
      //console.log(lloc);
      this.props.canviDestinacio(lloc);
      }
    
      handleChangeOrigen(selectedOptions) {
        this.props.canviOrigen(selectedOptions[0]);     
      }

      handleChangeDestinacio(selectedOptions) {
        this.props.canviDestinacio(selectedOptions[0]);     
      }

      handleChangeData(value) {
        this.props.canviData(value);     
      }

      handleChangeHora(value) {
        this.props.canviHora(value);     
      }



  componentDidMount() {
    API.get(`parada/`)
      .then(res => {
        const llistats = res.data.llistat;
        this.setState({ llistats });
        
      })
  }

  afegirColetillaBCNCentre(options, tipus) { //tipus = origen, destinacio
    var indexOpcio;
    var nouArray = [...options];
    
    if (nouArray&&nouArray.length>0) {
      nouArray.map((opcio,index) => {
        if (opcio==="Barcelona - Centre") indexOpcio=index;     
      })

      if (tipus==="origen") nouArray[indexOpcio]=nouArray[indexOpcio]+" (Passeig de Gràcia / Diputació)"
      if (tipus==="destinacio") nouArray[indexOpcio]=nouArray[indexOpcio]+" (Gran Via / Balmes)"

      nouArray.map((opcio,index) => {
        if (opcio==="Barcelona - FGC Bonanova"&&tipus==="origen") nouArray.splice(index,1);
      })

      nouArray.map((opcio,index) => {
        if (opcio==="Barcelona - Francesc Macià"&&tipus==="origen") nouArray.splice(index,1);
      })
      
      nouArray.map((opcio,index) => {
        if (opcio==="Barcelona - Palau Reial"&&tipus==="destinacio") nouArray.splice(index,1);
      })

      nouArray.map((opcio,index) => {
        if (opcio==="Barcelona - Hospital Clínic"&&tipus==="destinacio") nouArray.splice(index,1);
      })
     
     
      nouArray.map((opcio,index) => {
        if (opcio==="Avià (Ateneu)"&&tipus==="destinacio") nouArray.splice(index,1);
      })

    }
      

    return nouArray;
  }



    render(){

        var options = []
        this.state.llistats.map((parada, index)=> options.push(parada.nom))

        return (
        <div className="caixaCercador">
              <Form>
                <Form.Row>
                    <Col md>
                    <Typeahead
                        labelKey="origen"
                        options={this.afegirColetillaBCNCentre(options, "origen")}
                        emptyLabel
                        placeholder={this.props.itinerari.origen}
                        onInputChange={this.handleInputChangeOrigen}
                        //onInputChange={()=>this.props.canviOrigen("Bífidus Produccions")}
                        onChange={this.handleChangeOrigen}
                        />
                    </Col>
                    <Col md>
                    <Typeahead
                        labelKey="destinacio"
                        options={this.afegirColetillaBCNCentre(options, "destinacio")}
                        emptyLabel
                        placeholder={this.props.itinerari.destinacio}
                        onInputChange={this.handleInputChangeDestinacio}
                        onChange={this.handleChangeDestinacio}
                        />
                    </Col>
                </Form.Row>
                <br/>
                <Form.Row>
                     <Col md>
                     <DateTime pickerOptions={{format:"DD-MM-YYYY", locale:"ca"}} placeholder={this.props.itinerari.data} onChange={this.handleChangeData} minDate={()=>new Date()}/>
                    </Col>
                    <Col md>
                    <DateTime pickerOptions={{format:"HH:mm"}} placeholder={this.props.itinerari.hora} onChange={this.handleChangeHora}/>
                    </Col>
                    <Col md>
                    {(this.props.itinerari.origen==="Origen"||this.props.itinerari.destinacio==="Destinació") && 
                      <Button variant="secondary" size="lg" block disabled>
                        <FontAwesomeIcon icon="search" />
                        </Button>
                    }

                    {(this.props.itinerari.origen!=="Origen"&&this.props.itinerari.destinacio!=="Destinació") && 
                       <div>
                        {this.props.tancaFinestra && 
                          <div onClick={this.props.tancaFinestra}><Buscar/></div>
                          }
                          {!this.props.tancaFinestra && 
                          <div onClick={this.props.assignarItinerari}><Buscar/></div>
                          }
                        </div>
                    }
                    
                   
                    </Col>
                </Form.Row>
                </Form>  



        </div>)

    }

}

export default Cercador