import React, { Component } from "react";

import API from "Api";
import LiniesParada from "../parades/LiniesParada";
import Carregant from "../estructura/Carregant";
import ModulLleure from "Components/portada/ModulLleure";

class LlistaTotesLinies extends Component {
  state = {
    linies: [{}],
    generaInterficie: false,
  };

  obteDades() {
    const baseURL = `linia/`;
    API.get(baseURL).then((res) => {
      const linies = res.data.llistat;
      this.setState({ linies });
      const status = res.data.status;
      this.setState({ status, generaInterficie: true });
    });
  }

  componentDidMount() {
    this.obteDades();
  }

  render() {
    document.title = "Línies - Mobilitat Berguedà";
    return (
      <div>
        {!this.state.generaInterficie && ( //Si no ha carregat les dades
          <div>
            <Carregant />
          </div>
        )}
        {this.state.generaInterficie && ( //Genera la interficie quan ha rebut les dades
          <div>
            <LiniesParada linies={this.state.linies} />
            {/* <ModulLleure/> */}
          </div>
        )}
      </div>
    );
  }
}

export default LlistaTotesLinies;
