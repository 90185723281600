import React, {Component} from 'react';
import { compose, withProps, withStateHandlers } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"


// Centra el mapa a partir dels punts de la línia
const fitBounds = (map, linia)=> {
  const bounds = new window.google.maps.LatLngBounds();
  linia.map(coords => {
    bounds.extend(coords);
  });
  map.fitBounds(bounds);
};

const MapaGoogle = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCYEQJ7nw4waLh4o_IvlQHDCzGVQQvlOOI&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>

  

  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: props.latCenter, lng: props.lngCenter }}
    ref={(map) => { map && fitBounds(map, props.coordenadesCentre); }} //Centrem el mapa segons els punts rebuts
  >

          

          <Marker
            title={props.dades.paradaOrigen.nom}
            name={'SOMA'}
            position={{lat: Number(props.dades.paradaOrigen.latitud), lng: Number(props.dades.paradaOrigen.longitud)}}
            icon={{
              url: "img/icona_origen_itinerari.png",
              anchor: new window.google.maps.Point(8,8),
              scaledSize: new window.google.maps.Size(16,16)
            }}/>

            <Marker
            title={props.dades.paradaDestinacio.nom}
            name={'SOMA'}
            position={{lat: Number(props.dades.paradaDestinacio.latitud), lng: Number(props.dades.paradaDestinacio.longitud)}}
            icon={{
              url: "img/icona_destinacio_itinerari.png",
              anchor: new window.google.maps.Point(8,8),
              scaledSize: new window.google.maps.Size(16,16)
            }}/>

          <Marker
            title={'Origen'}
            name={'SOMA'}
            position={{lat: Number(props.dades.origen.latitud), lng: Number(props.dades.origen.longitud)}}
            
            icon={{
              url: "img/icona_sortida_itinerari.png",
              anchor: new window.google.maps.Point(16,16),
              scaledSize: new window.google.maps.Size(32,32)
            }}/>



          <Marker
            title={'Destinació'}
            name={'SOMA'}
            position={{lat: Number(props.dades.destinacio.latitud), lng: Number(props.dades.destinacio.longitud)}}
            icon={{
              url: "img/icona_arribada_itinerari.png",
              anchor: new window.google.maps.Point(16,16),
              scaledSize: new window.google.maps.Size(32,32)
            }} />

            {/* Polilinia ruta a peu des d'origen */}
            {props.CoordsPolyLineOrigen && <Polyline
                      path={props.CoordsPolyLineOrigen}
                      options={{
                        strokeColor:"#000000",
                        strokeOpacity:0.8,
                        strokeWeight:2
                      }}
                      />
            }


            {/* Polilinia ruta a peu des de destinacio */}
            {props.CoordsPolyLineDestinacio && <Polyline
                      path={props.CoordsPolyLineDestinacio}
                      options={{
                        strokeColor:"#000000",
                        strokeOpacity:0.8,
                        strokeWeight:2
                      }}
                      />
            }

            {/* Polilinia entre parades */}
            {props.CoordsPolyLineEntreParades.map((polylinia,index)=>
                      <Polyline
                      path={polylinia}
                      options={{
                        strokeColor: props.itineraris[index].linia.color,
                        strokeOpacity:0.8,
                        strokeWeight:5
                      }}
                      />) } 
  
  </GoogleMap>
)


class MapaItinerari extends Component {

  render() {

      //Calculem el centre entre l'origen i la destinació
    var latCenter=((Number(this.props.dades.origen.latitud)+Number(this.props.dades.destinacio.latitud))/2)
    var lngCenter=((Number(this.props.dades.origen.longitud)+Number(this.props.dades.destinacio.longitud))/2)

    //Preparem l'objecte que conté les dades per centrar el mapa
    var coordenadesCentre = [{lat: Number(this.props.dades.origen.latitud), lng: Number(this.props.dades.origen.longitud)}, {lat: Number(this.props.dades.destinacio.latitud), lng: Number(this.props.dades.destinacio.longitud)}]

    //Emmagatzamem les arrays de les Polylinies
    const decodePolyline = require('decode-google-map-polyline');
    //Inicialitzem variables
    var CoordsPolyLineOrigen = null;
    var CoordsPolyLineDestinacio = null;

    //Si em rebut dades de la ruta obtenim la polilinia
    if (this.props.itinerariAParadaOrigen) {
      CoordsPolyLineOrigen = decodePolyline(this.props.itinerariAParadaOrigen.routes[0].overview_polyline.points);
    }

    if (this.props.itinerariAParadaDestinacio) {
       CoordsPolyLineDestinacio = decodePolyline(this.props.itinerariAParadaDestinacio.routes[0].overview_polyline.points);
    }
   

    var CoordsPolyLineRecorreguts = []    
    this.props.itinerari.map((itinerari) =>  CoordsPolyLineRecorreguts.push(decodePolyline(itinerari.recorregut)))
 

    return (
          <div className="mapa">
            <MapaGoogle 
              latCenter={latCenter}
              lngCenter={lngCenter}
              coordenadesCentre={coordenadesCentre}
              dades={this.props.dades}
              CoordsPolyLineOrigen={CoordsPolyLineOrigen}
              CoordsPolyLineDestinacio={CoordsPolyLineDestinacio}
              CoordsPolyLineEntreParades={CoordsPolyLineRecorreguts}
              itineraris={this.props.itinerari}
              /> 
        </div>
    );
  }
}
 
export default MapaItinerari