import React, {Component} from 'react';
import {Container, Form, Button, Alert} from 'react-bootstrap';

import API from 'Api';

class Login extends Component {
    
    constructor(){
        super()
        this.verificaDades=this.verificaDades.bind(this)
        this.handleClick=this.handleClick.bind(this)
      }

    state ={
        email: "",
        contrasenya: "",
        errorAxios: false,
        ok: false,
        missatgeAxios: null
    }

    verificaDades() {

        // const dadesAvis = {
        //     idExpedicio: this.state.idExpedicio,
        //     idParada:this.state.paradaSeleccionada,
        //     tipusAvis: this.obteIdTipusIncidencia(this.state.tipusIncidencia), //1-retard, 2-anulat, 4-afluencia
        //     retard:this.state.minutsRetard
        // };
    
        API.post(`usuaris/verificarUsuari.php`, { email: this.state.email, contrasenya: this.state.contrasenya })
          .then(res => {
            if (res.status===200){
                this.setState({ok:true, missatgeAxios:res.data.missatge})
                this.props.assignaToken(res.data.token)
                sessionStorage.setItem('token', res.data.token);
            } else this.setState({errorAxios:true, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({errorAxios:true, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }

    //CLIC PER ENVIAR
    handleClick(event) {
        event.preventDefault();
        // this.setState({pantalla:2})
        this.verificaDades();
        }
    
    
    render() {
        return (

            <div>
                <Container>
                    <div className="modulInferior">
                    <h2>Inicia la sessió</h2>
                    <div className="missatges">
                       {this.state.ok && 
                        <Alert variant="success">
                                {this.state.missatgeAxios} - Token: {this.props.token}
                        </Alert>
                        }

                        {this.state.errorAxios && 
                        <Alert variant="danger">
                                {this.state.missatgeAxios}
                        </Alert>
                        }

                    </div>

                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Correu Electrònic</Form.Label>
                            <Form.Control type="email" placeholder="Escriu el correu electrònic" onChange={e => this.setState({email: e.target.value})}/>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Contrasenya</Form.Label>
                            <Form.Control type="password" placeholder="Escriu la contrasenya" onChange={e => this.setState({contrasenya: e.target.value})}/>
                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={this.handleClick}>
                            Accedir
                        </Button>
                    </Form>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Login;