import React, {Component} from 'react';
import Cercador from '../Cercador';
import {Container} from 'react-bootstrap'

class FotoGran extends Component {

    render() {

        return (
          <div>
            <div className="foto fotoGran fotoGranPortada">
                <Container>
                <div className="cercadorPortada">
                  <h2 className="titolBuscadorPortada">On anem avui?</h2>
                  <Cercador canviOrigen={this.props.canviOrigen} canviDestinacio={this.props.canviDestinacio} canviHora={this.props.canviHora} canviData={this.props.canviData} itinerari={this.props.itinerari} assignarItinerari={this.props.assignarItinerari}/>
                </div>
                </Container>

            </div>
          </div>
        )
      }
}

export default FotoGran