import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class AvisGeneral extends Component {

render() {

    return (

      <div className="avisGeneral">
          <Container>
          <FontAwesomeIcon icon="exclamation-triangle" color="#000000"/> {this.props.missatge}
          </Container>
      </div>
    )
  }

}

export default AvisGeneral