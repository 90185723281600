import React, {Component} from 'react';
import {Card, Row, Col} from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import API from 'Api';

class ModulIncidencies extends Component {
    
    obteDades() {
        this.setState({generaInterficie: false }); //Abans de carregar dades, posem la careta de Carregant.
        const baseURL = `expedicio/?circulaAra`;

        API.get(baseURL)
        .then(res => {
                const expedicions = res.data.llistat;
                this.setState({expedicions});
          this.setState({generaInterficie: true, status:"OK" });
        })
    }
      
      
        state = {
            expedicions: [],
            status:"ERROR",
            generaInterficie: false
          }
      
      
          componentDidMount() {
            this.obteDades();
          }

        escriuExpedicionsambIncidencies(){
            return (
                <div className="incidenciesActuals">
                        {this.state.expedicions.length>0 && this.state.expedicions.map ((expedicio, index) => 
                                expedicio.numAvisosUsuari>0 &&
                                <LinkContainer to={"/expedicio/"+expedicio.id} key={"idExpedicio_"+expedicio.id}><div className="maBoto paradaLlista">
                                    {/* <div className="botoVisible"  onClick={()=>this.props.amagarLinia(index)}>{linia.visible ? <FontAwesomeIcon icon="check-circle" /> : <FontAwesomeIcon icon="circle" />}</div> */}
                                    {' '}<div className="iconaLinia" ><span className="siglesLinia" style={{backgroundColor: expedicio.linia.color}}>{expedicio.linia.sigles}</span></div>
                                    {' '}<div className="nomLinia">{expedicio.paradaInici.nom + " (" + expedicio.paradaInici.hora+ ")"} <FontAwesomeIcon icon="arrow-right"  color="#336122"/> {expedicio.paradaFinal.nom + " (" + expedicio.paradaFinal.hora + ")"}</div>
                                    {' '}<div className="properaParada" style={{display:"block", fontSize:"16px"}}>{expedicio.numAvisosUsuari>0 && <span><FontAwesomeIcon icon="exclamation-triangle" color="#B364A9"/> {expedicio.numAvisosUsuari} {expedicio.numAvisosUsuari===1 ? "avís notificat" : "avisos notificats"}</span>}     </div>
                                </div></LinkContainer>
                        )}
                </div>
            )            
        }      


    render() {

        return (
          <div>
            <Card style={{ width: '100%' }} className="targetaPortada">
                <Card.Body>
                    <Card.Title>Incidències actuals</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">El teu bus va tard? Va molt ple? Vols informar d'una incidència?</Card.Subtitle>

                    {this.state.expedicions && this.escriuExpedicionsambIncidencies()}


                        <LinkContainer to={"/expedicio"}><Card.Link>Consulta les circulacions actuals</Card.Link></LinkContainer>
                </Card.Body>
                </Card>
          </div>
        )
      }
}

export default ModulIncidencies