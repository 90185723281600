import React, {Component} from 'react';
import {Button, Form, Alert, Row, Col, DropdownButton, Dropdown, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import API from 'Api';
import { SliderPicker } from 'react-color';
import Carregant from '../../estructura/Carregant';
import MapaSeleccioPunt from '../../mapes/MapaSeleccioPunt';
import Desplegable from '../elements/Desplegable';
import { thisExpression } from '@babel/types';

class AfegirLinia extends Component {

    constructor(){
        super()

        this.carregaParades=this.carregaParades.bind(this)
        this.generaDesplegables=this.generaDesplegables.bind(this)

        this.assignaValor=this.assignaValor.bind(this)
        this.assignaColor=this.assignaColor.bind(this)

        this.afegirParada=this.afegirParada.bind(this)
        this.treureParada=this.treureParada.bind(this)
        this.assignaParada=this.assignaParada.bind(this)

        this.handleClick=this.handleClick.bind(this)
      }

    state = {
        nom: null,
        sigles: null,
        color: "#000000",
        itinerari: [],
        numParades:2,

        parades:[],
        
        pantalla: 1,
        missatgeAxios: null
    }

    //ASSIGNAR VARIABLES PER ENVIAR
      assignaValor(e) {
        this.setState({ [e.target.name]: e.target.value });
      };

      assignaColor(color) {
            this.setState({ color: color.hex });
      }


      //CLIC PER ENVIAR
      handleClick(event) {
        event.preventDefault();
        this.setState({pantalla:2})
        this.afegeix();
      }

        //CLIC PER AFEGIR UNA NOVA PARADA
        afegirParada(event) {
            event.preventDefault();
            var nouNumParada=this.state.numParades+1
            this.setState({numParades:nouNumParada})
        }

        //CLIC PER AFEGIR UNA NOVA PARADA
        treureParada(event) {
            event.preventDefault();
            var nouNumParada=this.state.numParades-1
            this.setState({numParades:nouNumParada})
        }

        //ASSIGNAR PARADA A L'ITINERARI
        assignaParada(event, index) {
            console.log(event);
            console.log(index);
            //Assignem el nou valor a les variables
            var itinerari=this.state.itinerari;
            itinerari[index] = event;            
            this.setState({itinerari});
          };

       //ORDRE CARREGA PARADES
       carregaParades() {    
        API.get(`parada/?ordre=nom`)
          .then(res => {
            if (res.status===200){
                this.setState({parades:res.data.llistat})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }

      //ORDRE AFEGIR

      afegeix() {

        //Trobem la 

        const dades = {
            nom: this.state.nom,
            sigles:this.state.sigles,
            color:this.state.color,
            itinerari: this.state.itinerari
        };
    
        API.post(`linia/`, { dades, token:this.props.token })
          .then(res => {
            if (res.status===201){
                this.setState({pantalla:3, missatgeAxios:res.data.missatge});
                this.props.refrescaParades();
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      }

      //RENDERITZA ELS DESPLEGABLES DE LA PARADA
     generaDesplegables() {
         //Generem el loop per la llista de parades de la línia per ordre
        var llistaDesplegables = [];
        for (var i=1; i<=this.state.numParades; i++) {

                    //Si hi ha algun element seleccionat, l'escollim
                    if (this.state.itinerari[i]) {
                        var itemSeleccionat = null;
                        
                        //Recorrem l'array de parades buscant el nom de l'ID corresponent
                        for (var k=0; k<this.state.parades.length&&!itemSeleccionat; k++) {
                            if (this.state.parades[k].id ===this.state.itinerari[i] ) {
                                itemSeleccionat = this.state.parades[k].nom    
                            }
                        }

                    } else var itemSeleccionat = "Escull parada...";
                

            llistaDesplegables.push(<div className="blocDesplegable">{i}: <Desplegable elements={this.state.parades} onSelect={this.assignaParada} index={i} seleccionat={itemSeleccionat}/> </div>)
        }
        return llistaDesplegables;
     } 



    componentDidMount() {
        this.carregaParades();
    }

    render() {

        return (
            
            <div>
                <Card style={{ width: '100%' }}>
                    <Card.Header>Nova línia</Card.Header>

                     {this.state.pantalla===1 && //PANTALLA 1: FORMULARI
                        <Card.Body>
                            <Row>
                                <Col md>
                            <Form.Group controlId="formNomLinia">
                                <Form.Control type="text" placeholder="Nom Línia" name="nom" onChange={this.assignaValor}/>
                            </Form.Group>
                            <Form.Group controlId="formSiglesLinia">
                                <Form.Control type="siglesLinia" placeholder="Sigles Línia" name="sigles" onChange={this.assignaValor}/>
                            </Form.Group>
                            <Form.Group controlId="formColorLinia">    
                                   {/* https://casesandberg.github.io/react-color/ */}
                                    <SliderPicker color={this.state.color} onChangeComplete={this.assignaColor}/>
                                </Form.Group>
                            

                            {this.state.itinerari.length>this.state.numParades && //MOSTRA EL BOTÓ D'AFEGIR QUAN S'HAN POSAT TOTES LES PARADES DE L'ITINERARI
                                <Form.Group controlId="formBotoEnviar">  
                                <Button variant="success" onClick={this.handleClick}>Afegir</Button>
                                </Form.Group>
                            }

                            </Col>
                            <Col md>   
                                <Form.Group controlId="formInterari">    
                                    <p>Itinerari</p>

                                    {this.generaDesplegables()}

                                    <Button variant="secondary" onClick={this.afegirParada}>Afegir parada</Button> <Button variant="secondary" onClick={this.treureParada}>Treure parada</Button>

                                </Form.Group>            
                            </Col>
                            
                        </Row>
                        
                        </Card.Body>
                    }

                    {this.state.pantalla===2 && //PANTALLA 2: CARREGANT
                        <Card.Body>                            
                            <Carregant/>                        
                        </Card.Body>
                    }   


                    {this.state.pantalla===3 && //PANTALLA 3: mostra resultat
                        <Alert variant="success">
                            Línia afegida correctament!
                        </Alert>

                        }

                    {this.state.pantalla===4 && //PANTALLA 4: mostra error
                        <Alert variant="danger">
                            Hi ha hagut un error: {this.state.missatgeAxios}
                            </Alert>

                        }


                </Card>
            </div>
        )
    }
}

    export default AfegirLinia;