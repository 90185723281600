import React, {Component} from 'react';
import { LinkContainer } from "react-router-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class DetallsParada extends Component {

    renderText() {
       if (this.props.dades.observacions) { 
        var re = /(https|http?:\/\/[^\s]+)/g;
        let parts = this.props.dades.observacions.split(re) // re is a matching regular expression
        for (let i = 1; i < parts.length; i += 2) {
          parts[i] = <a key={'link' + i} href={parts[i]}>{parts[i]}</a>
        }
        console.log(parts);
        return parts
       }
    
       return null;
        
      }


render(){
    let text = this.renderText() //Busquem els links de les adreces.
    return (
        <div>
            <LinkContainer to="/parada/" className="maBoto"><p><FontAwesomeIcon icon="arrow-left"  color="#336122" onClick={this.canviEstatFinestra}/> Parades</p></LinkContainer>
            <h2>{this.props.dades.nom}</h2>
            <p>{this.props.dades.codiPostal} {this.props.dades.poblacio}</p>
            {this.props.dades.observacions && <p>{text}</p>}
        </div>

    )
}
}

export default DetallsParada