import React, {Component} from 'react';
import {Table} from 'react-bootstrap';

import API from 'Api';
import { LinkContainer } from "react-router-bootstrap";
import iconaBusBlanca from '../../img/icona-parada-bus-blanca.png'
import IconaAgafaViatgers from './IconaAgafaViatgers';
import IconaDeixaViatgers from './IconaDeixaViatgers';
import IconaLectius from './IconaLectius';


class TaulaHorari extends Component {
    
    
    state = {
        dades: [{}],
        status:"ERROR",
        generaInterficie: false,
        missatge:'',
        llegendaAgafa:false,
        llegendaDeixa:false
      }

      constructor(){
        super()
        this.mostraLlegendaAgafa=this.mostraLlegendaAgafa.bind(this);
        this.mostraLlegendaDeixa=this.mostraLlegendaDeixa.bind(this);
      }  


    obteDades() {
        this.setState({generaInterficie: false});
        const id = this.props.codiLinia;
        const ordre = this.props.ordre;
        const diaSetmana = this.props.diaSetmana;
        const baseURL = `linia/horarisLinia_v3.php?codi=` + id + `&ordre=` + ordre + `&diaSetmana=` + diaSetmana;
    
        API.get(baseURL)
        .then(res => {
            if (res.data.status==="OK"){
                let dades = res.data.expedicions;
                
                this.setState({dades});
            }
          let missatge = res.data.missatge;
          let status = res.data.status;
          this.setState({status, missatge, generaInterficie: true});
          
        })
      }

    mostraLlegendaAgafa() {
        this.setState({llegendaAgafa:true});
    }  

    mostraLlegendaDeixa() {
        this.setState({llegendaDeixa:true});
    }

    dibuixaFilaHorari(parada, index){
        if (index!=0){
            return(
            <tr>{parada.map((expedicio,index)=>(
                <td>
                    {expedicio[0]?expedicio[0]:'-'}
                     {expedicio[1]&&!expedicio[2] && <span> <IconaAgafaViatgers modificaEstat={this.mostraLlegendaAgafa}/></span>}
                     {expedicio[2]&&!expedicio[1] && <span> <IconaDeixaViatgers modificaEstat={this.mostraLlegendaDeixa}/></span>}
                </td>
                ))
                }</tr>
        )
        }

        if (index===0){ //Si rebem la primera línia, conté els codis de les expedicions
            return(
            <tr><td></td>
            {parada.map((expedicio,index)=>
                        <LinkContainer to={"/expedicio/"+expedicio.id} className="top_taula_horaris">
                            <td className="maBoto">
                                <img
                                    src={iconaBusBlanca}
                                    width="20"
                                    height="20"
                                    className="iconaBus"
                                    alt="Expedicio"
                                />
                                <IconaLectius circulaLectius={expedicio.circulaLectius}  className="icona-lectius"/>
                            </td>
                        </LinkContainer>)}</tr>
        )
        }
        
        
    }


    
    componentDidUpdate(prevProps) { //Si arriben nous props tornem a executar el mètode de carrega de dades
        if (prevProps.ordre !== this.props.ordre || prevProps.diaSetmana !== this.props.diaSetmana) {
            this.obteDades();
        }
      }

    componentDidMount() {
        this.obteDades();
      }


    render(){
        return(
            
            <div>
                {!this.state.generaInterficie && //Si no ha carregat les dades
                    <div>
                       Carregant...
                    </div>
                }
                
                {this.state.generaInterficie &&
                    <div>
                        {this.state.status==="OK" &&
                            <div>
                        <Table responsive="lg" striped bordered hover variant="dark" className="taulaHorari">
                            <tbody>
                            {this.state.dades.map((parada,index)=>this.dibuixaFilaHorari(parada, index))}
                            </tbody>
                        </Table>
                        
                        {this.state.llegendaAgafa && <p><IconaAgafaViatgers/> La parada indicada només agafa passatgers. No es pot baixar de l'autobús en aquesta parada.</p>}
                        {this.state.llegendaDeixa && <p><IconaDeixaViatgers/> La parada indicada només deixa passatgers. No es pot pujar a l'autobús en aquesta parada.</p>}
                        
                        </div> } 

                        {this.state.status==="ERROR" &&
                            <div className="missatgeError">
                            <p>{this.state.missatge}</p>
                        </div> } 
                    </div>
                }
            </div>
        )
    }
}

export default TaulaHorari