import React, {Component} from 'react';
import {Container, Button, Accordion, Card, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class CapesMapa extends Component {
   

    render(){       

    return(
            <div>
            <Container>
                <div className="capes modulInferior">

                    <div className="capMenuCapes">
                        <h3>Capes</h3>
                        <div className="botoTancar"><FontAwesomeIcon icon="times-square" size="2x" className="maBoto" color="#336122" onClick={this.props.amagarMenuCapes}/></div>
                    </div>
                    
                    <Accordion defaultActiveKey="0" className="accordioCapes">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" className="maBoto">
                            Parades
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div className="interiorAcordio">
                                    <div onClick={this.props.amagarParades}>{this.props.paradesVisibles ? "Amagar" : "Mostrar"} Parades</div>
                                    <Form.Control type="text" placeholder="Buscar parada..." onChange={this.props.filtraParades}/>
                                    {this.props.parades.map ((parada, index) => 
                                        <div className="maBoto paradaLlista">
                                            <div className="botoVisible"  onClick={()=>this.props.amagarParada(index)}>{parada.visible ? <FontAwesomeIcon icon="check-circle" /> : <FontAwesomeIcon icon="circle" />}</div>
                                            {' '}<div className="iconaParada" onClick={()=>this.props.canviaCentre(index)}>{parada.esBergueda ? <img src="https://mobilitat.bergueda.cat/img/icona_parada.png" width="20" height="20" className="iconaBus" alt="Expedicio"/> : <img src="https://mobilitat.bergueda.cat/img/icona_parada_gris.png" width="20" height="20" className="iconaBus" alt="Expedicio"/> }</div>
                                            {' '}<div className="nomParada" onClick={()=>this.props.canviaCentre(index)}>{parada.nom}</div>
                                        </div>
                                    )}
                                </div>

                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1" className="maBoto">
                            Linies
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body> <div className="interiorAcordio">
                                    {/* <div onClick={this.props.amagarParades}>{this.props.paradesVisibles ? "Amagar" : "Mostrar"} Linies</div> */}
                                    {this.props.linies.map ((linia, index) => 
                                        <div className="maBoto paradaLlista">
                                            <div className="botoVisible"  onClick={()=>this.props.amagarLinia(index)}>{linia.visible ? <FontAwesomeIcon icon="check-circle" /> : <FontAwesomeIcon icon="circle" />}</div>
                                            {' '}<div className="iconaLinia" onClick={()=>this.props.mostraLiniaSola(index)}><span className="siglesLinia" style={{backgroundColor: linia.color}}>{linia.sigles}</span></div>
                                            {' '}<div className="nomLinia" onClick={()=>this.props.mostraLiniaSola(index)}>{linia.nom}</div>
                                        </div>
                                    )}
                                </div></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        {this.props.expedicionsActuals.length>0 && //Només surt si hi ha busos en circulació
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2" className="maBoto">
                            Servei actual
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                            <Card.Body> <div className="interiorAcordio">
                                    {/* <div onClick={this.props.amagarParades}>{this.props.paradesVisibles ? "Amagar" : "Mostrar"} Linies</div> */}
                                    {this.props.expedicionsActuals.map ((expedicio, index) => 
                                        <div className="maBoto paradaLlista">
                                            {/* <div className="botoVisible"  onClick={()=>this.props.amagarLinia(index)}>{linia.visible ? <FontAwesomeIcon icon="check-circle" /> : <FontAwesomeIcon icon="circle" />}</div> */}
                                            {' '}<div className="iconaLinia" onClick={()=>this.props.canviaCentreExpedicio(index)}><span className="siglesLinia" style={{backgroundColor: expedicio.linia.color}}>{expedicio.linia.sigles}</span></div>
                                            {' '}<div className="nomLinia" onClick={()=>this.props.canviaCentreExpedicio(index)}>{expedicio.paradaInici.nom + " (" + expedicio.paradaInici.hora+ ")"} <FontAwesomeIcon icon="arrow-right"  color="#336122"/> {expedicio.paradaFinal.nom + " (" + expedicio.paradaFinal.hora + ")"}</div>
                                            {' '}<div className="properaParada" style={{display:"block", fontSize:"13px"}} onClick={()=>this.props.canviaCentreExpedicio(index)}>Propera parada: {expedicio.circulaAra.paradesRestants[1].nom} ({expedicio.circulaAra.paradesRestants[1].horaPas})  {expedicio.numAvisosUsuari>0 && <span><FontAwesomeIcon icon="exclamation-triangle" color="#B364A9"/> {expedicio.numAvisosUsuari} {expedicio.numAvisosUsuari===1 ? "avís notificat" : "avisos notificats"}</span>}     </div>
                                        </div>
                                    )}
                                </div>
                                <div style={{display:"block", fontSize:"13px", fontStyle:"italic"}}>(Situació aproximada segons l'horari teòric)</div></Card.Body>
                            </Accordion.Collapse>
                        </Card>}
                    </Accordion>
                    <a href={process.env.PUBLIC_URL + '/arxius/mapa_transport_public_bergueda_octubre_2021.pdf'} target="_blank"><Card.Link><FontAwesomeIcon icon="file-pdf" color="#336122"/> Plànol de línies</Card.Link></a>
                </div>
            </Container>
            </div>
        )
    }
}

export default CapesMapa