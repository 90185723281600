import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class Arxius extends Component {

retornaIcona(tipus) {
    if (tipus==="PDF"){
        return <FontAwesomeIcon icon="file-pdf" color="#336122"/>
    } else if (tipus==="URL") {
         return <FontAwesomeIcon icon="link" color="#336122"/>
    } else return <FontAwesomeIcon icon="file" color="#336122"/>
}

 render() {


    return(
        <div>
            <h3>Documents</h3>
            <div>
                {this.props.arxius.map((arxiu)=>
                
                <div className="arxiu">
                    <a href={arxiu.url} target="_blank"> 
                    <span className="icona">{this.retornaIcona(arxiu.tipus)}</span>
                     {arxiu.titol}</a>

                </div>
                
                
                )}
            </div>
            <br></br>
        </div>
    )
}

}

export default Arxius