import React, {Component} from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap'
import Cercador from '../Cercador';

class CercadorWidget extends Component {

  render() {
    return (
        <span>
            <OverlayTrigger
                trigger="click"
                key="widget_cercador"
                placement="bottom"
                rootClose="true"
                overlay={
                    <Popover id={"widget_cercador"}>
                    <Popover.Title as="h3">{"Cercador"}</Popover.Title>
                    <Popover.Content>
                        <Cercador canviOrigen={this.props.canviOrigen} canviDestinacio={this.props.canviDestinacio} canviHora={this.props.canviHora} canviData={this.props.canviData} itinerari={this.props.itinerari} assignarItinerari={this.props.assignarItinerari}/>
                    </Popover.Content>
                    </Popover>
                }
                >
                <span>{this.props.titol ? this.props.titol : "Cercador" }</span>
            </OverlayTrigger>
        </span>
    )


  }
}

export default CercadorWidget