import React, {Component} from 'react';
import {Button, Alert} from 'react-bootstrap';

import API from 'Api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Carregant from '../../estructura/Carregant';

class CanviLectius extends Component {
    
    state ={
        color: "#d4d4d4"
    }

    canviaColor() {
        // console.log(this.props.lectius);

        this.props.lectius === "0" && this.setState ({color: "#d4d4d4"});
        this.props.lectius === "1" && this.setState ({color: "#336122"});
        this.props.lectius === "2" && this.setState ({color: "#e8271a"});

    }

    //ORDRE AFEGIR
    canviarCondicio() {
        
        //Preparem les dades per enviar
        const dades = {
            idExpedicio: this.props.idExpedicio
        };
    
        API.put(`expedicio/canviaLectius.php`, { dades, token:this.props.token })
          .then(res => {
            if (res.status===201){
                this.props.carregaExpedicions();
                console.log("Condició modificada correctament")
            } else console.log(res.data.missatge)
          }).catch (error => console.log(error.response.data.missatge)) //si surt ERROR 40X
    
      }

    componentDidMount() {
        this.canviaColor();
    }

    componentWillReceiveProps() {
        setTimeout(() => this.canviaColor(), 500);
    }

    
    render() {
        return (
            <div> <FontAwesomeIcon icon="graduation-cap" className="maBoto" color={this.state.color} onClick={()=>this.canviarCondicio()}/></div>
        )
    }
}

export default CanviLectius;