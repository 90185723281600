import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import puntParada from '../../img/puntparada.svg'


class RecorregutLinia extends Component {

generaResultat(parada, color, index, totalElements) {
    if (index===0||index===(totalElements-1)){
        var posicioInicialOFInal=true;
    } else var posicioInicialOFInal=false;
       
    return (
        <div className="divTableRow liniaResposta noSelect" key={"linia"+index}> 
            <div className="divTableCell">
                {/* Fem servir unes etiquetes especials si és el primer o últim element de la línia */}
                
                {index===0 &&
                    <div className="recorregutParadaColor paradaIniciLinia"
                 style={{backgroundColor: color}}>
                    <img
                        src={puntParada}
                        width="10"
                        height="10"
                        className="puntParada"
                        alt="Parada"
                    />
                </div>
                }

                {index===(totalElements-1) &&
                    <div className="recorregutParadaColor paradaFinalLinia"
                 style={{backgroundColor: color}}>
                    <img
                        src={puntParada}
                        width="10"
                        height="10"
                        className="puntParada"
                        alt="Parada"
                    />
                </div>
                }

                {!posicioInicialOFInal &&
                    <div className="recorregutParadaColor"
                 style={{backgroundColor: color}}>
                    <img
                        src={puntParada}
                        width="10"
                        height="10"
                        className="puntParada"
                        alt="Parada"
                    />
                </div>
                }

            </div>
            {/* <div className="divTableCell recorregutParadaSeparador"></div> */}
            <div className="divTableCell recorregutParadaNom maBoto" onClick={()=>this.props.obreFinestraParada(parada.id)}>
            {parada.nom}
            </div>    
    </div>

    )
}


render(){
    return (
        <div>
            {/* <div className="divTable">
                <div className="divTableBody"> 
                NOMÉS ES MOSTREN LES PARADES VISIBLES
                */}
                    {this.props.parades.map((parada,index)=>parada.visible && this.generaResultat(parada, this.props.color, index, this.props.parades.length))}
                {/* </div>
            </div> */}
        </div>

    )
}
}

export default RecorregutLinia 