import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import MapaItinerari from '../mapes/MapaItinerari';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class MapaSuperior extends Component {

    state = {
        mapaObert:false,
    }

    constructor(){
        super()
        this.desplegarMapa=this.desplegarMapa.bind(this)
    }

    desplegarMapa(){
        this.state.mapaObert ? this.setState({mapaObert:false}) : this.setState({mapaObert:true});
       }

render() {
    return (
        <div>
        {!this.state.mapaObert &&
            <Container><div className="maBoto" onClick={this.desplegarMapa}><FontAwesomeIcon icon="map-marked-alt" color="#336122"/> Mostra mapa dels resultats</div></Container>
          }

        {this.state.mapaObert &&
          <div className="barraSuperior mapaSuperior">
               <FontAwesomeIcon icon="times-square" size="2x" className="maBoto botoTancarMapa" color="#336122" onClick={this.desplegarMapa}/>
           <MapaItinerari dades={this.props.dades} itinerari={this.props.itinerari} itinerariAParadaOrigen={this.props.itinerariAParadaOrigen}
        itinerariAParadaDestinacio={this.props.itinerariAParadaDestinacio}/>

        </div>}
        
        </div>
    )
}
}

export default MapaSuperior