import React, {Component} from 'react';

class Carregant extends Component {
render() {
    return (
        <div className="carregant">
            <div className="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
}

export default Carregant