import React, {Component} from 'react';
import {Dropdown, DropdownButton} from 'react-bootstrap';

class Desplegable extends Component {
    
    
    
    render() {
        return (
            <DropdownButton
                variant="outline-secondary"
                title={this.props.seleccionat}
                id="input-group-dropdown-1"
                onSelect={(event) => this.props.onSelect(event, this.props.index)}
                >
                    {this.props.elements.map((element,index) =>
                         <Dropdown.Item eventKey={element.codi} key={element.codi}>{element.sigles}: {element.nom}</Dropdown.Item>
                    )}
            </DropdownButton>
        )
    }
}

export default Desplegable;


    