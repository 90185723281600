import React, {Component} from 'react';
import {Container, Row, Col, Alert} from 'react-bootstrap';

import API from 'Api';
import {Link} from 'react-router-dom';
import { LinkContainer } from "react-router-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Carregant from '../estructura/Carregant';

class AdminPortada extends Component {

    state = {
        estadistiques: {},
        pantalla:1,
        llistaTipusAvis:[],
        missatgeAxios: null
    }    
    
    carregaEstadistiques() {    
        API.get(`estadistica/`)
          .then(res => {
            if (res.status===200){
                this.setState({estadistiques:res.data, pantalla:2})
            } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
          }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X
    
      } 

          //CARREGA TIPUS AVIS
        carregaTipusAvis() {    
            API.get(`avis/?tipus`)
                .then(res => {
                if (res.status===200){
                    this.setState({llistaTipusAvis:res.data});
                } else this.setState({pantalla:4, missatgeAxios:res.data.missatge})
                }).catch (error => this.setState({pantalla:4, missatgeAxios:error.response.data.missatge})) //si surt ERROR 40X

            }

      nomTipusAvis(idTipusAvis) {
        if (this.state.llistaTipusAvis.length>0) {
            var tipusAvis = this.state.llistaTipusAvis.filter((avis)=>avis.id===idTipusAvis);
        return tipusAvis[0].nom
        } return idTipusAvis
    }

    componentDidMount() {
        this.carregaEstadistiques();
        this.carregaTipusAvis();
    }

    render() {

        return (
                <Container>
                     {this.state.pantalla===1 && //PANTALLA 1: Si s'han rebut dades, es carreguen
                        <div>
                            <Carregant/>
                        </div>
                        } 
                    {this.state.pantalla===2 &&
                    <div>
                        <h2>Benvinguts a l'administrador de Mobilitat Berguedà!</h2>
                        <Row>
                            <LinkContainer to="/admin/parada"><Col md className="estadisticaBloc maBoto"><div>Parades</div><div className="estadisticaNumero">{this.state.estadistiques.parada.numTotal}</div></Col></LinkContainer>
                            <LinkContainer to="/admin/linia"><Col md className="estadisticaBloc maBoto"><div>Línies</div><div className="estadisticaNumero">{this.state.estadistiques.linia.numTotal}</div></Col></LinkContainer>
                            <LinkContainer to="/admin/expedicio"><Col md className="estadisticaBloc maBoto"><div>Expedicions</div><div className="estadisticaNumero">{this.state.estadistiques.expedicio.numTotal}</div></Col></LinkContainer>
                        </Row>
                        <Row>
                        <LinkContainer to="/admin/avis"><Col md lg="2" className="estadisticaBloc maBoto"><div>Avisos</div><div className="estadisticaNumero">{this.state.estadistiques.avis.numTotal}</div></Col></LinkContainer>
                        <LinkContainer to="/admin/avisUsuari">
                            <Col md className="estadisticaBloc maBoto">
                                <div>Darrers avisos d'usuari</div>
                                <div className="estadisticaNumero">{this.state.estadistiques.avisUsuari.numTotal}</div>
                                <div className="estadisticaUltimsAvisos">{this.state.estadistiques.avisUsuari.ultimsAvisos.map((avisUsuari) => 
                                    <div>

                                    <Link to={"/expedicio/" + avisUsuari.expedicio.id}>{avisUsuari.dia} - {avisUsuari.hora}: {this.nomTipusAvis(avisUsuari.tipusAvis)} a {avisUsuari.parada.nom}</Link>
                                    
                                    </div>



                                )}</div>
                            </Col></LinkContainer>
                        </Row>
                    </div>}

                    {this.state.pantalla===4 && //PANTALLA 4: mostra error
                        <Alert variant="danger">
                            Hi ha hagut un error: {this.state.missatgeAxios}
                            </Alert>

                        }
                </Container>
        )
    }

}

export default AdminPortada