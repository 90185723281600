import React, {Component} from 'react';
import {Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class ObresRonda extends Component {
render(){
    return (
        <div className="modulTransportDemanda">
            <Alert variant="warning">
                <Alert.Heading><FontAwesomeIcon icon="exclamation-triangle"/> <strong>Canvi d'ubicació</strong> de la parada de Ronda Universitat</Alert.Heading>
                <p>A partir del dilluns 08/02/2021 i per reordenació del trànsit a la Ronda Universitat, <strong>la parada es trasllada de forma definitiva a les noves ubicacions</strong>. 
                  
                </p>
                <hr />
                {(this.props.estat==="origen"||!this.props.estat) &&
                <p className="mb-0">
                La recollida de viatgers es farà a la parada situada a <strong>Passeig de Gràcia / Diputació</strong>. <a href="https://goo.gl/maps/C4YSsATMh7zZf2fz7" target="_blank">Mapa <FontAwesomeIcon icon="external-link"/>.</a> <b>L'hora de sortida en aquesta parada s'avança 5 minuts respecte l'habitual (horaris actualitzats al cercador).</b>
                </p>}

                {(this.props.estat==="destinacio"||!this.props.estat) && 
                <p className="mb-0">
                La baixada de viatgers es farà a la parada situada a <strong>Gran Via / Balmes</strong>. <a href="https://goo.gl/maps/8jJqyHWwn6f2nKXX6" target="_blank">Mapa <FontAwesomeIcon icon="external-link"/></a>
                </p>}


                <p className="mb-0">
                    Més informació sobre el canvi d'ubicació en aquest <a href="https://mobilitat.bergueda.cat/arxius/barcelona/novesparades_rdauniversitat2021.pdf" target="_blank">document <FontAwesomeIcon icon="file-pdf"/></a>.
                    
                </p>
            </Alert>
        </div>

    )
}
}

export default ObresRonda