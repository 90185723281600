import React, {Component} from 'react';
import LlistaParades from './LlistaParades';
import Parada from './Parada'
import {Route} from 'react-router-dom';
import Top from './estructura/Top'
import Portada from './Portada'
import Peu from './estructura/Peu'
import Resultats from './Resultats';
import Mapa from './Mapa';
import Linia from './Linia';
import Expedicio from './Expedicio';
import Administrador from './administrador/Administrador';
import AvisGeneral from './estructura/AvisGeneral';
import AvisPeu from './estructura/AvisPeu';
import API from 'Api';



class Main extends Component {
  
  constructor(){
    super()
    this.canviOrigen=this.canviOrigen.bind(this)
    this.canviDestinacio=this.canviDestinacio.bind(this)
    this.canviData=this.canviData.bind(this)
    this.canviHora=this.canviHora.bind(this)
    this.assignarItinerari=this.assignarItinerari.bind(this)
    this.mostraMenu=this.mostraMenu.bind(this)
    this.invertirRecorregut=this.invertirRecorregut.bind(this)
}


state = {
  itinerari: {
    origen:"Origen",
    destinacio:"Destinació",
    data:"08-11-2019",
    dataRaw:null,
    hora:"05:00"
  },
  itinerariBuscar: {
    origen:"Origen",
    destinacio:"Destinació",
    data:"08-11-2019",
    dataRaw:null,
    hora:"05:00"
  },
  mostraMenu:true,
  opcions:[]
}

obteOpcions() {     
  const baseURL = `opcions/`;
  API.get(baseURL)
  .then(res => {
          const opcions = res.data;
          this.setState({opcions});
  })
}


//Funcions generació itinerari
canviOrigen(nomParada){
    //Valors que es mantenen  -> STATE
    this.setState({ itinerariBuscar:{origen:nomParada,
      destinacio:this.state.itinerariBuscar.destinacio,
    data:this.state.itinerariBuscar.data,
    dataRaw:this.state.itinerariBuscar.dataRaw,
    hora:this.state.itinerariBuscar.hora}});
}

canviDestinacio(nomParada){
  //Valors que es mantenen  -> STATE
  this.setState({ itinerariBuscar:{origen:this.state.itinerariBuscar.origen,
    destinacio:nomParada,
  data:this.state.itinerariBuscar.data,
  dataRaw:this.state.itinerariBuscar.dataRaw,
  hora:this.state.itinerariBuscar.hora}});
}

invertirRecorregut() {

  //Assignem els nous estats.
  this.setState({ itinerariBuscar:{origen:this.state.itinerariBuscar.destinacio,
    destinacio:this.state.itinerariBuscar.origen,
  data:this.state.itinerariBuscar.data,
  dataRaw:this.state.itinerariBuscar.dataRaw,
  hora:this.state.itinerariBuscar.hora}});
  this.setState({ itinerari:{origen:this.state.itinerari.destinacio,
    destinacio:this.state.itinerari.origen,
  data:this.state.itinerari.data,
  dataRaw:this.state.itinerari.dataRaw,
  hora:this.state.itinerari.hora}}); 
  sessionStorage.setItem('itinerari', JSON.stringify(this.state.itinerariBuscar));
}

canviData(novaData){
  novaData = new Date(novaData);
  var dd = String(novaData.getDate()).padStart(2, '0');
  var mm = String(novaData.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = novaData.getFullYear();

  var dataFormat = dd + '-' + mm + '-' + yyyy;
  //Valors que es mantenen  -> STATE
  this.setState({ itinerari:{origen:this.state.itinerari.origen,
    destinacio:this.state.itinerari.destinacio,
  data:dataFormat,
  dataRaw:novaData,
  hora:this.state.itinerari.hora}});
  this.setState({ itinerariBuscar:{origen:this.state.itinerariBuscar.origen,
    destinacio:this.state.itinerariBuscar.destinacio,
  data:dataFormat,
  dataRaw:novaData,
  hora:this.state.itinerariBuscar.hora}});
}

canviHora(novaHora){
  // console.log(novaHora);
  novaHora = new Date(novaHora);
  var hh = String(novaHora.getHours()).padStart(2, '0');
  var mm = String(novaHora.getMinutes()).padStart(2, '0'); //January is 0!

  var horaFormat = hh + ':' + mm;
 
  //Valors que es mantenen  -> STATE
  this.setState({ itinerariBuscar:{origen:this.state.itinerariBuscar.origen,
    destinacio:this.state.itinerariBuscar.destinacio,
  data:this.state.itinerariBuscar.data,
  dataRaw:this.state.itinerariBuscar.dataRaw,
  hora:horaFormat}});
  this.setState({ itinerari:{origen:this.state.itinerari.origen,
    destinacio:this.state.itinerari.destinacio,
  data:this.state.itinerari.data,
  dataRaw:this.state.itinerari.dataRaw,
  hora:horaFormat}}); 
}

assignarItinerari(){
  this.setState({itinerari:this.state.itinerariBuscar})
  sessionStorage.setItem('itinerari', JSON.stringify(this.state.itinerariBuscar));
}

componentDidMount() {
  //Agafa data d'avui
  var avui = new Date();
 //Assignem els valors actuals a les variables de dia i hora
  
  this.canviData(avui);
  // this.canviHora(avui);

  //Carreguem les opcions
  this.obteOpcions();

  //Si té valors d'itinerari en memòria, els carrega
  const itinerari = sessionStorage.getItem('itinerari');
        if (itinerari){
          const valors=JSON.parse(itinerari);
          this.setState({itinerari:valors})
        }


}

mostraMenu(valor){
  if (this.state.mostraMenu!==valor) {
      valor ? this.setState({mostraMenu:true}) : this.setState({mostraMenu:false});
  }

}

render() {
  
        
  return (



       <div className="App">
        <Top canviOrigen={this.canviOrigen} canviDestinacio={this.canviDestinacio} canviHora={this.canviHora} canviData={this.canviData} itinerari={this.state.itinerariBuscar} mostraMenu={this.state.mostraMenu} assignarItinerari={this.assignarItinerari}/>
            
            { //Només quan hi ha l'avis actiu.
            this.state.opcions.avisActiu==="1" && <AvisGeneral missatge={this.state.opcions.avisText}/>}
            
            <Route exact path ="/" render={() => ( <Portada canviOrigen={this.canviOrigen} canviDestinacio={this.canviDestinacio} canviHora={this.canviHora} canviData={this.canviData} itinerari={this.state.itinerariBuscar} assignarItinerari={this.assignarItinerari}/>)}/>
            <Route exact path ="/llistaParades" render={() => ( <LlistaParades/>)}/>
            <Route exact path ="/resultats" render={() => ( <Resultats canviOrigen={this.canviOrigen} canviDestinacio={this.canviDestinacio} canviHora={this.canviHora} canviData={this.canviData} itinerari={this.state.itinerari}  itinerariBuscar={this.state.itinerariBuscar} assignarItinerari={this.assignarItinerari} invertirRecorregut={this.invertirRecorregut}/>)}/>
            <Route exact path ="/mapa" render={() => (<Mapa/>)}/>
            <Route path={["/parada/:id", "/parada"]} render ={(params) => ( //passem els valors de la URL a través de params
                            <Parada {...params} />    
                        )}/>
            <Route path={["/linia/:id", "/linia"]} render ={(params) => (
                            <Linia {...params} />    
                        )}/>
            <Route path={["/expedicio/:id", "/expedicio"]} render ={(params) => (
                <Expedicio {...params} />    
            )}/>
            
            {/* ADMINISTRADOR */}
            <Route path ="/admin" render={() => (<Administrador componentDidMount={this.mostraMenu(false)} />)}/> 

        <Peu/>
        <AvisPeu/>


    </div>
  );
} 
}

export default Main; 