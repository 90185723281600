import React, {Component} from 'react';
import {Button, Alert} from 'react-bootstrap';

import API from 'Api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Carregant from '../../estructura/Carregant';

class CanviCondicioParada extends Component {
    
    state ={
        icona: "sort"
    }

    canviaIcona() {
        // console.log(this.props.lectius);

        (this.props.horari.agafaViatgers === 1 && this.props.horari.deixaViatgers === 1) && this.setState ({ icona: "sort"});
        (this.props.horari.agafaViatgers === 1 && this.props.horari.deixaViatgers === 0) && this.setState ({ icona: "sort-up"});
        (this.props.horari.agafaViatgers === 0 && this.props.horari.deixaViatgers === 1) && this.setState ({ icona: "sort-down"});

    }

    //ORDRE AFEGIR
    canviarCondicio() {
        
        //Preparem les dades per enviar
        const dades = {
            idExpedicio: this.props.idExpedicio,
            idParada: this.props.horari.idParada
        };
    
        API.put(`expedicio/canviaCondicioParada.php`, { dades, token:this.props.token })
          .then(res => {
            if (res.status===201){
                this.props.carregaExpedicions(); //Actualitza l'estat de les parades
                console.log("Condició modificada correctament")
            } else console.log(res.data.missatge)
          }).catch (error => console.log(error.response.data.missatge)) //si surt ERROR 40X
    
      }

    componentDidMount() {
        this.canviaIcona();
    }

    componentWillReceiveProps() {
        setTimeout(() => this.canviaIcona(), 500);
    }

    
    render() {
        return (
            <span> <FontAwesomeIcon icon={this.state.icona} className="maBoto" onClick={()=>this.canviarCondicio()}/></span>
        )
    }
}

export default CanviCondicioParada;